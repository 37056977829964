var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"timeout":4000,"multi-line":_vm.multiLine,"color":"green","shaped":"","top":""},model:{value:(_vm.successAlert),callback:function ($$v) {_vm.successAlert=$$v},expression:"successAlert"}},[_vm._v(" "+_vm._s(_vm.successMsg)+" ")]),_c('v-snackbar',{attrs:{"timeout":4000,"multi-line":_vm.multiLine,"color":"red","shaped":"","top":""},model:{value:(_vm.errorAlert),callback:function ($$v) {_vm.errorAlert=$$v},expression:"errorAlert"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1),_c('v-container',{staticClass:"text-uppercase"},[_c('v-card',{attrs:{"color":"red","flat":""}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("SOHBETLER")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 hidden-sm-and-down",attrs:{"tile":"","outlined":"","color":"green"},on:{"click":function($event){return _vm.messageClick()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-send")]),_vm._v("MESAJ GÖNDER ")],1),_c('v-btn',{staticClass:"ma-2 hidden-lg-and-up",attrs:{"tile":"","outlined":"","color":"green","fab":"","x-small":""},on:{"click":function($event){return _vm.messageClick()}}},[_c('v-icon',[_vm._v("mdi-email-send")])],1)],1)],1)],1),_vm._l((_vm.conversationList),function(item){return _c('v-container',{key:item.index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
          name: 'conversation',
          params: {
            conversationid: item.ConversationsUUID,
            userid: _vm.activeuserid,
            chattedid: item.ChattedUserID,
            chattedname: item.ChattedUserName,
          },
        }}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 16 : 2,"max-width":"1800"}},[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"color":"indigo","outlined":"","pill":"","small":""}},[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.ChattedUserName)+" ")],1),(item.UnReadMessageCount != 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","outlined":"","pill":"","small":""}},[_c('h5',[_vm._v(_vm._s(item.UnReadMessageCount)+" Okunmamış")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',[_vm._v(" "+_vm._s(item.LastMessageText)+" ")])],1),_c('v-col',{attrs:{"cols":"12","align":"end"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"purple lighten-3","outlined":"","pill":"","x-small":""}},[_vm._v(" "+_vm._s(item.LastMessageDateExplain)+" ")])],1)],1)],1)]}}],null,true)})],1)],1)}),_c('v-dialog',{attrs:{"width":"750px"},model:{value:(_vm.diaologMessage),callback:function ($$v) {_vm.diaologMessage=$$v},expression:"diaologMessage"}},[_c('v-form',{ref:"testc"},[_c('v-card',[_c('v-card-title',{staticClass:"indigo white--text"},[_vm._v("MESAJ GÖNDER")]),_c('v-container',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.entries,"loading":_vm.isLoading,"search-input":_vm.search,"hide-no-data":"","hide-selected":"","item-text":"Title","item-value":"UserID","label":"Bir kullanıcı seçiniz","placeholder":"Aramak için yazın","prepend-icon":"mdi-account-search","return-object":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var attr = ref.attr;
        var on = ref.on;
        var item = ref.item;
        var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey","small":""}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.Title)}})])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.Title)}}),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account")])],1)]}}]),model:{value:(_vm.userNameSelect),callback:function ($$v) {_vm.userNameSelect=$$v},expression:"userNameSelect"}})],1)],1),_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"mx-2",attrs:{"color":"indigo","auto-grow":"","label":"Bir mesaj yazın","rows":"1","prepend-icon":"mdi-message-plus"},model:{value:(_vm.textmessage),callback:function ($$v) {_vm.textmessage=$$v},expression:"textmessage"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.messageCancel()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home")]),_vm._v("VAZGEÇ ")],1),_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","outlined":"","color":"indigo"},on:{"click":function($event){return _vm.messageSave()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_vm._v("MESAJ GÖNDER ")],1)],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }