<template>
  <v-app>
    <v-content>
      <Home/>
    </v-content>
  </v-app>
</template>


<script>

import Home from '../src/views/Home'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>