<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>SOHBETLER</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class="ma-2 hidden-sm-and-down"
              tile
              outlined
              color="green"
              @click="messageClick()"
            >
              <v-icon left>mdi-email-send</v-icon>MESAJ GÖNDER
            </v-btn>

            <v-btn
              class="ma-2 hidden-lg-and-up"
              tile
              outlined
              color="green"
              @click="messageClick()"
              fab
              x-small
            >
              <v-icon>mdi-email-send</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container v-for="item in conversationList" v-bind:key="item.index">
        <router-link
          style="text-decoration: none; color: inherit"
          :to="{
            name: 'conversation',
            params: {
              conversationid: item.ConversationsUUID,
              userid: activeuserid,
              chattedid: item.ChattedUserID,
              chattedname: item.ChattedUserName,
            },
          }"
        >
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              class="mx-auto"
              max-width="1800"
            >
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip
                    class="ma-2 text-uppercase"
                    color="indigo"
                    outlined
                    pill
                    small
                  >
                    <v-icon>mdi-account</v-icon>
                    {{ item.ChattedUserName }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="red"
                    outlined
                    pill
                    small
                    v-if="item.UnReadMessageCount != 0"
                  >
                    <h5>{{ item.UnReadMessageCount }} Okunmamış</h5>
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <!-- <v-chip class="ma-2" color="grey" outlined pill small>
                    {{ item.LastMessageText }}
                  </v-chip> -->
                  <v-card-text>
                    {{ item.LastMessageText }}
                  </v-card-text>
                </v-col>
                <v-col cols="12" align="end">
                  <v-chip
                    class="ma-2"
                    color="purple lighten-3"
                    outlined
                    pill
                    x-small
                  >
                    {{ item.LastMessageDateExplain }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </router-link>
      </v-container>

      <!--message dialog box -->
      <v-dialog v-model="diaologMessage" width="750px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text">MESAJ GÖNDER</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="userNameSelect"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="search"
                    hide-no-data
                    hide-selected
                    item-text="Title"
                    item-value="UserID"
                    label="Bir kullanıcı seçiniz"
                    placeholder="Aramak için yazın"
                    prepend-icon="mdi-account-search"
                    return-object
                    solo
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.Title"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.Title"
                      ></v-list-item-title>
                      <v-list-item-action>
                        <v-icon>mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    color="indigo"
                    auto-grow
                    class="mx-2"
                    label="Bir mesaj yazın"
                    rows="1"
                    prepend-icon="mdi-message-plus"
                    v-model="textmessage"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="messageCancel()"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="indigo"
                @click="messageSave()"
              >
                <v-icon left>mdi-send</v-icon>MESAJ GÖNDER
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-app>
  </div>
</template>


<script>
import auth from "../api/auth";
import router from "../router";

export default {
  watch: {
    $route: function () {
      if (this.$route.name !== "messagemain") {
        clearInterval(this.polling);
      } else {
        this.count = 0;
        this.pollData();
      }
    },
    search(val) {
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entries = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.activeuserid,
      };
      this.$http
        .post(auth.API_URL + "api/UserAD/UserSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entries = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },
  },

  data: () => ({
    test: "",
    loginSuccess: false,
    accountname: "",
    activeuserid: 0,
    conversations: [],
    conversationList: [],
    polling: null,
    count: 0,
    diaologMessage: false,
    textmessage: "",
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    multiLine: "",

    entries: [],
    isLoading: false,
    userNameSelect: [],
    search: null,
  }),
  methods: {
    loadConversations,
    messageCancel,
    messageClick,
    messageSave,
    pollData() {
      if (this.count == 0) {
        this.loadConversations();
      }
      this.polling = setInterval(() => {
        this.loadConversations();
        this.count++;
      }, 60000);
    },
  },
  mounted() {
    this.pollData();
  },
};

function loadConversations() {
  this.conversationList = [];
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.ID == 0) {
            router.push({
              path: "/",
            });
            window.location.reload();
          } else {
            this.loginSuccess = true;
            this.accountname = response.body.Title;
            this.activeuserid = response.body.UserID;

            var params = {
              ActiveUserID: this.activeuserid,
            };

            this.$http
              .post(
                auth.API_URL + "api/Conversation/ConversationList",
                params,
                {
                  headers: {
                    Authorization: "Bearer " + auth.getAuthHeader(),
                  },
                }
              )
              .then(
                (response) => {
                  this.conversations = response.body;

                  this.conversations.forEach((element) => {
                    var conversationItem = {
                      ID: element.ID,
                      ConversationsUUID: element.ConversationsUUID,
                      ChattedUserID: element.ChattedUserID,
                      ChattedUserName: element.ChattedUserName,
                      ChattedUserUrlText: element.ChattedUserUrlText,
                      ChattedUserUUID: element.ChattedUserUUID,
                      LastMessageDateExplain: element.LastMessageDateExplain,
                      LastMessageText: element.LastMessageText,
                      UnReadMessageCount: element.UnReadMessageCount,
                    };

                    this.conversationList.push(conversationItem);
                  });
                },
                (response) => {
                  if (response.status === 0) {
                    this.text1 = "Service Unavailable";
                    this.snackbar = true;
                  } else {
                    this.text1 = "Service Unavailable";
                    this.snackbar = true;
                  }
                }
              );
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function messageClick() {
  this.diaologMessage = true;
  this.textmessage = "";
  this.userNameSelect = [];
  this.entries = [];
}

function messageCancel() {
  this.diaologMessage = false;
}

function messageSave() {
  if (typeof this.userNameSelect === "undefined") {
    this.errorMsg = "Mesaj göndermek için bir kullanıcı seçmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.userNameSelect.length === 0) {
    this.errorMsg = "Mesaj göndermek için bir kullanıcı seçmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.textmessage === "") {
    this.errorMsg = "Bir mesaj girmeniz gerekmektedir.";
    this.errorAlert = true;
    return;
  }

  var params = {
    ConversationSaved: true,
    SendUserID: this.activeuserid,
    ReceivedID: this.userNameSelect.UserID,
    MessageText: this.textmessage,
  };

  this.$http
    .post(auth.API_URL + "api/Conversation/MessageSave", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.textmessage = "";
          this.diaologMessage = false;
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.loadConversations();
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}
</script>