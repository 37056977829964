<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>

      <v-container>
        <v-card flat>
          <v-toolbar dense>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title v-bind="attrs" v-on="on"
                  >{{ showText }}
                </v-toolbar-title>
              </template>
              <span>{{ showText }}</span>
            </v-tooltip>

            <v-chip
              class="ma-2 text-uppercase hidden-sm-and-down"
              color="pink"
              label
              text-color="white"
              x-small
              >{{ categoryName }}</v-chip
            >

            <v-img
              class="hidden-sm-and-down"
              v-if="goodreadsShow & (goodreadsUrl !== '')"
              src="../assets/img/goodreads-icon24.png"
              max-height="24"
              max-width="24"
              @click="openLink"
              style="cursor: pointer"
            ></v-img>
            <v-img
              class="hidden-sm-and-down"
              v-if="imbdShow & (imdbUrl !== '')"
              src="../assets/img/icons8-imdb-40.png"
              max-height="40"
              max-width="40"
              @click="openLink"
              style="cursor: pointer"
            ></v-img>

            <v-spacer></v-spacer>

            <v-chip
              class="hidden-sm-and-down ma-2"
              :color="order == 1 ? 'red' : 'indigo'"
              outlined
              small
              @click="orderclick"
            >
              <v-icon left small v-if="order == 0"
                >mdi-reorder-horizontal</v-icon
              >
              <v-icon left small v-if="order == 1">mdi-heart-multiple</v-icon>
              {{ orderTitle }}
            </v-chip>

            <v-btn
              v-if="loginSuccess"
              class="hidden-sm-and-down ma-2"
              tile
              outlined
              color="green"
              @click="dialog = !dialog"
            >
              <v-icon left>mdi-lead-pencil</v-icon>YORUM EKLE
            </v-btn>
          </v-toolbar>

          <!-- <v-system-bar
            color="blue-grey darken-1 white--text"
            class="hidden-md-and-down"
            v-if="tagList !== ''"
          >
            <span>{{ tagList }}</span>
          </v-system-bar> -->

          <v-chip-group mandatory>
            <v-chip
              v-for="tag in tags"
              :key="tag"
              outlined
              small
              color="blue-grey lighten-1"
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-card>
      </v-container>

      <v-container class="hidden-lg-and-up">
        <v-card flat>
          <v-toolbar dense>
            <v-chip
              class="ma-2 text-uppercase"
              color="pink"
              label
              text-color="white"
              x-small
              >{{ categoryName }}</v-chip
            >

            <v-img
              v-if="goodreadsShow & (goodreadsUrl !== '')"
              src="../assets/img/goodreads-icon24.png"
              max-height="24"
              max-width="24"
              @click="openLink"
              style="cursor: pointer"
            ></v-img>
            <v-img
              v-if="imbdShow & (imdbUrl !== '')"
              src="../assets/img/icons8-imdb-40.png"
              max-height="40"
              max-width="40"
              @click="openLink"
              style="cursor: pointer"
            ></v-img>

            <v-spacer></v-spacer>

            <v-btn
              class="ma-2"
              outlined
              :color="order == 1 ? 'red' : 'indigo'"
              @click="orderclick"
              fab
              x-small
            >
              <v-icon small v-if="order == 0">mdi-reorder-horizontal</v-icon>
              <v-icon small v-if="order == 1">mdi-heart-multiple</v-icon>
            </v-btn>

            <v-btn
              v-if="loginSuccess"
              class="ma-2"
              tile
              outlined
              color="green"
              @click="dialog = !dialog"
              fab
              x-small
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container v-for="item in articleList" v-bind:key="item.index">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            class="mx-auto"
            color="white"
            dark
            max-width="1800"
          >
            <v-card-text class="text--primary"> </v-card-text>

            <v-card-text class="text--primary" v-html="item.ArticleText">
              <h4>{{ item.ArticleText }}</h4>
            </v-card-text>

            <v-col lg="12" md="12" sm="12" xs="12" align="end">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'user',
                  params: {
                    userUrl: item.UserUrl,
                    userGui: item.UserUUID,
                  },
                }"
              >
                <v-chip
                  class="ma-2 text-uppercase"
                  color="indigo"
                  text-color="white"
                  small
                  style="cursor: pointer"
                >
                  <v-avatar left>
                    <v-icon small>mdi-account-circle</v-icon>
                  </v-avatar>
                  {{ item.UserName }}
                </v-chip>
              </router-link>
            </v-col>
            <v-col lg="12" md="12" sm="12" xs="12" align="end">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                    icon
                    x-small
                    @click="favupdownclick(item.ArticleID, 0, item.UserName)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-heart</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.FavCount }}</span>
              </v-tooltip>
              <v-tooltip bottom color="pink">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                    icon
                    x-small
                    @click="favupdownclick(item.ArticleID, 1, item.UserName)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-thumb-up</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.LikeCount }}</span>
              </v-tooltip>
              <v-tooltip bottom color="pink">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                    icon
                    x-small
                    @click="favupdownclick(item.ArticleID, 2, item.UserName)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-thumb-down</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.DisLikeCount }}</span>
              </v-tooltip>

              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'article',
                  params: { articleid: item.ArticleID },
                }"
              >
                <v-chip
                  class="ma-2"
                  color="indigo"
                  outlined
                  pill
                  x-small
                  style="cursor: pointer"
                >
                  {{ item.ArticleDate }}
                </v-chip>
              </router-link>
            </v-col>

            <v-speed-dial top right absolute direction="left">
              <template #activator>
                <v-btn icon x-small :class="'pink--text'">
                  <v-icon>mdi-alert-decagram</v-icon>
                </v-btn>
                <!-- from current official vuetify documentation -->
              </template>

              <v-btn
                icon
                x-small
                @click="
                  complaintclick(item.UserID, item.ArticleID, item.UserName)
                "
                class="purple--text"
              >
                <v-icon>mdi-account-voice</v-icon>
              </v-btn>

              <v-btn
                v-clipboard="clipboardTxt + item.ArticleID"
                class="black--text"
                icon
                x-small
              >
                <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
              </v-btn>

              <!-- <v-btn class="blue--text" icon x-small>
                <v-icon>mdi-twitter</v-icon>
              </v-btn>

              <v-btn class="indigo--text" icon x-small>
                <v-icon>mdi-facebook</v-icon>
              </v-btn> -->

              <v-btn
                class="green--text"
                icon
                x-small
                v-if="item.EditOk"
                @click="articleditshow(item.ArticleText, item.ArticleID)"
              >
                <v-icon>mdi-tooltip-edit</v-icon>
              </v-btn>

              <v-btn
                class="red--text"
                icon
                x-small
                v-if="item.EditOk"
                @click="articledeleteshow(item.ArticleID)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-card>
        </v-hover>
      </v-container>

      <v-container>
        <div class="text-center">
          <v-pagination
            v-if="pagelen > 1"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            color="indigo"
            v-model="page"
            :length="pagelen"
            @input="loadheadlines"
          ></v-pagination>
        </div>
      </v-container>

      <!-- Yorum add dialog box -->
      <v-dialog v-model="dialog" width="600px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text">YORUM EKLE</v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (linkshow = true),
                        (bknzshow = false),
                        (bknzheadline = ''),
                        (bknzid = '')
                    "
                  >
                    <v-icon left> mdi-link-variant </v-icon>
                    Link
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (bknzshow = true),
                        (linkshow = false),
                        (linkurl = ''),
                        (linkname = '')
                    "
                  >
                    <v-icon left> mdi-comment-search </v-icon>
                    Bknz.
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="addSpoilerTags()"
                  >
                    <v-icon left> mdi-alert-rhombus </v-icon>
                    Spoiler!
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="linkshow">
                  <v-text-field
                    id="linkurl"
                    v-model="linkurl"
                    label="Url Adresi"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    id="linkname"
                    v-model="linkname"
                    label="Url Tanım"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>

                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="linkclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="linkadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="bknzshow">
                  <v-autocomplete
                    v-model="bknzheadline"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="searchMain"
                    hide-no-data
                    hide-selected
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Başlık aramak için yazınız.."
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action v-if="item.SearchType == 0">
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-action v-if="item.SearchType == 1">
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    id="bknzid"
                    v-model="bknzid"
                    label="Yorum ID"
                    name="bknzid"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                    @input="bknzheadline = ''"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="bknzclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="bknzadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="article"
                    outlined
                    name="article"
                    label="Yorum giriniz..."
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="cancelArticle()"
              >
                <v-icon left>mdi-cancel</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="dialogArticleConfirm = true"
              >
                <v-icon left>mdi-pencil</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum edit dialog box -->
      <v-dialog v-model="dialogEdit" width="600px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text"
              >YORUM DÜZENLE</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (linkshowEdit = true),
                        (bknzshowEdit = false),
                        (bknzheadlineEdit = ''),
                        (bknzidEditEdit = '')
                    "
                  >
                    <v-icon left> mdi-link-variant </v-icon>
                    Link
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (bknzshowEdit = true),
                        (linkshowEdit = false),
                        (linkurlEdit = ''),
                        (linknameEdit = '')
                    "
                  >
                    <v-icon left> mdi-comment-search </v-icon>
                    Bknz.
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="addSpoilerTagsEdit()"
                  >
                    <v-icon left> mdi-alert-rhombus </v-icon>
                    Spoiler!
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="linkshowEdit">
                  <v-text-field
                    id="linkurlEdit"
                    v-model="linkurlEdit"
                    label="Url Adresi"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    id="linknameEdit"
                    v-model="linknameEdit"
                    label="Url Tanım"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>

                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="linkcloseEdit()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="linkaddEdit()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="bknzshowEdit">
                  <v-autocomplete
                    v-model="bknzheadlineEdit"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="searchMain"
                    hide-no-data
                    hide-selected
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Başlık aramak için yazınız.."
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action v-if="item.SearchType == 0">
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-action v-if="item.SearchType == 1">
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    id="bknzidEdit"
                    v-model="bknzidEdit"
                    label="Yorum ID"
                    name="bknzid"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                    @input="bknzheadlineEdit = ''"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="bknzcloseEdit()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="bknzaddEdit()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="articleEditItem"
                    outlined
                    name="articleEdit"
                    label="Yorumunuz"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogEdit = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleEdit()"
              >
                <v-icon left>mdi-pencil</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum silme dialog box -->
      <v-dialog v-model="dialogDelete" width="400px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text">YORUM SİLME</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="deleteReason"
                    outlined
                    name="deleteReason"
                    label="Silme Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogDelete = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleDelete()"
              >
                <v-icon left>mdi-delete</v-icon>SİL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!--şikayet dialog box -->
      <v-dialog v-model="diaologCompliant" width="400px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text">ŞİKAYET ET</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    id="compliantTitle"
                    v-model="compliantTitle"
                    label="Şikeyet Başlık"
                    name="compliantTitle"
                    color="blue-grey darken-2"
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                    :rules="textRules"
                  ></v-textarea>
                  <v-textarea
                    v-model="compliantReason"
                    outlined
                    name="compliantReason"
                    label="Şikayet Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="diaologCompliant = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="compliantSave()"
              >
                <v-icon left>mdi-account-voice</v-icon>ŞİKAYET ET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

    <!-- Headline Edit Confirm dialog box -->
    <v-dialog v-model="dialogArticleConfirm" max-width="500px">
      <v-card>
        <v-container>
          <v-card-text class="text--primary" align="center">
            <h3>
              Girdiğiniz yorumu yayınlamak istediğinizden emin misiniz?
            </h3>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogArticleConfirm = false"
              >Vazgeç</v-btn
            >
            <v-btn color="green" text @click="savearticle()">Tamam</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>      
    </v-app>
  </div>
</template>


<script>
import auth from "../api/auth";
import Vue from "vue";
import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

export default {
  name: "App",
  watch: {
    "$route.params.id": function () {
      if (typeof this.$route.params.id !== "undefined") {
        (this.page = 1), (this.pagelen = 0), this.loadheadlines();
      }
    },
    searchMain(val) {
      this.bknzid = "";

      if (val == null) return;
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entries = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.accountid,
        SearchType: 1,
      };
      this.$http
        .post(auth.API_URL + "api/Search/MainSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entries = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },
  },

  data: () => ({
    tags: [],
    page: 1,
    pagelen: 0,
    dialog: false,
    order: 0,
    fav: false,
    up: false,
    down: false,
    headlineName: "",
    categoryName: "",
    showText: "",
    goodreadsShow: false,
    imbdShow: false,
    imdbUrl: "",
    goodreadsUrl: "",
    article: "",
    articleList: [],
    entryList: [],
    loginSuccess: false,
    orderTitle: "Giriş Sırası",
    textRules: [(v) => !!v || "Girilmesi zorunlu alan."],
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    accountname: "",
    activeuserid: 0,
    dialogDelete: false,
    deleteReason: "",
    dialogEdit: false,
    articleEditItem: "",
    editArticleID: 0,
    compuserid: 0,
    comparticleid: 0,
    diaologCompliant: false,
    compliantReason: null,
    compliantTitle: null,
    multiLine: null,
    subTitle: "",
    tagList: "",

    linkurl: "",
    linkname: "",
    linkshow: false,
    bknzshow: false,
    bknzid: "",

    linkurlEdit: "",
    linknameEdit: "",
    linkshowEdit: false,
    bknzshowEdit: false,
    bknzidEdit: "",

    dialogArticleConfirm : false,

    entries: [],
    isLoading: false,
    bknzheadline: [],
    bknzheadlineEdit: [],
    search: null,
    searchMain: null,
    clipboardTxt: auth.URLROOT + "Article/",
  }),
  methods: {
    headlinesLoad,
    orderclick,
    favupdownclick,
    loadheadlines,
    openLink,
    richeditTest,
    savearticle,
    articleditshow,
    articledeleteshow,
    articleEdit,
    articleDelete,
    complaintclick,
    compliantSave,

    cancelArticle,
    addSpoilerTags,
    addSpoilerTagsEdit,
    linkclose,
    linkadd,
    bknzclose,
    bknzadd,
    linkcloseEdit,
    linkaddEdit,
    bknzcloseEdit,
    bknzaddEdit,
    linkInfo,
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.loadheadlines();
  },
};

function headlinesLoad() {
  var params = {
    HeadlineID: this.$route.params.id,
    ArticleShow: true,
    ActiveUserID: this.activeuserid,
    OrderBy: (this.order == 0) ? 0 : 2,
    PageNumber: this.page,
    DataCount: 10,
  };
  
  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineLoad", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.articleList = [];
        this.goodreadsShow = false;
        this.imbdShow = false;
        this.imdbUrl = "";
        this.goodreadsUrl = "";
        this.headlineName = response.body.HeadlineName;
        this.categoryName = response.body.CategoryName;
        this.subTitle = response.body.SubTitle;
        this.tagList = response.body.TagList;


        this.tags = [];
        if (this.tagList.trim() !== "") {
          var result = this.tagList.split("#");
          result.forEach((element) => {
            if (element.trim() !== "") {
              this.tags.push(element.trim());
            }
          });
        }

        this.showText = response.body.ShowText;

        if (response.body.CategoryID === 1 || response.body.CategoryID === 2) {
          this.imbdShow = true;
          this.imdbUrl = response.body.IMDBLink;
        }

        if (response.body.CategoryID === 3) {
          this.goodreadsShow = true;
          this.goodreadsUrl = response.body.GoodreadsLink;
        }

        this.entryList = response.body.ArticlesInfo;

        var icount = 0;
        this.entryList.forEach((element) => {
          var articleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
          };
          if (icount === 0) {
            this.pagelen = element.TotalPage;
          }
          this.articleList.push(articleItem);
          icount = icount + 1;
        });
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function loadheadlines() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };

    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          this.headlinesLoad();
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    this.headlinesLoad();
  }
}

function orderclick() {
  if (!this.order) {
    this.order = 1;
    this.orderTitle = "Beğeni Sırası";
  } else {
    this.order = 0;
    this.orderTitle = "Giriş Sırası";
  }

  this.loadheadlines();
}

function favupdownclick(value, typeInput, articleUserID) {
  if (this.loginSuccess) {
    var params = {
      Type: typeInput,
      UserID: this.activeuserid,
      ArticleID: value,
    };

    if (articleUserID != this.accountname) {
      this.$http
        .post(auth.API_URL + "api/HeadlineArticle/UserActionSave", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = response.body.ResponseMessage;
              this.successAlert = true;
              // this.dialog = false
              // this.article = ''
              this.loadheadlines();
            } else {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;
            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    } else {
      this.errorMsg =
        "Favori, beğeni ve ya beğenmeme işlemlerini kendi yorumlarınızda yapamazsınız.";
      this.errorAlert = true;
    }
  } else {
    this.errorMsg =
      "Favori, beğeni ve ya beğenmeme işlemlerini yapmak için oturum açmalısınız.";
    this.errorAlert = true;
  }
}

function openLink() {
  if (this.imdbUrl) {
    window.open(this.imdbUrl, "_blank");
  }

  if (this.goodreadsUrl) {
    window.open(this.goodreadsUrl, "_blank");
  }
}

function richeditTest() {
  this.$router.push({ name: "about" });
}

function savearticle() {
  var articletxt = auth.convertToHtmlFromText(this.article);

  var params = {
    HeadlineID: this.$route.params.id,
    UserID: this.activeuserid,
    ArticleText: articletxt,
  };

  if (this.article === "") {
    this.errorMsg = "Bir yorum girmeniz gerekmektedir.";
    this.errorAlert = true;
    this.dialogArticleConfirm = false;
  } else {
    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleSave", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialog = false;
            this.article = "";
            this.loadheadlines();
            this.dialogArticleConfirm = false;            
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
            this.dialogArticleConfirm = false;            
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function articleditshow(text, id) {
  this.dialogEdit = true;
  this.articleEditItem = auth.convertToTextFromHtml(text);
  this.editArticleID = id;
}

function articledeleteshow(id) {
  this.dialogDelete = true;
  this.editArticleID = id;
}

function articleEdit() {
  var articletxt = auth.convertToHtmlFromText(this.articleEditItem);

  if (this.articleEditItem === "") {
    this.errorMsg = "Bir yorum girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      ArticleText: articletxt,
      UserID: this.activeuserid
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleEdit", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogEdit = false;
            this.articleEditItem = "";
            this.loadheadlines();
            this.cancelArticle();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function articleDelete() {
  if (this.deleteReason === "") {
    this.errorMsg = "Bir silme nedeni girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      DeleteType: 1,
      DeleteReason: this.deleteReason,
      DeleteUserID: this.activeuserid,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleDelete", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogDelete = false;
            this.deleteReason = "";
            this.loadheadlines();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function complaintclick(userid, articleid, articleUserID) {
  if (this.loginSuccess) {
    if (articleUserID != this.accountname) {
      // şikayet diaolog açılacak
      this.diaologCompliant = true;
      this.compuserid = userid;
      this.comparticleid = articleid;
    } else {
      this.errorMsg = "Kendi yorumlarınızı şikayet edemezsiniz.";
      this.errorAlert = true;
    }
  } else {
    this.errorMsg = "Bir yorumu şikayet edebilmeniz için oturum açmalısınız. ";
    this.errorAlert = true;
  }
}

function compliantSave() {
  if (this.$refs.testc.validate()) {
    if (this.compliantReason === "") {
      this.errorMsg = "Bir şikayet nedeni girmeniz gerekmektedir.";
      this.errorAlert = true;
    } else {
      var compliantReasonHtml = auth.convertToHtmlFromText(
        this.compliantReason
      );
      var params = {
        CompliantType: 0,
        UserID: this.compuserid,
        ArticleID: this.comparticleid,
        ComplainingID: this.activeuserid,
        ComplaintTitle: this.compliantTitle,
        ComplaintText: compliantReasonHtml,
        ComplaintRead: false,
      };

      this.$http
        .post(auth.API_URL + "api/HeadlineArticle/CompliantSave", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = response.body.ResponseMessage;
              this.successAlert = true;
              this.diaologCompliant = false;
              this.compliantTitle = "";
              this.compliantReason = "";
            } else {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;
            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    }
  }
}

function addSpoilerTags() {
  this.article =
    this.article == ""
      ? this.article + "(spoiler!)" + "\n\n" + "(spoiler!)"
      : this.article + "\n\n" + "(spoiler!)" + "\n\n" + "(spoiler!)";
}

function addSpoilerTagsEdit() {
  this.articleEditItem =
    this.articleEditItem == ""
      ? this.articleEditItem + "(spoiler!)" + "\n\n" + "(spoiler!)"
      : this.articleEditItem + "\n\n" + "(spoiler!)" + "\n\n" + "(spoiler!)";
}

function linkclose() {
  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function linkcloseEdit() {
  this.linkshowEdit = false;
  this.linkurlEdit = "";
  this.linknameEdit = "";
}

function linkadd() {
  if (this.linkurl == "") {
    this.errorMsg = "Link eklemek için bir url adresi girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  this.article =
    this.article + "[:" + this.linkurl + "|" + this.linkname + ":]";

  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function linkaddEdit() {
  if (this.linkurlEdit == "") {
    this.errorMsg = "Link eklemek için bir url adresi girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  this.articleEditItem =
    this.articleEditItem +
    "[:" +
    this.linkurlEdit +
    "|" +
    this.linknameEdit +
    ":]";

  this.linkshowEdit = false;
  this.linkurlEdit = "";
  this.linknameEdit = "";
}

function bknzclose() {
  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function bknzcloseEdit() {
  this.bknzshowEdit = false;
  this.bknzidEdit = "";
  this.bknzheadlineEdit = "";
}

function bknzadd() {
  if (this.bknzid == "" && this.bknzheadline == "") {
    this.errorMsg = "Bknz eklemek için bir başlık ya da yorum id girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.bknzid != "") {
    this.article = this.article + "{:" + this.bknzid + ":}";
  }

  if (this.bknzheadline != "") {
    this.article =
      this.article +
      "{;" +
      this.bknzheadline.HeadlineName +
      "|" +
      this.bknzheadline.UrlText +
      "|" +
      this.bknzheadline.HeadlinesID +
      ";}";
  }

  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function bknzaddEdit() {
  if (this.bknzidEdit == "" && this.bknzheadlineEdit == "") {
    this.errorMsg = "Bknz eklemek için bir başlık ya da yorum id girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.bknzidEdit != "") {
    this.articleEditItem = this.articleEditItem + "{:" + this.bknzidEdit + ":}";
  }

  if (this.bknzheadlineEdit != "") {
    this.articleEditItem =
      this.articleEditItem +
      "{;" +
      this.bknzheadlineEdit.HeadlineName +
      "|" +
      this.bknzheadlineEdit.UrlText +
      "|" +
      this.bknzheadlineEdit.HeadlinesID +
      ";}";
  }

  this.bknzshowEdit = false;
  this.bknzidEdit = "";
  this.bknzheadlineEdit = "";
}

function cancelArticle() {
  this.article = "";
  this.dialog = false;
  this.linkshow = false;
  this.bknzshow = false;
  this.bknzheadline = "";
}

function linkInfo() {
  this.successMsg = "Yorumun linki kopyalandı.";
  this.successAlert = true;
}
</script>
