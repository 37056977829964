<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>ŞİKAYET YÖNETİMİ</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="indigo"
              @click="goHome()"
              small
            >
              <v-icon>mdi-home-import-outline </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <v-data-table
          :headers="headlines"
          :items="complaintList"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>ŞİKAYET LİSTESİ</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>

          <template v-slot:[`item.complaintID`]="{ item }">
            <v-chip class="ma-2" color="pink" outlined small>
              {{ item.complaintID }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintType`]="{ item }">
            <v-chip class="ma-2" color="pink" outlined small>
              <div v-if="item.complaintType == 0">Yorum</div>
              <div v-if="item.complaintType == 1">Kullanıcı</div>
            </v-chip>
          </template>

          <template v-slot:[`item.complainingName`]="{ item }">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'user',
                params: {
                  userUrl: item.complainingUrlTxt,
                  userGui: item.complainingUUID,
                },
              }"
              target="_blank"
            >
              <v-chip
                class="ma-2 text-uppercase"
                color="indigo"
                outlined
                style="cursor: pointer"
                small
              >
                {{ item.complainingName }}
              </v-chip>
            </router-link>
          </template>

          <template v-slot:[`item.userName`]="{ item }">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'user',
                params: {
                  userUrl: item.userUrlTxt,
                  userGui: item.userUUID,
                },
              }"
              target="_blank"
            >
              <v-chip
                class="ma-2 text-uppercase"
                color="indigo"
                outlined
                style="cursor: pointer"
                small
              >
                {{ item.userName }}
              </v-chip>
            </router-link>
          </template>

          <template v-slot:[`item.articleID`]="{ item }">
            <v-chip class="ma-2" color="green" outlined small>
              {{ item.articleID }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintDate`]="{ item }">
            <v-chip class="ma-2" color="brown" outlined small>
              {{ item.complaintDate }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintTitle`]="{ item }">
            <v-chip class="ma-2" color="brown" outlined small>
              {{ item.complaintTitle }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintReason`]="{ item }">
            <v-btn
              color="indigo"
              fab
              dark
              x-small
              @click="goReason(item.complaintReason)"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>

      <!--şikayet dialog box -->
      <v-dialog v-model="diaologCompliant" width="400px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text"
              >ŞİKAYET ET
              <v-spacer></v-spacer>
              <v-avatar color="indigo darken-4" dark>
                <v-icon dark @click="diaologCompliant = false"
                  >mdi-close-octagon</v-icon
                >
              </v-avatar>
            </v-card-title>

            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-card class="mx-auto" color="white" dark max-width="400">
                    <v-card-text
                      class="text--primary"
                      v-html="compliantReason"
                    >
                      <h4>{{ compliantReason }}</h4>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import auth from "../../api/auth";
import router from "../../router";

export default {
  data: () => ({
    headlines: [
      {
        text: "Şikayet ID",
        align: " d-none",
        sortable: false,
        value: "complaintID",
      },
      {
        text: "Şikayet Tipi",
        align: "center",
        sortable: false,
        value: "complaintType",
      },
      {
        text: "Şikayet Eden",
        align: "center",
        sortable: false,
        value: "complainingName",
      },
      {
        text: "Şikayet Edilen",
        align: "center",
        sortable: false,
        value: "userName",
      },
      {
        text: "Yorum ID",
        align: "center",
        sortable: false,
        value: "articleID",
      },
      {
        text: "Şikayet Tarihi",
        align: "center",
        sortable: false,
        value: "complaintDate",
      },
      {
        text: "Şikayet Başlık",
        align: "center",
        sortable: false,
        value: "complaintTitle",
      },
      {
        text: "Şikayet Nedeni",
        align: "center",
        sortable: false,
        value: "complaintReason",
      },
      {
        text: "Durum",
        align: "center",
        sortable: false,
        value: "status",
      },
      // {
      //   text: "Katagori",
      //   align: "start",
      //   sortable: false,
      //   value: "categoryName",
      // },
      // {
      //   text: "Yazar Adı",
      //   align: "start",
      //   sortable: false,
      //   value: "userName",
      // },
      // {
      //   text: "Başlık Tarihi",
      //   value: "headlineDate",
      //   align: "start",
      //   sortable: false,
      // },
      // {
      //   text: "İlk Yorum",
      //   value: "firstComment",
      //   align: "start",
      //   sortable: false,
      // },
      // {
      //   text: "Durum",
      //   value: "status",
      //   align: "start",
      //   sortable: false,
      // },
      // {
      //   text: "Yorum Sayısı",
      //   value: "commentCount",
      //   align: "end",
      //   sortable: false,
      // },
      // { text: "ID", value: "headlineId", align: " d-none", sortable: false },
      // { text: "İşlemler", value: "action", sortable: false },

      // { text: "Aktif/Pasif", value: "active", sortable: false },
      // { text: "ID", value: "id", align: " d-none", sortable: false },
      // { text: "İşlemler", value: "action", sortable: false },
    ],
    complaintList: [],

    multiLine: null,
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    diaologCompliant: false,
    compliantReason: "",
  }),

  computed: {},

  created() {},

  methods: {
    goHome,
    loadAdminPages,
    goReason,
  },

  mounted() {
    this.loadAdminPages();
  },
};

function loadAdminPages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }

          this.complaintList = [];
          var params = {
            ShowType: 0,
            FilterOK: false,
          };

          this.progress = false;
          this.$http
            .post(auth.API_URL + "api/HeadlineArticle/ComplaintList", params, {
              headers: {
                Authorization: "Bearer " + auth.getAuthHeader(),
              },
            })
            .then(
              (response) => {
                this.progress = false;
                this.list = response.body;

                this.list.forEach((element) => {
                  var elementItem = {
                    complaintID: element.ID,
                    complaintType: element.CompliantType,
                    complainingName: element.ComplainingInfo.Title,
                    complainingUrlTxt: element.ComplainingInfo.UrlText,
                    complainingUUID: element.ComplainingInfo.UUID,
                    userName: element.UserInfo.Title,
                    userUrlTxt: element.UserInfo.UrlText,
                    userUUID: element.UserInfo.UUID,
                    articleID: element.ArticleID,
                    complaintTitle: element.ComplaintTitle,
                    complaintDate: element.ComplaintDateTxt,
                    complaintReason: element.ComplaintText,
                  };
                  this.complaintList.push(elementItem);
                });
              },
              (response) => {
                if (response.status === 0) {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                } else {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                }
              }
            );
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function goHome() {
  this.$router.push({ name: "adminmain" });
}

function goReason(item) {
  this.diaologCompliant = true;
  this.compliantReason = item;
}
</script>