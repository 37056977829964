<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>

      <v-container v-for="item in articleList" v-bind:key="item.index">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            class="mx-auto"
            color="white"
            dark
            max-width="1800"
          >
            <v-card-text class="text--primary"> </v-card-text>

            <v-card-text class="text--primary">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'headline',
                  params: { name: item.HeadlineUrl, id: item.HeadlineID },
                }"
              >
                <v-chip
                  class="ma-2"
                  color="success"
                  outlined
                  style="cursor: pointer"
                >
                  <v-icon left>mdi-view-headline</v-icon>
                  {{ item.HeadlineNameTxt }}
                </v-chip>
              </router-link>
            </v-card-text>

            <v-card-text class="text--primary" v-html="item.ArticleText">
              <h4>{{ item.ArticleText }}</h4>
            </v-card-text>

            <v-col lg="12" md="12" sm="12" xs="12" align="end">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'user',
                  params: {
                    userUrl: item.UserUrl,
                    userGui: item.UserUUID,
                  },
                }"
              >
                <v-chip
                  class="ma-2 text-uppercase"
                  color="indigo"
                  text-color="white"
                  small
                  style="cursor: pointer"
                >
                  <v-avatar left>
                    <v-icon small>mdi-account-circle</v-icon>
                  </v-avatar>
                  {{ item.UserName }}
                </v-chip>
              </router-link>
            </v-col>
            <v-col lg="12" md="12" sm="12" xs="12" align="end">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                    icon
                    x-small
                    @click="favupdownclick(item.ArticleID, 0, item.UserName)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-heart</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.FavCount }}</span>
              </v-tooltip>
              <v-tooltip bottom color="pink">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                    icon
                    x-small
                    @click="favupdownclick(item.ArticleID, 1, item.UserName)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-thumb-up</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.LikeCount }}</span>
              </v-tooltip>
              <v-tooltip bottom color="pink">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                    icon
                    x-small
                    @click="favupdownclick(item.ArticleID, 2, item.UserName)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-thumb-down</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.DisLikeCount }}</span>
              </v-tooltip>

              <v-chip
                class="ma-2"
                color="indigo"
                outlined
                pill
                x-small
                style="cursor: pointer"
              >
                {{ item.ArticleDate }}
              </v-chip>
            </v-col>

            <v-speed-dial top right absolute direction="left">
              <template #activator>
                <v-btn icon x-small :class="'pink--text'">
                  <v-icon>mdi-alert-decagram</v-icon>
                </v-btn>
                <!-- from current official vuetify documentation -->
              </template>

              <v-btn
                icon
                x-small
                @click="
                  complaintclick(item.UserID, item.ArticleID, item.UserName)
                "
                class="purple--text"
              >
                <v-icon>mdi-account-voice</v-icon>
              </v-btn>

              <v-btn
                v-clipboard="clipboardTxt + item.ArticleID"
                class="black--text"
                icon
                x-small
              >
                <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
              </v-btn>

              <!-- <v-btn class="blue--text" icon x-small>
                <v-icon>mdi-twitter</v-icon>
              </v-btn>

              <v-btn class="indigo--text" icon x-small>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>               -->

              <v-btn
                class="green--text"
                icon
                x-small
                v-if="item.EditOk"
                @click="articleditshow(item.ArticleText, item.ArticleID)"
              >
                <v-icon>mdi-tooltip-edit</v-icon>
              </v-btn>

              <v-btn
                class="red--text"
                icon
                x-small
                v-if="item.EditOk"
                @click="articledeleteshow(item.ArticleID)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-card>
        </v-hover>
      </v-container>

      <!-- Yorum edit dialog box -->
      <v-dialog v-model="dialogEdit" width="600px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text"
              >YORUM DÜZENLE</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (linkshow = true),
                        (bknzshow = false),
                        (bknzheadline = ''),
                        (bknzid = '')
                    "
                  >
                    <v-icon left> mdi-link-variant </v-icon>
                    Link
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (bknzshow = true),
                        (linkshow = false),
                        (linkurl = ''),
                        (linkname = '')
                    "
                  >
                    <v-icon left> mdi-comment-search </v-icon>
                    Bknz.
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="addSpoilerTags()"
                  >
                    <v-icon left> mdi-alert-rhombus </v-icon>
                    Spoiler!
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="linkshow">
                  <v-text-field
                    id="linkurl"
                    v-model="linkurl"
                    label="Url Adresi"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    id="linkname"
                    v-model="linkname"
                    label="Url Tanım"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>

                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="linkclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="linkadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="bknzshow">
                  <v-autocomplete
                    v-model="bknzheadline"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="searchMain"
                    hide-no-data
                    hide-selected
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Başlık aramak için yazınız.."
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action v-if="item.SearchType == 0">
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-action v-if="item.SearchType == 1">
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    id="bknzid"
                    v-model="bknzid"
                    label="Yorum ID"
                    name="bknzid"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                    @input="bknzheadline = ''"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="bknzclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="bknzadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="articleEditItem"
                    outlined
                    name="articleEditItem"
                    label="Yorumunuz"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogEdit = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleEdit()"
              >
                <v-icon left>mdi-pencil</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum silme dialog box -->
      <v-dialog v-model="dialogDelete" width="400px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text">YORUM SİLME</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="deleteReason"
                    outlined
                    name="deleteReason"
                    label="Silme Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogDelete = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleDelete()"
              >
                <v-icon left>mdi-delete</v-icon>SİL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!--şikayet dialog box -->
      <v-dialog v-model="diaologCompliant" width="400px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text">ŞİKAYET ET</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    id="compliantTitle"
                    v-model="compliantTitle"
                    label="Şikeyet Başlık"
                    name="compliantTitle"
                    color="blue-grey darken-2"
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                    :rules="textRules"
                  ></v-textarea>
                  <v-textarea
                    v-model="compliantReason"
                    outlined
                    name="compliantReason"
                    label="Şikayet Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="diaologCompliant = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="compliantSave()"
              >
                <v-icon left>mdi-account-voice</v-icon>ŞİKAYET ET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-app>
  </div>
</template>


<script>
import auth from "../api/auth";
import Vue from "vue";
import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

export default {
  name: "App",
  watch: {
    "$route.params.articleid": function () {
      if (typeof this.$route.params.articleid !== "undefined") {
        this.loadarticles();
      }
    },
    searchMain(val) {
      this.bknzid = "";

      if (val == null) return;
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entries = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.accountid,
        SearchType: 1,
      };
      this.$http
        .post(auth.API_URL + "api/Search/MainSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entries = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },
  },

  data: () => ({
    loginSuccess: false,
    accountname: "",
    activeuserid: 0,
    articleList: [],
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    customToolbar: [["link"], [{ list: "ordered" }, { list: "bullet" }]],
    dialogDelete: false,
    deleteReason: "",
    dialogEdit: false,
    articleEditItem: "",
    editArticleID: 0,
    textRules: [(v) => !!v || "Girilmesi zorunlu alan."],
    compuserid: 0,
    comparticleid: 0,
    diaologCompliant: false,
    multiLine: null,
    compliantTitle: null,
    compliantReason: null,

    linkurl: "",
    linkname: "",
    linkshow: false,
    bknzshow: false,
    bknzid: "",

    entries: [],
    isLoading: false,
    bknzheadline: [],
    searchMain: null,
    clipboardTxt: auth.URLROOT + "Article/",
  }),

  methods: {
    loadarticles,
    favupdownclick,
    articleditshow,
    articledeleteshow,
    articleEdit,
    articleDelete,
    complaintclick,
    compliantSave,

    cancelArticle,
    addSpoilerTags,
    linkclose,
    linkadd,
    bknzclose,
    bknzadd,
    linkInfo,
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    if (localStorage.userSessionID) {
      var sparam = {
        SessionID: localStorage.userSessionID,
      };

      this.$http
        .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.loginSuccess = true;
            this.accountname = response.body.Title;
            this.activeuserid = response.body.UserID;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    }

    this.loadarticles();
  },
};

function loadarticles() {
  var params = {
    ArticleID: this.$route.params.articleid,
    HeadlineID: 0,
    ActiveUserID: this.activeuserid,
    OrderBy: 1,
    MostLikes: true,
    NoPointControl: true,
    PageNumber: 0,
  };
  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/ArticleList", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.articleList = [];
        this.entryList = response.body;
        this.entryList.forEach((element) => {
          var articleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            HeadlineName: element.HeadlineName,
            HeadlineUrl: element.HeadlineUrl,
            HeadlineNameTxt:
              element.SubTitle === ""
                ? element.HeadlineName
                : element.HeadlineName + " (" + element.SubTitle + ")",
            HeadlineID: element.HeadlineID,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
          };
          this.articleList.push(articleItem);
        });
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function favupdownclick(value, typeInput, articleUserID) {
  if (this.loginSuccess) {
    var params = {
      Type: typeInput,
      UserID: this.activeuserid,
      ArticleID: value,
    };

    if (articleUserID != this.accountname) {
      this.$http
        .post(auth.API_URL + "api/HeadlineArticle/UserActionSave", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = response.body.ResponseMessage;
              this.successAlert = true;
              // this.dialog = false
              // this.article = ''
              this.loadarticles();
            } else {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;
            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    } else {
      this.errorMsg =
        "Favori, beğeni ve ya beğenmeme işlemlerini kendi yorumlarınızda yapamazsınız.";
      this.errorAlert = true;
    }
  } else {
    this.errorMsg =
      "Favori, beğeni ve ya beğenmeme işlemlerini yapmak için oturum açmalısınız.";
    this.errorAlert = true;
  }
}

function articleditshow(text, id) {
  this.dialogEdit = true;
  this.articleEditItem = auth.convertToTextFromHtml(text);
  this.editArticleID = id;
}

function articledeleteshow(id) {
  this.dialogDelete = true;
  this.editArticleID = id;
}

function articleEdit() {
  var articletxt = auth.convertToHtmlFromText(this.articleEditItem);

  if (this.articleEditItem === "") {
    this.errorMsg = "Bir yorum girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      ArticleText: articletxt,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleEdit", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogEdit = false;
            this.articleEditItem = "";
            this.loadarticles();
            this.cancelArticle();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function articleDelete() {
  if (this.deleteReason === "") {
    this.errorMsg = "Bir silme nedeni girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      DeleteType: 1,
      DeleteReason: this.deleteReason,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleDelete", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogDelete = false;
            this.deleteReason = "";
            this.loadheadlines();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function complaintclick(userid, articleid, articleUserID) {
  if (this.loginSuccess) {
    if (articleUserID != this.accountname) {
      // şikayet diaolog açılacak
      this.diaologCompliant = true;
      this.compuserid = userid;
      this.comparticleid = articleid;
    } else {
      this.errorMsg = "Kendi yorumlarınızı şikayet edemezsiniz.";
      this.errorAlert = true;
    }
  } else {
    this.errorMsg = "Bir yorumu şikayet edebilmeniz için oturum açmalısınız. ";
    this.errorAlert = true;
  }
}

function compliantSave() {
  if (this.$refs.testc.validate()) {
    if (this.compliantReason === "") {
      this.errorMsg = "Bir şikayet nedeni girmeniz gerekmektedir.";
      this.errorAlert = true;
    } else {
      var compliantReasonHtml = auth.convertToHtmlFromText(
        this.compliantReason
      );
      var params = {
        CompliantType: 0,
        UserID: this.compuserid,
        ArticleID: this.comparticleid,
        ComplainingID: this.activeuserid,
        ComplaintTitle: this.compliantTitle,
        ComplaintText: compliantReasonHtml,
        ComplaintRead: false,
      };

      this.$http
        .post(auth.API_URL + "api/HeadlineArticle/CompliantSave", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = response.body.ResponseMessage;
              this.successAlert = true;
              this.diaologCompliant = false;
              this.compliantTitle = "";
              this.compliantReason = "";
            } else {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;
            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    }
  }
}

function addSpoilerTags() {
  this.articleEditItem =
    this.articleEditItem == ""
      ? this.articleEditItem + "(spoiler!)" + "\n\n" + "(spoiler!)"
      : this.articleEditItem + "\n\n" + "(spoiler!)" + "\n\n" + "(spoiler!)";
}

function linkclose() {
  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function linkadd() {
  if (this.linkurl == "") {
    this.errorMsg = "Link eklemek için bir url adresi girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  this.articleEditItem =
    this.articleEditItem + "[:" + this.linkurl + "|" + this.linkname + ":]";

  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function bknzclose() {
  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function bknzadd() {
  if (this.bknzid == "" && this.bknzheadline == "") {
    this.errorMsg = "Bknz eklemek için bir başlık ya da yorum id girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.bknzid != "") {
    this.article = this.article + "{:" + this.bknzid + ":}";
  }

  if (this.bknzheadline != "") {
    console.log(this.bknzheadline);
    this.articleEditItem =
      this.articleEditItem +
      "{;" +
      this.bknzheadline.HeadlineName +
      "|" +
      this.bknzheadline.UrlText +
      "|" +
      this.bknzheadline.HeadlinesID +
      ";}";
  }

  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function cancelArticle() {
  this.articleEditItem = "";
  this.dialog = false;
  this.linkshow = false;
  this.bknzshow = false;
  this.bknzheadline = "";
}

function linkInfo() {
  this.successMsg = "Yorumun linki kopyalandı.";
  this.successAlert = true;
}
</script>
