<template>
  <div id="app">
    <v-app id="inspire">
      <v-container>
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>ÜYELİK ONAYLANDI</v-toolbar-title>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <div class="text-center">
          <v-card>


          <v-card-text class="headline font-weight-bold text-uppercase">
          HOŞGELDİN {{ this.userName }}
          </v-card-text>     


            <v-btn class="ma-2" outlined large fab color="green">
              <v-icon>mdi-check</v-icon>
            </v-btn>

          <v-card-text class="headline font-weight-bold">
            ÜYELİĞİNİZ ONAYLANMIŞTIR.
          </v-card-text>     

          <v-btn class="ma-2" tile outlined color="indigo" @click="loginEntry" >
           <v-icon left>mdi-account</v-icon>GİRİŞ YAP
          </v-btn>     

          </v-card>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<script>

import auth from "../api/auth";

export default {
  name: 'App',

  data: () => ({
    userName: '',
  }),
  methods: {
    loginEntry,  
  },

  mounted() {

    this.userName = this.$route.params.userName

      var params = {
          userUUID: this.$route.params.userUUID 
      };


  this.$http
    .post(auth.API_URL + "api/UserAD/UserConfirm", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
            this.userName = this.$route.params.userName
        } 
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );    

  },  

};

function loginEntry() {
  this.$router.push({ name: "login" });
}



</script>
