<template>
  <div id="app">
    <v-app id="inspire">
      <v-container>
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title
              >{{ categoryName }} Kategorisindeki Başlıklar</v-toolbar-title
            >

            <!-- <v-spacer></v-spacer>

            <v-chip
              class="ma-2"
              :color="(order === 1) ? 'pink' : 'grey'"
              outlined
              small
              @click="orderclick(1)"
            >
              <v-icon left>mdi-reorder-horizontal</v-icon>Bu Gün
            </v-chip>
            <v-chip
              class="ma-2"
              :color="(order === 2) ? 'pink' : 'grey'"
              outlined
              small
              @click="orderclick(2)"
            >
              <v-icon left>mdi-reorder-horizontal</v-icon>Bu Hafta
            </v-chip>
            <v-chip
              class="ma-2"
              :color="(order === 3) ? 'pink' : 'grey'"
              outlined
              small
              @click="orderclick(3)"
            >
              <v-icon left>mdi-reorder-horizontal</v-icon>Bu Ay
            </v-chip>
            <v-chip
              class="ma-2"
              :color="(order === 4) ? 'pink' : 'grey'"
              outlined
              small
              @click="orderclick(4)"
            >
              <v-icon left>mdi-reorder-horizontal</v-icon>Bu Yıl
            </v-chip>
            <v-chip
              class="ma-2"
              :color="(order === 0) ? 'pink' : 'grey'"
              outlined
              small
              @click="orderclick(0)"
            >
              <v-icon left>mdi-reorder-horizontal</v-icon>Tüm Zamanlar
            </v-chip> -->
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <v-card class="mx-auto" max-width="1800" tile>
          <v-list shaped>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" :to="item.link">
                <v-row dense>
                  <v-col cols="9">
                    <v-list-item-content>
                      <h4>
                        <v-list-item-title>
                          <v-icon small color="blue">{{ item.icon }}</v-icon>
                          {{ item.title }}</v-list-item-title
                        >
                      </h4>
                    </v-list-item-content>
                  </v-col>
                  <v-col cols="3" align="end">
                    <v-list-item-content>
                    <h5>({{ item.count }})</h5>
                    </v-list-item-content>                    
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-container>

      <v-container>
        <div class="text-center">
          <v-pagination
            v-if="pagelen > 1"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            color="indigo"
            v-model="page"
            :length="pagelen"
            @input="loadheadline"
          ></v-pagination>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import auth from "../api/auth";

export default {
  name: "App",
  watch: {
    "$route.params.categoryid": function () {
      (this.page = 1), (this.pagelen = 0), this.loadheadlineAll();
    },
  },

  data: () => ({
    page: 1,
    pagelen: 0,
    items: [],
    headlineList: [],
    categoryName: "",
    order: 0,
  }),
  methods: {
    loadheadlineAll,
    loadheadline,
    orderclick,
  },

  mounted() {
    this.loadheadlineAll();
  },
};

function orderclick(value) {
  this.order = value;
}

function loadheadlineAll() {
  if (this.$route.params.categoryid === 0) {
    this.categoryName = "En Son";
  } else {
    var params = {
      CategoryID: this.$route.params.categoryid,
    };
    this.$http
      .post(auth.API_URL + "api/CategoryAD/CategoryLoadAD", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.categoryName = response.body.CategoryName;
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }

  this.loadheadline();
}

function loadheadline() {
  this.items = [];

  var param = {
    CategoryID: this.$route.params.categoryid,
    TopCount: 0,
    PageNumber: this.page,
    DataCount: 25,
  };
  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineMenuList", param, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.headlineList = response.body;

        var icount = 0;
        this.headlineList.forEach((subelement) => {
          var headlineItem = {
            icon: subelement.CategoryIcon,
            title:
              subelement.SubTitle === ""
                ? subelement.HeadlineName
                : subelement.HeadlineName + " (" + subelement.SubTitle + ")",
            count: subelement.Count,
            link: {
              name: "headline",
              params: { name: subelement.UrlText, id: subelement.HeadlineID },
            },
          };
          if (icount === 0) {
            this.pagelen = subelement.TotalPage;
          }
          this.items.push(headlineItem);
          icount = icount + 1;
        });
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}
</script>