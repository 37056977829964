<template>
  <v-container id="signinup-form" class="fill-height">
    <div class="text-center">
      <v-snackbar
        :timeout="5000"
        v-model="successAlert"
        :multi-line="multiLine"
        color="green"
        shaped
        top
      >
        {{ successMsg }}
      </v-snackbar>
      <v-snackbar
        :timeout="5000"
        v-model="errorAlert"
        :multi-line="multiLine"
        color="red"
        shaped
        top
      >
        {{ errorMsg }}
      </v-snackbar>
    </div>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="8" class="">
        <v-card class="evelation-12 card">
          <v-window v-model="step">
            <!--SignIn-->
            <v-window-item :value="1">
              <v-row class="">
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form
                      ref="signin"
                      class="signup-form-form"
                      @submit.prevent="signin"
                    >
                      <h1
                        class="text-center display-1 mb-10"
                        :class="`${bgColor}--text`"
                      >
                        GİRİŞ YAP
                      </h1>
                      <v-text-field
                        id="username"
                        v-model="login"
                        label="E-mail"
                        name="Username"
                        append-icon="mdi-email"
                        type="text"
                        :color="bgColor"
                        :rules="loginRules"
                      />
                      <v-text-field
                        id="password"
                        v-model="password"
                        label="Şifre"
                        name="Password"
                        append-icon="showPassword ? mdi-eye : mdi-eye-off"
                        :type="showPassword ? 'text' : 'password'"
                        :color="bgColor"
                        :rules="passwordRules"
                        @click:append="showPassword = !showPassword"
                      />
                      <div class="text-center">
                        <a
                          href="#"
                          class="mt-3 overline no-text-decoration"
                          :class="`${bgColor}--text`"
                          @click="step = 3"
                        >
                          ŞİFREMİ UNUTTUM
                        </a>
                      </div>
                      <div class="text-center mt-6">
                        <v-btn
                          type="submit"
                          @click="apiLogin"
                          large
                          :color="bgColor"
                          dark
                          >GİRİŞ</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">ÜYE OL</h1>
                      <h5 class="text-center overline mb-3">
                        Lütfen üye Ol butonuna tıklayanız.
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 2">ÜYE OL</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <!--SignUp-->
            <v-window-item :value="2">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        ZATEN ÜYE MİSİNİZ?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Lütfen Giriş yap butonuna tıklayınız.
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">GİRİŞ YAP</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <h1
                      class="text-center display-1 mb-10"
                      :class="`${bgColor}--text`"
                    >
                      ÜYE OL
                    </h1>
                    <v-form
                      ref="signup"
                      class="signup-form-form"
                      @submit.prevent="signup"
                    >
                      <v-text-field
                        id="username"
                        v-model="username"
                        label="Kullanıcı Adı"
                        name="username"
                        append-icon="mdi-account"
                        type="text"
                        :rules="textRules"
                      />
                      <v-text-field
                        id="email"
                        v-model="email"
                        label="E-mail"
                        name="email"
                        append-icon="mdi-email"
                        type="email"
                        :rules="loginRules"
                      />
                      <v-text-field
                        id="regpassword"
                        v-model="regpassword"
                        label="Şifre"
                        name="regpassword"
                        append-icon="showPassword ? mdi-eye : mdi-eye-off"
                        :type="showRegPassword ? 'text' : 'password'"
                        :color="bgColor"
                        :rules="passwordRules"
                        @click:append="showRegPassword = !showRegPassword"
                      />
                      <v-text-field
                        id="regrepassword"
                        v-model="regrepassword"
                        label="Şifre Tekrarı"
                        name="regrepassword"
                        append-icon="showPassword ? mdi-eye : mdi-eye-off"
                        :type="showRegRePassword ? 'text' : 'password'"
                        :color="bgColor"
                        :rules="passwordRules"
                        @click:append="showRegRePassword = !showRegRePassword"
                      />
                      <v-checkbox
                        v-model="confirmed"
                        label="Sanat Sözlük sözleşmesini okudum ve kabul ediyorum"
                        color="indigo"
                      ></v-checkbox>

                      <div class="text-center">
                        <v-chip
                          class="ma-2"
                          color="indigo"
                          outlined
                          small
                          @click="dialogConfirm = true"
                        >
                          <v-icon left> mdi-text-box-check-outline </v-icon>
                          Sözleşme
                        </v-chip>
                      </div>
                      <div class="text-center mt-6">
                        <v-btn
                          type="submit"
                          @click="register"
                          large
                          :color="bgColor"
                          dark
                        >
                          ÜYE OL</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <!--PW Rest-->
            <v-window-item :value="3">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        ZATEN ÜYE MİSİNİZ?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Lütfen Giriş yap butonuna tıklayınız.
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">GİRİŞ YAP</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form ref="reset" class="signup-form-form">
                      <h1
                        class="text-center display-1 mb-10"
                        :class="`${bgColor}--text`"
                      >
                        ŞİFREMİ UNUTTUM
                      </h1>
                      <v-text-field
                        id="resetpass"
                        v-model="resetpass"
                        label="E-mail"
                        name="resetpass"
                        append-icon="mdi-email"
                        type="text"
                        :color="bgColor"
                        class="v-input__icon--double"
                        :rules="loginRules"
                      />
                      <div class="text-center mt-6">
                        <v-btn large :color="bgColor" dark @click="resetmail"
                          >ŞİFREYİ SIFIRLA</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>

    <!--şikayet dialog box -->
    <v-dialog v-model="dialogConfirm" width="600px">
      <v-form ref="testc">
        <v-card>
          <v-card-title class="indigo white--text">SÖZLEŞME</v-card-title>
          <v-divider></v-divider>
          <v-container>
            <b> sanatsozluk.com </b> hakkında kısmında belirtilen hizmeti
            sağlayan bir web sitesidir. <br /><br />
            <b>sanatsozluk.com</b>’da bir kullanıcı hesabı oluşturduğunda tabi
            olduğun kurallar ve diğer yasal konular aşağıda belirtilmiştir.
            <br /><br />
            Yayına gönderdiğin her türlü bilgi ve benzeri içerik hukuka uygun
            olmak durumundadır.
            <br /><br />
            Gönderdiğin içeriklerin tüm sorumluluğu sana ait olmak üzere ve
            hiçbir ön denetime tabi olmadan görüntülenmeye açık olduğundan
            sağladığın içeriğin hukuka aykırı olmadığından lütfen emin ol.
            <br /><br />
            İçeriği yüklediğin anda görüntülenmeye ve kullanıma açık olduğundan
            hukuki sorumluluğun başladığı için, sağladığın içeriğin daha sonra
            tarafınca veya şikayet/haberdar edilme vs. üzerine
            <b>sanatsozluk.com</b> tarafından silinmiş olması sorumluluğunu
            ortadan kaldırmayabilir. <br /><br />
            İçeriklerin hukuka aykırı olması sebebiyle yayından kaldırılabilir.
            <br /><br />
            Kurallara veya hukuka aykırılıkların tekrar etmesi halinde hesabın
            geçici olarak durdurulabilir veya tamamen kapatılabilir.
            <br /><br />
            Tarafımıza bildirilmediği müddetçe, yayındaki içeriğin hukuka
            uygunluğunu denetleme yükümlülüğümüz bulunmamaktadır. Ancak,
            <b>sanatsozluk.com</b> yer sağladığı içeriğin hukuka uygunluğunu
            sağlamaya özen göstermekte, bu nedenle yapılan her başvuruyu
            dikkatle değerlendirmektedir. Diğer tüm haklarımız saklıdır.
            <br /><br />
            Yayındaki her içerik farklı kullanıcılar tarafınca sağlanmış
            olabilir. bu sebeple, yayınlanmış her paylaşım "bildir" butonu
            kullanılarak şikayet edilebilmektedir.
            <br /><br />
            <b>sanatsozluk.com</b>'da yer alan içeriklerin doğru ve/veya güncel
            olduğu hiçbir şekilde iddia veya garanti edilmemektedir. Aksine,
            kullanıcılar tamamen gerçekdışı içerik de üretebilirler.
            <b>sanatsozluk.com</b>'da yer alan içeriğe "hukuka aykırı içerik ve
            şikayet" halleri dışında müdahale edilmediğinden,
            <b>sanatsozluk.com</b>’da yer alan herhangi bir bilgi, yorum, öneri,
            tecrübe paylaşımı ve benzeri içeriğin referans alınması nedeniyle
            oluşabilecek (dolaylı veya doğrudan) maddi ve/veya manevi herhangi
            bir kaybından sorumlu olmadığımızı belirtiriz.
            <b>sanatsozluk.com</b>'da yer alan bağlantılara/yönlendirmelere
            (link) ilişkin hiçbir sorumluluğumuz bulunmamaktadır.
            <b>sanatsozluk.com</b> bağlantı ve yönlendirmelerin içeriğini
            kontrol etmediği gibi, içeriğin doğruluğu, yönlendirilen adreste
            virüs, trojan, phishing ve benzeri zararlı bir unsur olmadığı veya
            yönlendirilen sitenin hukuka uygun olduğu gibi veya benzeri hiçbir
            bir garanti vermemektedir. <br /><br />
            Gerekli gördüğümüz takdirde ve hiçbir gerekçe göstermeksizin
            dilediğimiz içeriği kısmen veya tamamen yayından kaldırma, yetkini
            geçici olarak durdurma veya silme haklarını saklı tutmaktayız.
            erişim yetkisinin güvenliğine ilişkin tüm sorumluluk kullanıcıya
            aittir.
            <br /><br />
            Kullanıcı paylaşımlarını, paylaşım sahibinden izin almaksızın
            <b>sanatsozluk.com</b> pazarlama çalışmalarında kullanma hakkını
            saklı tutmaktayız. <br /><br />
            Şifreni kolay tahmin edilemeyecek şekilde oluşturmanı ve üçüncü
            kişilerle paylaşmamanı tavsiye ederiz. <b>sanatsozluk.com</b>'a
            vermiş olduğun bilgilerinin güvenliği için
            <b>sanatsozluk.com</b> elinden gelen özeni göstermektedir, ancak
            <b>sanatsozluk.com</b>'un özen yükümlülüğünden başka yükümlülüğünün
            olmadığını, barındırma hizmeti ve benzeri hizmet veren firmaların
            güvenliği sağlayamaması veya <b>sanatsozluk.com</b>'a yasadışı
            olarak erişilerek bilgilerin ele geçirilmesi gibi hallerde doğrudan
            ya da dolaylı hiçbir zarara ilişkin sorumluluk kabul etmemekteyiz.
            <br /><br />
            Her ne kadar gönderdiğin içerikleri özenle saklasak da, bu verilerin
            kaybolmayacağına ilişkin hiçbir taahhütte bulunmamaktayız. ayrıca bu
            verileri silme hakkını da saklı tutmaktayız. bu nedenle herhangi bir
            sebeple yaşanacak veri kaybı veya bozulması halinde hiçbir şekilde
            sorumluluk kabul etmemekteyiz. Veri kaybı yaşama gibi bir endişen
            varsa <b>sanatsozluk.com</b>'a sağladığın verileri kendi
            imkanlarınla yedeklemeni öneririz. <br /><br />
            Gizlilik politikası, kullanım koşullarında da belirtilmiş olup,
            kullanım koşulları da bu sözleşmenin ayrılmaz bir parçasıdır.
            <b>sanatsozluk.com</b>’da geçirdiğin süre boyunca aşağıda
            sıraladığımız saklı tutulan haklarımız hakkında bilgi sahibi olmanı
            isteriz. <br /><br />
            <b>sanatsozluk.com</b>'da bulunduğun süre boyunca “cookie” olarak da
            adlandırılan çerezlerin ve buna benzer unsurların bilgisayarına
            yerleştirilmesi söz konusu olabilir. Çerezler basit metin
            dosyalarından ibaret olup, kimlik ve sair özel bilgiler içermez, bu
            nevi kişisel bilgi içermemekle beraber, oturum bilgileri ve benzeri
            veriler saklanır ve seni tekrar tanımak için kullanılabilir.
            <br /><br />
            Ayrıca, <b>sanatsozluk.com</b>'dan hesap alırken ve
            <b>sanatsozluk.com</b> hesabını kullandığın süre boyunca senden
            doğrudan talep ettiğimiz (haberli) veya siteyi kullanımın esnasında
            yaptığın işlemlerin otomatik olarak kaydedilmesi (habersiz) şeklinde
            tarafından toplanılan her türlü veriyi süre sınırı olmaksızın
            saklama, işleme ve kullanma hakkımız bulunmaktadır. Ancak bu
            sözleşmede aksi belirtilen durumlar saklıdır. Senden bu bağlamda
            topladığımız veriler gizli tutulacaktır. Bu veriler
            <b>sanatsozluk.com</b>'un geliştirme çalışmalarında
            kullanılabilecek, anonimiten korunarak istatistiki veri olarak
            kullanılabilecek, sadece anonimiten korunmak şartıyla üçüncü
            kişilerle paylaşılabilecektir. yasal zorunluluğun söz konusu olması
            ve yetkili makamlar tarafından usulüne uygun olarak talep edilmesi
            halinde bilgilerin ilgili makamla paylaşılabilecektir. Yürürlükteki
            mevzuat gereği tüm hesap sahiplerinin sadece son 6 aylık ip bilgisi
            saklanmaktadır. <br /><br />
            Kendi isteğin doğrultusunda gönderdiğin verilerle alenileştirdiğin
            bilgilerine ilişkin olarak <b>sanatsozluk.com</b>'un hiçbir
            sorumluluğu bulunmamaktadır. Sağladığın bilgilere özen göstermeni
            rica ve tavsiye ederiz. <br /><br />
            Bu sözleşme <b>sanatsozluk.com</b> tarafından önceden
            duyurulmaksızın ve tek taraflı olarak değiştirilebilir. Olası
            değişiklikler sonrası <b>sanatsozluk.com</b> kullanımı, sözleşme
            değişikliklerinin kabulü anlamına geleceğinden sözleşme metnini
            belirli aralıklarla kontrol etmeni rica ve tavsiye ederiz.
            <br /><br />
            Bu sözleşmesel ilişki nedeniyle taraflar arasında doğabilecek
            ihtilaflarda <b>sanatsozluk.com</b>'un kayıtlarının (eposta
            yazışmaları, internet trafik bilgisi ve erişim kayıtları[log] vs.)
            hmk 193. maddesi anlamında geçerli, bağlayıcı, kesin ve münhasır
            delil olacağını, bu maddenin delil sözleşmesi niteliğinde olduğunu
            kabul etmektesin. <br /><br />
            Bu sözleşme en son 07.10.2021 tarihinde güncellenmiştir.
          </v-container>

          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="error"
              @click="dialogConfirm = false"
            >
              <v-icon left>mdi-close-circle-outline</v-icon>KAPAT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import auth from "../api/auth";
import Vue from "vue";
import VueResource from "vue-resource";
import axios from "axios";
Vue.use(VueResource);
// import Home from '../views/Home.vue'
// import router from '../router'
// import Notification from './Notification'
export default {
  name: "Signupform",
  // components: {
  //   Notification
  // },
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "indigo",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  async fetch({ store, error }, user) {
    try {
      await store.dispatch("users/signupUser", user);
    } catch (e) {
      error({
        statusCode: 503,
        message: "Unable to sign up user. Please try again later.",
      });
    }
  },
  data: () => ({
    Credential: {
      Email: "",
      Password: "",
    },
    multiLine: null,
    dialogConfirm: false,
    showPassword: false,
    showRegPassword: false,
    showRegRePassword: false,
    step: 1,
    confirmUUID: "",
    username: "",
    email: "",
    password: "",
    regpassword: "",
    regrepassword: "",
    resetpass: "",
    firstname: "",
    lastname: "",
    login: "",
    confirmed: false,
    loginRules: [
      (v) => !!v || "Lütfen e-mail adresinizi giriniz.",
      (v) => /.+@.+\..+/.test(v) || "Geçerli bir e-mail adresi giriniz",
    ],
    passwordRules: [(v) => !!v || "Lütfen şifrenizi giriniz."],
    textRules: [(v) => !!v || "Girilmesi zorunlu alan."],
    snackbarType: "success",
    snackbarMessage: "",
    snackbar: false,
    errorMsg: "",
    errorAlert: false,
    successMsg: "",
    successAlert: false,
    User: {
      UserID: 0,
      Email: "",
      Password: "",
      Title: "",
      FirstName: "",
      LastName: "",
    },
  }),
  methods: {
    signup() {
      this.$auth
        .signup({
          data: {
            user: {
              username: this.username,
              email: this.email,
              password: this.password,
            },
          },
        })
        .catch((e) => {
          this.error = e + "";
        });
    },
    async signin() {
      try {
        const response = await this.$auth.loginWith("local", {
          data: {
            login: this.login,
            password: this.password,
          },
        });
        this.snackbarType = response.data.type;
        this.snackbarMessage = response.data.message;
        this.snackbar = true;
      } catch (err) {
        this.snackbarType = "error";
        this.snackbarMessage = "Error signing you in";
        this.snackbar = true;
      }
    },
    apiLogin,
    register,
    getconfirmid,
    resetmail,
  },
};

function apiLogin() {
  if (this.$refs.signin.validate()) {
    localStorage.clear();
    var credential = {
      Email: this.login,
      Password: this.password,
    };

    auth.login(this, credential, this.$route.query.redirect);
  }
}

function register() {
  if (this.$refs.signup.validate()) {
    if (this.regrepassword != this.regpassword) {
      this.errorMsg = "Şifre ve Şifre Tekrarı eşit olmalıdır.";
      this.errorAlert = true;
    } else {
      if (!this.confirmed) {
        this.errorMsg =
          "Üye olmanız için sözleşmeyi onaylamanız gerekmektedir.";
        this.errorAlert = true;
      } else {
        if (this.username.length > 20) {
          this.errorMsg = "Kullanıcı adınız 20 karakteri geçemez.";
          this.errorAlert = true;
        } else {
          var params = {
            Title: this.username,
            Email: this.email,
            Password: this.regpassword,
          };

          this.progress = false;
          this.$http
            .post(auth.API_URL + "api/AuthAD/UserSaveAD", params, {
              headers: {
                Authorization: "Bearer " + auth.getAuthHeader(),
              },
            })
            .then(
              (response) => {
                if (response.body.Status === true) {
                  this.successMsg = response.body.ResponseMessage;
                  this.successAlert = true;

                  this.getconfirmid();

                  this.step = 1;
                  this.username = null;
                  this.email = null;
                  this.regpassword = null;
                  this.regrepassword = null;
                } else {
                  this.errorMsg = response.body.ResponseMessage;
                  this.errorAlert = true;
                }
              },
              (response) => {
                if (response.status === 0) {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                } else {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                }
              }
            );
        }
      }
    }
  }
}

function getconfirmid() {
  var params = {
    Email: this.email,
    Password: this.regpassword,
    LimitedInfo: true,
  };

  this.progress = false;
  this.$http
    .post(auth.API_URL + "api/AuthAD/UserLoadAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        var okMail =
          auth.URL_MAIL +
          "MailConfirm/" +
          response.body.Title +
          "/" +
          response.body.ConfirmID;

        let formData = new FormData();

        formData.append("Konu", "E-Posta Onay Maili");
        formData.append("to", response.body.Email);
        formData.append("formattedMessage", okMail);
        formData.append("mailType", "0");
        axios.defaults.baseURL = auth.API_URL;
        // axios.responseType = 'arraybuffer';
        axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
        axios
          .post(auth.API_URL + "api/HelpersAD/SendEmail", formData, {
            headers: {},
          })
          .then((response) => {
            console.log(response);
          })
          .catch(function () {});
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function resetmail() {
  if (this.$refs.reset.validate()) {
    var params = {
      Email: this.resetpass,
      LimitedInfo: true,
    };

    this.progress = false;
    this.$http
      .post(auth.API_URL + "api/AuthAD/UserLoadAD", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.UserID === 0) {
            this.errorMsg = "Girdiğiniz mail adresi sistemde kayıtlı değildir.";
            this.errorAlert = true;
          } else {
            this.successMsg =
              "Şifrenizi sıfırlamak için mail adresinize bir link gönderilmiştir. Gönderilen linki takip ederek şifrenizi sıfırlayabilirsiniz.";
            this.successAlert = true;

            var okMail =
              auth.URL_MAIL +
              "MailReset/" +
              response.body.Title +
              "/" +
              response.body.ConfirmID;

            let formData = new FormData();

            formData.append("Konu", "Şifre Sıfırlama");
            formData.append("to", response.body.Email);
            formData.append("formattedMessage", okMail);
            formData.append("mailType", "1");
            formData.append("UserName", response.body.Title);
            axios.defaults.baseURL = auth.API_URL;
            // axios.responseType = 'arraybuffer';
            axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
            axios
              .post(auth.API_URL + "api/HelpersAD/SendEmail", formData, {
                headers: {},
              })
              .then((response) => {
                console.log(response);
              })
              .catch(function () {});
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}
</script>

<style scoped lang="scss">
.v-input__icon--double .v-input__icon {
  margin-left: -4.25rem !important;
}
a.no-text-decoration {
  text-decoration: none;
}
#signinup-form {
  max-width: 75rem;
}
.signup-form-form {
  max-width: 23rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
