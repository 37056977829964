<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>YORUM YÖNETİMİ</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn color="warning" fab dark small @click="goFilter()">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>

            <v-btn
              class="ma-2"
              tile
              outlined
              color="indigo"
              @click="goHome()"
              small
            >
              <v-icon>mdi-home-import-outline </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container v-for="item in articleList" v-bind:key="item.index">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            class="mx-auto"
            color="white"
            dark
            max-width="1800"
          >
            <v-card-text class="text--primary">
              <v-chip
                class="ma-2"
                color="red"
                outlined
                small
                v-if="!item.Confirmed"
                style="cursor: pointer"
                @click="confirmShow(true, item.ArticleID)"
              >
                <v-icon left small>mdi-close-circle</v-icon>
                Onaylanmamış
              </v-chip>
              <v-chip
                class="ma-2"
                color="deep-purple accent-4"
                outlined
                small
                v-if="item.Confirmed"
                style="cursor: pointer"
                @click="confirmShow(false, item.ArticleID)"
              >
                <v-icon left small>mdi-ticket-confirmation</v-icon>
                Onaylanmış
              </v-chip>
            </v-card-text>

            <v-card-text class="text--primary">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'headline',
                  params: { name: item.HeadlineUrl, id: item.HeadlineID },
                }"
                target="_blank"
              >
                <v-chip
                  class="ma-2"
                  color="success"
                  outlined
                  style="cursor: pointer"
                >
                  <v-icon left>mdi-view-headline</v-icon>
                  {{ item.HeadlineNameTxt }}
                </v-chip>
              </router-link>
            </v-card-text>

            <v-card-text class="text--primary" v-html="item.ArticleText">
              <h4>{{ item.ArticleText }}</h4>
            </v-card-text>

            <v-col lg="12" md="12" sm="12" xs="12" align="end">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'user',
                  params: {
                    userUrl: item.UserUrl,
                    userGui: item.UserUUID,
                  },
                }"
                target="_blank"
              >
                <v-chip
                  class="ma-2 text-uppercase"
                  color="indigo"
                  text-color="white"
                  small
                  style="cursor: pointer"
                >
                  <v-avatar left>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                  {{ item.UserName }}
                </v-chip>
              </router-link>
            </v-col>
            <v-col lg="12" md="12" sm="12" xs="12" align="end">
              <v-chip
                class="ma-2"
                color="indigo"
                outlined
                pill
                x-small
                style="cursor: pointer"
              >
                {{ item.ArticleDate }}
              </v-chip>
            </v-col>

            <v-speed-dial top right absolute direction="left">
              <template #activator>
                <v-btn icon x-small :class="'pink--text'">
                  <v-icon>mdi-alert-decagram</v-icon>
                </v-btn>
              </template>

              <v-btn
                class="green--text"
                icon
                x-small
                @click="articleditshow(item.ArticleText, item.ArticleID)"
              >
                <v-icon>mdi-tooltip-edit</v-icon>
              </v-btn>

              <v-btn
                class="red--text"
                icon
                x-small
                @click="articledeleteshow(item.ArticleID)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-card>
        </v-hover>
      </v-container>

      <!-- Filtre dialog box -->
      <v-dialog v-model="dialogFilter" max-width="500px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text"
              >Filtre Seçimi</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Başlangıç Tarihi"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        İptal
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        Tamam
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="Bitiş Tarihi"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        İptal
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date2)"
                      >
                        Tamam
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="headlineSearching"
                    :loading="isLoading"
                    :items="entries"
                    :search-input.sync="searchMain"
                    hide-no-data
                    hide-details
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Aramak için yazınız.."
                    class="mx-4"
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action>
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="userNameSelect"
                    :items="entriesuser"
                    :loading="isLoading"
                    :search-input.sync="search"
                    hide-no-data
                    hide-details
                    item-text="Title"
                    item-value="UserID"
                    label="Kullanıcı"
                    placeholder="Aramak için yazınız.."
                    return-object
                    class="mx-4"
                    outlined
                    dense
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.Title"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.Title"
                      ></v-list-item-title>
                      <v-list-item-action>
                        <v-icon>mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-radio-group v-model="confirmGroup" mandatory>
                    <v-radio label="Onaylanmamışlar" value="1"></v-radio>
                    <v-radio label="Onaylanmışlar" value="2"></v-radio>
                    <v-radio label="Hepsi" value="0"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    id="articleid"
                    v-model="articleid"
                    label="Yorum ID"
                    name="bknzid"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="FilterCancel()"
              >
                <v-icon left>mdi-home</v-icon>İPTAL
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="FilterSave()"
              >
                <v-icon left>mdi-content-save</v-icon>UYGULA
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum silme dialog box -->
      <v-dialog v-model="dialogDelete" width="400px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text">YORUM SİLME</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="deleteReason"
                    outlined
                    name="deleteReason"
                    label="Silme Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogDelete = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleDelete()"
              >
                <v-icon left>mdi-delete</v-icon>SİL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum edit dialog box -->
      <v-dialog v-model="dialogEdit" width="600px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text"
              >YORUM DÜZENLE</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (linkshow = true),
                        (bknzshow = false),
                        (bknzheadline = ''),
                        (bknzid = '')
                    "
                  >
                    <v-icon left> mdi-link-variant </v-icon>
                    Link
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (bknzshow = true),
                        (linkshow = false),
                        (linkurl = ''),
                        (linkname = '')
                    "
                  >
                    <v-icon left> mdi-comment-search </v-icon>
                    Bknz.
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="addSpoilerTags()"
                  >
                    <v-icon left> mdi-alert-rhombus </v-icon>
                    Spoiler!
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="linkshow">
                  <v-text-field
                    id="linkurl"
                    v-model="linkurl"
                    label="Url Adresi"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    id="linkname"
                    v-model="linkname"
                    label="Url Tanım"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>

                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="linkclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="linkadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="bknzshow">
                  <v-autocomplete
                    v-model="bknzheadline"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="searchMain"
                    hide-no-data
                    hide-selected
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Başlık aramak için yazınız.."
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action v-if="item.SearchType == 0">
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-action v-if="item.SearchType == 1">
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    id="bknzid"
                    v-model="bknzid"
                    label="Yorum ID"
                    name="bknzid"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                    @input="bknzheadline = ''"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="bknzclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="bknzadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="articleEditItem"
                    outlined
                    name="articleEditItem"
                    label="Yorumunuz"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogEdit = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleEdit()"
              >
                <v-icon left>mdi-pencil</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Article Confirm dialog box -->
      <v-dialog v-model="dialogArticleConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>
                {{ this.confirmText }}
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogArticleConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="confirmSave()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import auth from "../../api/auth";
import router from "../../router";

export default {
  watch: {
    searchMain(val) {
      this.bknzid = "";

      if (val == null) return;
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entries = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.accountid,
        SearchType: 1,
      };
      this.$http
        .post(auth.API_URL + "api/Search/MainSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entries = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },
    search(val) {
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entriesuser = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.activeuserid,
      };
      this.$http
        .post(auth.API_URL + "api/UserAD/UserSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entriesuser = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },    
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dialogArticleConfirm: false,
    loginSuccess: false,
    accountname: "",
    activeuserid: 0,
    multiLine: null,
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    dialogFilter: false,
    articleid: "",
    articleList: [],

    dialogDelete: null,
    deleteReason: "",
    editArticleID: "",

    dialogEdit: null,

    linkurl: "",
    linkname: "",
    linkshow: false,
    bknzshow: false,
    bknzid: "",

    entries: [],
    entriesuser: [],
    isLoading: false,
    bknzheadline: [],
    searchMain: null,
    search:null,

    articleEditItem: null,
    confirmText: "",
    confirmed: false,
    confirmArticleID: "",

    confirmGroup: 1,
    headlineSearching: "",
    userNameSelect:"",
  }),

  computed: {},

  created() {},

  methods: {
    goHome,
    goFilter,
    FilterSave,
    loadAdminPages,
    articledeleteshow,
    articleditshow,
    articleDelete,
    addSpoilerTags,
    linkclose,
    linkadd,
    bknzclose,
    bknzadd,
    linkInfo,
    cancelArticle,
    articleEdit,
    confirmShow,
    confirmSave,
    FilterCancel,
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.loadAdminPages();
  },
};

function loadAdminPages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }
          this.FilterSave();
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function goHome() {
  this.$router.push({ name: "adminmain" });
}

function goFilter() {
  this.dialogFilter = true;
}

function FilterSave() {
  var params = {
    ArticleID: this.articleid,
    HeadlineID:
      typeof this.headlineSearching !== "undefined" &&
      this.headlineSearching !== ""
        ? this.headlineSearching.HeadlinesID
        : 0,
    UserID:
      typeof this.userNameSelect !== "undefined" &&
      this.userNameSelect !== ""
        ? this.userNameSelect.UserID
        : 0,        
    ActiveUserID: this.activeuserid,
    OrderBy: 0,
    MostLikes: true,
    NoPointControl: true,
    PageNumber: 0,
    ConfirmedType: this.confirmGroup,
    StartDate: this.date,
    EndDate: this.date2,
  };
  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/ArticleList", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.articleList = [];
        this.entryList = response.body;

        this.entryList.forEach((element) => {
          var articleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            HeadlineName: element.HeadlineName,
            HeadlineUrl: element.HeadlineUrl,
            HeadlineNameTxt:
              element.SubTitle === ""
                ? element.HeadlineName
                : element.HeadlineName + " (" + element.SubTitle + ")",
            HeadlineID: element.HeadlineID,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
            Confirmed: element.Confirmed,
          };
          this.articleList.push(articleItem);
        });
        this.dialogFilter = false;
        this.articleid = "";
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function articledeleteshow(id) {
  this.dialogDelete = true;
  this.editArticleID = id;
}

function articleDelete() {
  if (this.deleteReason === "") {
    this.errorMsg = "Bir silme nedeni girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      DeleteType: 2,
      DeleteReason: this.deleteReason,
      DeleteUserID: this.activeuserid,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleDelete", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogDelete = false;
            this.deleteReason = "";
            this.articleid = "-1";
            this.FilterSave();
            this.articleid = "";
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function articleditshow(text, id) {
  this.dialogEdit = true;
  this.articleEditItem = auth.convertToTextFromHtml(text);
  this.editArticleID = id;
}

function addSpoilerTags() {
  this.articleEditItem =
    this.articleEditItem == ""
      ? this.articleEditItem + "(spoiler!)" + "\n\n" + "(spoiler!)"
      : this.articleEditItem + "\n\n" + "(spoiler!)" + "\n\n" + "(spoiler!)";
}

function linkclose() {
  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function linkadd() {
  if (this.linkurl == "") {
    this.errorMsg = "Link eklemek için bir url adresi girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  this.articleEditItem =
    this.articleEditItem + "[:" + this.linkurl + "|" + this.linkname + ":]";

  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function bknzclose() {
  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function bknzadd() {
  if (this.bknzid == "" && this.bknzheadline == "") {
    this.errorMsg = "Bknz eklemek için bir başlık ya da yorum id girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.bknzid != "") {
    this.article = this.article + "{:" + this.bknzid + ":}";
  }

  if (this.bknzheadline != "") {
    this.articleEditItem =
      this.articleEditItem +
      "{;" +
      this.bknzheadline.HeadlineName +
      "|" +
      this.bknzheadline.UrlText +
      "|" +
      this.bknzheadline.HeadlinesID +
      ";}";
  }

  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function cancelArticle() {
  this.articleEditItem = "";
  this.dialog = false;
  this.linkshow = false;
  this.bknzshow = false;
  this.bknzheadline = "";
}

function linkInfo() {
  this.successMsg = "Yorumun linki kopyalandı.";
  this.successAlert = true;
}

function articleEdit() {
  var articletxt = auth.convertToHtmlFromText(this.articleEditItem);

  if (this.articleEditItem === "") {
    this.errorMsg = "Bir yorum girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    this.articleid = this.editArticleID;
    var params = {
      ArticlesID: this.editArticleID,
      ArticleText: articletxt,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleEdit", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogEdit = false;
            this.articleEditItem = "";
            this.FilterSave();
            this.articleid = "";
            this.cancelArticle();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function confirmShow(confirmType, id) {
  (this.confirmText = confirmType
    ? "Bu yorumu onaylamak istediğinizden emin misiniz?"
    : "Bu yorumun onayını kaldırmak istediğinizden emin misiniz?"),
    (this.dialogArticleConfirm = true);
  this.confirmed = confirmType;
  this.confirmArticleID = id;
}

function confirmSave() {
  var params = {
    ArticleID: this.confirmArticleID,
    UserID: this.activeuserid,
    Confirmed: this.confirmed,
  };

  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/ArticleConfirmSave", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.dialogDelete = false;
          this.FilterSave();
          this.dialogArticleConfirm = false;
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function FilterCancel() {
  this.dialogFilter = false;
  this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  this.date2 = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  this.headlineSearching = "";
  this.confirmGroup = 1;
  this.userNameSelect = "";
  this.FilterSave();
}
</script>