<template>
  <div id="app">
    <v-app id="inspire">
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>BİLDİRİMLER</v-toolbar-title>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <v-row v-for="item in items" v-bind:key="item.index">
          <v-col cols="12">
            <v-card class="rounded-xl" color="amber lighten-5" dense>
              <v-card-text>  <h4> <v-icon left> {{ item.NotificationIcon }} </v-icon> {{ item.NotificationText }} </h4></v-card-text>
            </v-card>
            <v-chip class="ma-2" color="grey" outlined pill x-small>
              {{ item.NotificationDateTxt }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12">
            <v-card class="rounded-xl" color="amber lighten-5" dense>
              <v-card-text> Henüz herhangi bildirim almadınız. </v-card-text>
            </v-card>
            <v-chip class="ma-2" color="grey" outlined pill x-small>
              14:52
            </v-chip>
          </v-col>
        </v-row> -->
      </v-container>
    </v-app>
  </div>
</template>

<script>
import auth from "../api/auth";
import router from "../router";

export default {
  name: "App",

  data: () => ({
    items: [],
    notificationList: [],
  }),
  methods: {
    loadNotification,
  },
  mounted() {
    this.loadNotification();
  },
};

function loadNotification() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.ID == 0) {
            router.push({
              path: "/",
            });
            window.location.reload();
          } else {
            if (response.body.UserID != this.$route.params.userid) {
              router.push({
                path: "/",
              });
              window.location.reload();
            } else {
              var params = {
                UserID: this.$route.params.userid,
              };
              this.$http
                .post(
                  auth.API_URL + "api/Notification/NotificationList",
                  params,
                  {
                    headers: {
                      Authorization: "Bearer " + auth.getAuthHeader(),
                    },
                  }
                )
                .then(
                  (response) => {
                    this.notificationList = response.body;

                    this.notificationList.forEach((element) => {
                      var notificationItem = {
                        NotificationID: element.NotificationID,
                        NotificationType: element.NotificationType,
                        NotificationText: element.NotificationText,
                        NotificationDate: element.NotificationDate,
                        NotificationDateTxt: element.NotificationDateTxt,
                        NotificationIcon: element.NotificationIcon,
                      };
                      this.items.push(notificationItem);
                    });
                  },
                  (response) => {
                    if (response.status === 0) {
                      this.text1 = "Service Unavailable";
                      this.snackbar = true;
                    } else {
                      this.text1 = "Service Unavailable";
                      this.snackbar = true;
                    }
                  }
                );
            }
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}
</script>
