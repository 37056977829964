<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      width="400"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list shaped>
        <v-list-group
          v-for="item in items"
          :key="item.id"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          :to="item.link"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.id"
            :to="subItem.link"
          >
            <v-list-item-content prepend-icon="mdi-film">
              <v-row dense>
                <v-col cols="10">
                  <v-list-item-title v-html="subItem.title"></v-list-item-title>
                </v-col>
                <v-col cols="2" align="end">
                  <v-list-item-title v-html="subItem.count"></v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="loginInfoSuccess">
            <v-list-item-content prepend-icon="mdi-film">
              <v-row dense>
                <v-col cols="12">
                  <v-list-item-title
                    @click="dialog = !dialog"
                    style="cursor: pointer"
                    >Başlık Ekle +</v-list-item-title
                  >
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 170px"
        class="hidden-sm-and-down ml-0 pl-4"
      >
        <v-icon>mdi-format-section</v-icon>
        <span @click="gohompage" style="cursor: pointer">Sanat Sözlük</span>
      </v-toolbar-title>

      <v-btn icon small class="hidden-lg-and-up" @click="gohompage">
        <v-icon>mdi-format-section</v-icon>
      </v-btn>

      <v-autocomplete
        v-model="mainSearching"
        :loading="isLoading"
        :items="entries"
        :search-input.sync="searchMain"
        hide-no-data
        hide-details
        item-text="ShowText"
        item-value="HeadlinesID"
        placeholder="Aramak için yazınız.."
        return-object
        flat
        solo-inverted
        v-on:change="goSearching"
      >
        <!-- <template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
            v-bind="attr"
            :input-value="selected"
            color="blue-grey"
            class="white--text"
            v-on="on"
            small
          >
            <span v-text="item.ShowText"></span>
          </v-chip>
        </template> -->
        <template v-slot:item="{ item }">
          <v-list-item-title v-text="item.ShowText"></v-list-item-title>
          <v-list-item-action v-if="item.SearchType == 0">
            <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
          </v-list-item-action>
          <v-list-item-action v-if="item.SearchType == 1">
            <v-icon color="indigo">mdi-account</v-icon>
          </v-list-item-action>
        </template>
      </v-autocomplete>

      <v-spacer></v-spacer>
      <v-btn
        color="indigo darken-4"
        class="ma-2 white--text hidden-sm-and-down"
        v-if="loginSuccess"
        @click="loginEntry"
      >
        {{ btnlabel }}
        <v-icon right dark>mdi-account-settings</v-icon>
      </v-btn>

      <v-btn
        color="indigo darken-4"
        class="ma-2 white--text hidden-lg-and-up"
        v-if="loginSuccess"
        @click="loginEntry"
      >
        <v-icon>mdi-account-settings</v-icon>
      </v-btn>

      <!-- USER MENU EKRANI -->
      <v-menu v-if="loginInfoSuccess">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-avatar
                color="indigo darken-4"
                dark
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon dark>mdi-account-settings</v-icon>
              </v-avatar>
            </template>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase">
                <v-chip class="ma-2" color="indigo" outlined pill>
                  <v-icon>mdi-account</v-icon>
                  {{ menuUserName }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item v-if="accountType == 1" @click="goadminmain">
            <v-list-item-icon>
              <v-icon>mdi-fingerprint</v-icon> </v-list-item-icon
            >YÖNETİCİ
          </v-list-item>

          <v-list-item @click="dialog = !dialog">
            <v-list-item-icon> <v-icon>mdi-pen-plus</v-icon> </v-list-item-icon
            >BAŞLIK EKLE
          </v-list-item>

          <v-list-item @click="gouserpage">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon> </v-list-item-icon
            >PROFİLİM
          </v-list-item>

          <v-list-item @click="gomessagemain">
            <v-list-item-icon>
              <v-badge
                color="red"
                :content="unReadMessage"
                v-if="unReadMessage != 0"
              >
                <v-icon> mdi-email </v-icon>
              </v-badge>

              <v-icon v-if="unReadMessage == 0">
                mdi-email
              </v-icon> </v-list-item-icon
            >MESAJLARIM
          </v-list-item>

          <v-list-item @click="gonotification">
            <v-list-item-icon>
              <v-badge
                color="red"
                :content="unShowNotification"
                v-if="unShowNotification != 0"
              >
                <v-icon> mdi-bell-ring </v-icon>
              </v-badge>

              <v-icon v-if="unShowNotification == 0">
                mdi-bell-ring
              </v-icon> </v-list-item-icon
            >BİLDİRİMLERİM
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="logout">
            <v-list-item-icon> <v-icon>mdi-logout</v-icon> </v-list-item-icon
            >ÇIKIŞ YAP
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-container>
      <v-snackbar
        :timeout="4000"
        v-model="successAlert"
        :multi-line="multiLine"
        color="green"
        shaped
        top
      >
        {{ successMsg }}
      </v-snackbar>
      <v-snackbar
        :timeout="4000"
        v-model="errorAlert"
        :multi-line="multiLine"
        color="red"
        shaped
        top
      >
        {{ errorMsg }}
      </v-snackbar>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-container>

    <!-- Başlık ekleme dialog box -->
    <v-btn
      bottom
      color="pink"
      dark
      fab
      fixed
      right
      v-if="loginInfoSuccess"
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" width="600px">
      <v-form ref="test">
        <v-card>
          <v-card-title class="indigo white--text"
            >BAŞLIK EKLE
            <v-spacer></v-spacer>
            <v-avatar color="indigo darken-4" dark>
              <v-icon dark @click="dialogHeadline = true"
                >mdi-information-variant</v-icon
              >
            </v-avatar>
          </v-card-title>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  id="category"
                  v-model="category"
                  name="category"
                  :items="catitems"
                  label="Kategori"
                  dense
                  item-text="name"
                  item-value="id"
                  color="blue-grey darken-2"
                  outlined
                ></v-select>
                <v-text-field
                  id="headline"
                  v-model="headline"
                  label="Başlık Adı"
                  name="headline"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  id="subheadline"
                  v-model="subheadline"
                  label="Alt Başlık Adı"
                  name="subheadline"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>

                <v-text-field
                  v-if="category == 1 || category == 2"
                  id="imdblink"
                  v-model="imdblink"
                  label="IMDB Linki"
                  name="imdblink"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  v-if="category == 3"
                  id="goodreadslink"
                  v-model="goodreadslink"
                  label="Goodreads Linki"
                  name="goodreadslink"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>
                <v-chip
                  class="ma-2"
                  color="blue-grey darken-2"
                  small
                  outlined
                  @click="
                    (linkshow = true),
                      (bknzshow = false),
                      (bknzheadline = ''),
                      (bknzid = '')
                  "
                >
                  <v-icon left> mdi-link-variant </v-icon>
                  Link
                </v-chip>

                <v-chip
                  class="ma-2"
                  color="blue-grey darken-2"
                  small
                  outlined
                  @click="
                    (bknzshow = true),
                      (linkshow = false),
                      (linkurl = ''),
                      (linkname = '')
                  "
                >
                  <v-icon left> mdi-comment-search </v-icon>
                  Bknz.
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="blue-grey darken-2"
                  small
                  outlined
                  @click="addSpoilerTags()"
                >
                  <v-icon left> mdi-alert-rhombus </v-icon>
                  Spoiler!
                </v-chip>
              </v-col>
              <v-col cols="12" v-if="linkshow">
                <v-text-field
                  id="linkurl"
                  v-model="linkurl"
                  label="Url Adresi"
                  name="linkurl"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  id="linkname"
                  v-model="linkname"
                  label="Url Tanım"
                  name="linkurl"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>
                <v-chip
                  class="ma-2"
                  color="red darken-4"
                  small
                  outlined
                  @click="linkclose()"
                >
                  <v-icon left> mdi-close-circle </v-icon>
                  İptal
                </v-chip>

                <v-chip
                  class="ma-2"
                  color="green darken-4"
                  small
                  outlined
                  @click="linkadd()"
                >
                  <v-icon left> mdi-plus-circle </v-icon>
                  Ekle
                </v-chip>
              </v-col>

              <v-col cols="12" v-if="bknzshow">
                <v-autocomplete
                  v-model="bknzheadline"
                  :items="entriesx"
                  :loading="isLoading"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  item-text="ShowText"
                  item-value="HeadlinesID"
                  placeholder="Başlık aramak için yazınız.."
                  return-object
                  outlined
                  dense
                  label="Başlık"
                  color="blue-grey darken-2"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="blue-grey"
                      class="white--text"
                      v-on="on"
                      small
                    >
                      <span v-text="item.ShowText"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-title
                      v-text="item.ShowText"
                    ></v-list-item-title>
                    <v-list-item-action v-if="item.SearchType == 0">
                      <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-action v-if="item.SearchType == 1">
                      <v-icon color="indigo">mdi-account</v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <v-text-field
                  id="bknzid"
                  v-model="bknzid"
                  label="Yorum ID"
                  name="bknzid"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                  @input="bknzheadline = ''"
                  @keypress="isNumber($event)"
                ></v-text-field>
                <v-chip
                  class="ma-2"
                  color="red darken-4"
                  small
                  outlined
                  @click="bknzclose()"
                >
                  <v-icon left> mdi-close-circle </v-icon>
                  İptal
                </v-chip>

                <v-chip
                  class="ma-2"
                  color="green darken-4"
                  small
                  outlined
                  @click="bknzadd()"
                >
                  <v-icon left> mdi-plus-circle </v-icon>
                  Ekle
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="article"
                  outlined
                  name="article"
                  label="Yorum giriniz..."
                  auto-grow
                  color="blue-grey darken-2"
                ></v-textarea>
                <v-text-field
                  id="taglist"
                  v-model="taglist"
                  label="Etiketler"
                  name="taglist"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="error"
              @click="cancelHeadlines()"
            >
              <v-icon left>mdi-cancel</v-icon>VAZGEÇ
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="success"
              @click="dialogHeadlineConfirm = true"
            >
              <v-icon left>mdi-pencil</v-icon>KAYDET
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- manifesto dialog box -->
    <v-dialog v-model="dialogMani" width="600px">
      <v-form ref="test">
        <v-card>
          <v-card-title class="indigo white--text"
            >SANAT SÖZLÜK MANİFESTOSU
          </v-card-title>
          <v-container>
            <v-card-text class="text--primary" v-html="this.manifest">
              {{ this.manifest }}
            </v-card-text>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="error"
              @click="dialogMani = false"
            >
              <v-icon left>mdi-cancel</v-icon>KAPAT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- iletişim dialog box -->
    <v-dialog v-model="dialogContact" width="600px">
      <v-form ref="contact">
        <v-card>
          <v-card-title class="indigo white--text">İLETİŞİM </v-card-title>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  id="contactEmail"
                  v-model="contactEmail"
                  label="E-Mail"
                  name="contactEmail"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="mailRules"
                ></v-text-field>
                <v-select
                  id="contactCategory"
                  v-model="contactCategory"
                  name="contactCategory"
                  :items="contactCatItems"
                  label="Kategori"
                  dense
                  item-text="name"
                  item-value="id"
                  color="blue-grey darken-2"
                  outlined
                  :rules="textRules"
                ></v-select>

                <v-text-field
                  id="contactTitle"
                  v-model="contactTitle"
                  label="Konu Başlığı"
                  name="contactTitle"
                  color="blue-grey darken-2"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="textRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="contactText"
                  outlined
                  name="contactText"
                  label="Mesaj giriniz..."
                  auto-grow
                  color="blue-grey darken-2"
                  :rules="textRules"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="error"
              @click="cancelContact()"
            >
              <v-icon left>mdi-cancel</v-icon>KAPAT
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="success"
              @click="saveContact()"
            >
              <v-icon left>mdi-email-send</v-icon>GÖNDER
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- başlık kuralları dialog box -->
    <v-dialog v-model="dialogHeadline" width="600px">
      <v-form ref="test">
        <v-card>
          <v-card-title class="indigo white--text">
            BAŞLIK NASIL EKLENİR?
          </v-card-title>
          <v-container>
            <v-card-text class="text--primary" v-html="this.headlineRules">
              {{ this.headlineRules }}
            </v-card-text>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="error"
              @click="dialogHeadline = false"
            >
              <v-icon left>mdi-cancel</v-icon>KAPAT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Headline Edit Confirm dialog box -->
    <v-dialog v-model="dialogHeadlineConfirm" max-width="500px">
      <v-card>
        <v-container>
          <v-card-text class="text--primary" align="center">
            <h3>Girdiğiniz başlığı yayınlamak istediğinizden emin misiniz?</h3>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogHeadlineConfirm = false"
              >Vazgeç</v-btn
            >
            <v-btn color="green" text @click="saveheadlines()">Tamam</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-system-bar v-if="sidebar" dark color="indigo">
      <v-chip-group mandatory>
        <v-chip
          class="ma-2 text-uppercase"
          outlined
          style="cursor: pointer"
          x-small
          @click="dialogMani = true"
        >
          MANIFESTO
        </v-chip>
        <v-chip
          class="ma-2 text-uppercase"
          outlined
          style="cursor: pointer"
          x-small
          @click="dialogContact = true"
        >
          İLETİŞİM
        </v-chip>
        <v-chip
          class="ma-2 text-uppercase"
          outlined
          style="cursor: pointer"
          x-small
          @click="dialogHeadline = true"
        >
          BAŞLIK KURALLARI
        </v-chip>
      </v-chip-group>
    </v-system-bar>
  </v-app>
</template>


<script>
import auth from "../api/auth";
import router from "../router";
import axios from "axios";
// import { VueEditor } from "vue2-editor";

export default {
  // components: {
  //   VueEditor
  // },
  props: {
    source: String,
  },
  watch: {
    // "$route.params.id": function () {
    //   console.log(this.$route)
    //   if (this.$route.name === "login") {
    //     this.sidebar = false;
    //   } else {
    //     this.sidebar = true;
    //   }
    // },
    search(valx) {
      this.bknzid = "";
      if (valx == null) return;
      if (valx.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entriesx = [];
      var params = {
        SearchKey: valx,
        ActiveUserID: this.accountid,
        SearchType: 1,
      };
      this.$http
        .post(auth.API_URL + "api/Search/MainSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entriesx = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },

    searchMain(val) {
      if (val == null) return;
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entries = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.accountid,
      };
      this.$http
        .post(auth.API_URL + "api/Search/MainSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entries = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },
  },
  data() {
    return {
      multiLine: null,
      articleList: "",
      dialog: false,
      dialogMani: false,
      drawer: null,
      items: [],
      loginSuccess: true,
      loginInfoSuccess: false,
      btnlabel: "GİRİŞ YAP / ÜYE OL",
      menuUserName: null,
      menuUserUrl: null,
      catitems: [],
      catlist: [],
      headline: "",
      article: "",
      category: 0,
      successMsg: "",
      successAlert: false,
      errorMsg: "",
      errorAlert: false,
      accountid: "",
      accountuuid: "",
      accountType: 0,
      count: 0,
      tokenInfo: null,
      textRules: [(v) => !!v || "Girilmesi zorunlu alan."],
      customToolbar: [["link"], [{ list: "ordered" }, { list: "bullet" }]],
      unReadMessage: 0,
      unShowNotification: 0,
      imdblink: "",
      goodreadslink: "",
      linkurl: "",
      linkname: "",
      subheadline: "",
      linkshow: false,
      bknzshow: false,
      bknzid: "",
      taglist: "",
      taglisttxt: "",

      entries: [],
      entriesx: [],
      isLoading: false,
      bknzheadline: [],
      search: "",
      searchMain: "",
      mainSearching: "",
      sidebar: true,
      routname: "",
      manifest: "",
      headlineRules: "",
      fixedList: [],

      dialogHeadlineConfirm: false,

      mailRules: [
        (v) => !!v || "Lütfen e-mail adresinizi giriniz.",
        (v) => /.+@.+\..+/.test(v) || "Geçerli bir e-mail adresi giriniz",
      ],

      contactCategory: 0,
      contactEmail: "",
      contactTitle: "",
      contactText: "",
      dialogContact: "",
      dialogHeadline: false,
      contactCatItems: [
        {
          id: "0",
          name: "İçerik",
        },
        {
          id: "1",
          name: "Geri Bildirim",
        },
        {
          id: "2",
          name: "Diğer",
        },
      ],
    };
  },
  methods: {
    goSearching,
    loginEntry,
    logout,
    saveheadlines,
    gohompage,
    gouserpage,
    goabout,
    loadpages,
    goadminmain,
    gomessagemain,
    gonotification,
    cancelHeadlines,
    addSpoilerTags,
    linkclose,
    linkadd,
    bknzclose,
    bknzadd,
    cancelContact,
    saveContact,
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    var tparam = {};
    this.$http.post(auth.getTokenUrlNoUSer(), tparam).then(
      (response) => {
        window.localStorage.removeItem("token");
        window.localStorage.setItem("token", response.body.Token);
        this.tokenInfo = response.body.Token;
        this.loadpages();
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
  },
};

function loadpages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
      UnRead: true,
    };

    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.ID == 0) {
            this.logout();
          } else {
            this.loginSuccess = false;
            this.loginInfoSuccess = true;
            this.menuUserName = response.body.Title;
            this.menuUserUrl = response.body.UrlText;
            this.accountid = response.body.UserID;
            this.accountuuid = response.body.UUID;
            this.accountType = response.body.UserType;
            this.unReadMessage = response.body.UnReadMessage;
            this.unShowNotification = response.body.UnShowNotifaction;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    this.loginSuccess = true;
    this.loginInfoSuccess = false;
  }

  if (this.$route.fullPath === "/") {
    this.$router.push({ name: "homepage" });
  }

  this.items = [];
  var params = {};

  this.progress = false;
  this.$http
    .post(auth.API_URL + "api/CategoryAD/CategoryListAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.progress = false;
        this.catlist = response.body;

        this.catlist.forEach((element) => {
          var elementItem = {
            id: element.CategoryID,
            action: element.CategoryIcon,
            title: element.CategoryName,
            headlines: element.Headlines,
            items: [],
          };

          this.articleList = elementItem.headlines;

          this.articleList.forEach((subelement) => {
            var articleItem = {
              id: subelement.HeadlineID,
              title:
                subelement.SubTitle === ""
                  ? subelement.HeadlineName
                  : subelement.HeadlineName +
                    '<span class="grey--text text--lighten-1">' +
                    " (" +
                    subelement.SubTitle +
                    ")" +
                    "</span>",
              count:
                '<span class="grey--text text--lighten-1"> (' +
                subelement.Count +
                ")</span>",
              link: {
                name: "headline",
                params: {
                  name: subelement.UrlText,
                  id: subelement.HeadlineID,
                },
              },
            };
            elementItem.items.push(articleItem);
          });

          var articleItem = {
            title: "Daha Fazlası...",
            count: "",
            link: {
              name: "headlineAll",
              params: {
                categoryid: element.CategoryID,
                categoryname: element.CategoryName,
              },
            },
          };
          elementItem.items.push(articleItem);

          this.items.push(elementItem);

          var elementListItem = {
            id: element.CategoryID,
            name: element.CategoryName,
          };
          if (elementListItem.id != 0) {
            this.catitems.push(elementListItem);
          }
        });
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );

  // manifesto yazısı çekilir...
  var paramstext = {
    FixedType: 0,
  };

  this.$http
    .post(auth.API_URL + "api/Other/FixedTextList", paramstext, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.fixedList = response.body;
        this.fixedList.forEach((element) => {
          if (element.FixedTitle === "Manifesto") {
            this.manifest = element.FixedText;
          }
          if (element.FixedTitle === "HeadlineRules") {
            this.headlineRules = element.FixedText;
          }
        });
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function gouserpage() {
  this.$router.push({
    name: "user",
    params: { userUrl: this.menuUserUrl, userGui: this.accountuuid },
  });
}

function goabout() {
  this.$router.push({ name: "about" });
}

function gohompage() {
  // this.$router.push({ name: "homepage" });
  router.push({
    path: "/",
  });
  window.location.reload();
}

function goadminmain() {
  this.$router.push({ name: "adminmain" });
}

function gomessagemain() {
  this.$router.push({ name: "messagemain" });
  window.location.reload();
}

function gonotification() {
  this.$router.push({
    name: "notification",
    params: { userid: this.accountid },
  });
}

function loginEntry() {
  this.sidebar = false;
  this.$router.push({ name: "login" });
}

function logout() {
  this.sidebar = true;
  window.localStorage.removeItem("userInfo");
  window.localStorage.removeItem("userSessionID");
  router.push({
    path: "/",
  });
  window.location.reload();
}

function saveheadlines() {
  var articletxt = auth.convertToHtmlFromText(this.article);

  if (this.headline.trim() === "") {
    this.errorMsg = "Bir başlık girmeniz gerekmektedir.";
    this.errorAlert = true;
    this.dialogHeadlineConfirm = false;
    return;
  }

  if (this.category === 0) {
    this.errorMsg = "Bir kategori girmeniz gerekmektedir.";
    this.errorAlert = true;
    this.dialogHeadlineConfirm = false;
    return;
  }

  if (this.article.trim() === "") {
    this.errorMsg = "Bir yorum girmeniz gerekmektedir.";
    this.errorAlert = true;
    this.dialogHeadlineConfirm = false;
    return;
  }

  if (this.taglist.trim() !== "") {
    var ecount = 0;
    var result = this.taglist.split(",");
    result.forEach((element) => {
      if (element.trim() !== "") {
        this.taglisttxt = this.taglisttxt + "#" + element.trim() + " ";
        ecount = ecount + 1;
      }
    });
    if (ecount > 5) {
      this.errorMsg = "En fazla 5 etiket girebilirsiniz.";
      this.errorAlert = true;
      this.taglisttxt = "";
      this.dialogHeadlineConfirm = false;
      return;
    }
  }

  var params = {
    UserID: this.accountid,
    HeadlineName: this.headline.trim(),
    CategoryID: this.category,
    ArticleText: articletxt.trim(),
    IMDBLink: this.imdblink.trim(),
    GoodreadsLink: this.goodreadslink.trim(),
    SubTitle: this.subheadline.trim(),
    TagList: this.taglisttxt.trim(),
  };

  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineSave", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.dialog = false;
          this.headline = "";
          this.category = "";
          this.article = "";
          this.taglist = "";
          this.taglisttxt = "";
          this.dialogHeadlineConfirm = false;
          window.location.reload();
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
          this.dialogHeadlineConfirm = false;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function cancelHeadlines() {
  (this.headline = ""), (this.imdblink = ""), (this.goodreadslink = "");
  this.article = "";
  this.category = 0;
  this.dialog = false;
  this.linkshow = false;
  this.bknzshow = false;
  this.bknzheadline = "";
  this.taglist = "";
  this.taglisttxt = "";
}

function addSpoilerTags() {
  this.article =
    this.article == ""
      ? this.article + "(spoiler!)" + "\n\n" + "(spoiler!)"
      : this.article + "\n\n" + "(spoiler!)" + "\n\n" + "(spoiler!)";
}

function linkclose() {
  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function linkadd() {
  if (this.linkurl == "") {
    this.errorMsg = "Link eklemek için bir url adresi girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  this.article =
    this.article + "[:" + this.linkurl + "|" + this.linkname + ":]";

  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function bknzclose() {
  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function bknzadd() {
  if (this.bknzid == "" && this.bknzheadline == "") {
    this.errorMsg = "Bknz eklemek için bir başlık ya da yorum id girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.bknzid != "") {
    this.article = this.article + "{:" + this.bknzid + ":}";
  }

  if (this.bknzheadline != "") {
    this.article =
      this.article +
      "{;" +
      this.bknzheadline.HeadlineName +
      "|" +
      this.bknzheadline.UrlText +
      "|" +
      this.bknzheadline.HeadlinesID +
      ";}";
  }

  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function goSearching() {
  this.mainSearching.ShowText = "";
  this.entries = null;

  if (this.mainSearching.SearchType == 0) {
    this.$router.push({
      name: "headline",
      params: {
        name: this.mainSearching.UrlText,
        id: this.mainSearching.HeadlinesID,
      },
    });
  }

  if (this.mainSearching.SearchType == 1) {
    this.$router.push({
      name: "user",
      params: {
        userUrl: this.mainSearching.UserTitle,
        userGui: this.mainSearching.UserUUID,
      },
    });
  }
}

function cancelContact() {
  this.contactEmail = "";
  this.contactTitle = "";
  this.contactCatItems.id = 0;
  this.contactText = "";
  this.$refs.contact.reset();
  this.dialogContact = false;
}

function saveContact() {
  if (this.$refs.contact.validate()) {
    var params = {
      UserID: this.accountid,
      Email: this.contactEmail,
      ContactTitle: this.contactTitle,
      ContactCategory: this.contactCategory,
      ContactMessage: this.contactText,
    };

    this.$http
      .post(auth.API_URL + "api/Other/ContactSave", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            let formData = new FormData();
            formData.append("Konu", this.contactTitle);
            formData.append("to", "mustafa.sahintas@gmail.com");
            formData.append("from", this.contactEmail);
            formData.append("formattedMessage", this.contactText);
            formData.append("mailType", "2");
            formData.append("Category", this.contactCategory);
            formData.append("UserName", this.menuUserName);
            axios.defaults.baseURL = auth.API_URL;
            axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
            axios
              .post(auth.API_URL + "api/HelpersAD/SendEmail", formData, {
                headers: {},
              })
              .then((response) => {
                if (response.statusText == "OK") {
                  this.successMsg = "Mesajınız gönderildi.";
                  this.successAlert = true;
                }
              })
              .catch(function () {});

            this.cancelContact();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}
</script>

<style>
@import "../assets/css/custom.css";
</style>