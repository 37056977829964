
import Vue from 'vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)
import router from '../router'

// local
// const API_URL = 'http://localhost:44339/'
// const URLROOT = 'http://localhost:8080/#/'
// const URL_MAIL = 'http://localhost:8080/#/'

// live
// const API_URL = 'https://api.falconstonesoftware.com/'
const API_URL = 'https://api.sanatsozluk.com/'
const URLROOT = 'https://www.sanatsozluk.com/#/'
const URL_MAIL = 'https://www.sanatsozluk.com/#/'


const TOKEN_URL = API_URL + 'tokenArtDictionary'
const TOKEN_URL_NOUSER = API_URL + 'tokenArtDictionaryNoUser'




export default {
  API_URL: API_URL,
  URLROOT: URLROOT,
  URL_MAIL: URL_MAIL,
  user: {
    authenticated: false,
    email: '',
    title: ''
  },

  getFromBetween: {
    results: [],
    string: "",
    getFromBetween: function (sub1, sub2) {
      if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
      var SP = this.string.indexOf(sub1) + sub1.length;
      var string1 = this.string.substr(0, SP);
      var string2 = this.string.substr(SP);
      var TP = string1.length + string2.indexOf(sub2);
      return this.string.substring(SP, TP);
    },
    removeFromBetween: function (sub1, sub2) {
      if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
      var removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
      this.string = this.string.replace(removal, "");
    },
    getAllResults: function (sub1, sub2) {
      // first check to see if we do have both substrings
      if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;

      // find one result
      var result = this.getFromBetween(sub1, sub2);
      // push it to the results array
      this.results.push(result);
      // remove the most recently found one from the string
      this.removeFromBetween(sub1, sub2);

      // if there's more substrings
      if (this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
        this.getAllResults(sub1, sub2);
      }
      else return;
    },
    get: function (string, sub1, sub2) {
      this.results = [];
      this.string = string;
      this.getAllResults(sub1, sub2);
      return this.results;
    }
  },

  login(context, creds, redirect) {
    context.$http.post(TOKEN_URL, creds).then((response) => {
      this.user.authenticated = true
      this.user.email = creds.Email
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('userSessionID')
      window.localStorage.setItem('token', response.body.Token)
      window.localStorage.setItem('redirect', redirect)


      if (response.body.ResponseInfo.Status === true) {
        window.localStorage.setItem('userSessionID', response.body.UserSessionID)

        router.push({
          path: '/'
        })
        window.location.reload()
      }
      else {
        context.errorMsg = response.body.ResponseInfo.ResponseMessage
        context.errorAlert = true
      }


    }, (response) => {
      if (response.body.ResponseInfo.Status === false) {
        context.errorMsg = 'Service Unavailable'
        context.errorAlert = true
        context.bekle = false;
      } else {
        context.errorMsg = 'Giriş yapılamadı. Lütfen bilgilerinizi kontrol ediniz.'
        context.errorAlert = true
        context.bekle = false;
      }
    })


  },

  gettoken(context, creds, redirect) {

    context.$http.post(TOKEN_URL_NOUSER, creds).then((response) => {
      window.localStorage.removeItem('token')
      window.localStorage.setItem('token', response.body.Token)
      window.localStorage.setItem('redirect', redirect)

    }, (response) => {
      if (response.body.ResponseInfo.Status === false) {
        context.errorMsg = 'Service Unavailable'
        context.errorAlert = true
        context.bekle = false;
      } else {
        context.errorMsg = 'Service Unavailable'
        context.errorAlert = true
        context.bekle = false;
      }
    })
  },


  getAuthHeader() {
    return window.localStorage.getItem('token')
  },


  getTokenUrlNoUSer() {
    return TOKEN_URL_NOUSER
  },


  sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
  },

  convertToHtmlFromText(text) {
    var R = text

    // article içeriği düzenlenir
    // link düzenleme
    var result = this.getFromBetween.get(R, "[:", ":]");
    result.forEach((element) => {
      var subelement = element.split("|");
      var changeTxt =
        "<a href='" +
        subelement[0] +
        "' rel='noopener noreferrer' target='_blank' style='text-decoration:none'>" +
        subelement[1] +
        "&#128279</a>";
      R = R.replace(element, changeTxt);
    });
    R = R.replace("[:", "");
    R = R.replace(":]", "");


    // bknz headline düzenleme
    var bknzheadlineresult = this.getFromBetween.get(R, "{;", ";}");
    bknzheadlineresult.forEach((element) => {
      var subelement = element.split("|");
      var changeBknzHeadlineTxt =
        "(Bkz:" +
        "<a href='" +
        this.URLROOT +
        "Headline/" +
        subelement[1] + "/" + subelement[2] +
        "' rel='noopener noreferrer' target='_blank' style='text-decoration:none'>" +
        subelement[0] +
        "</a>)";
      R = R.replace(element, changeBknzHeadlineTxt);
    });
    R = R.replace("{;", "");
    R = R.replace(";}", "");


    //bknz aricle düzenleme
    var bknzarticleresult = this.getFromBetween.get(R, "{:", ":}");
    bknzarticleresult.forEach((element) => {
      var changeBknzArticleTxt =
        "(Bkz:" +
        "<a href='" +
        this.URLROOT +
        "Article/" +
        element +
        "' rel='noopener noreferrer' target='_blank' style='text-decoration:none'>#" +
        element +
        "</a>)";
      R = R.replace(element, changeBknzArticleTxt);
    });
    R = R.replace("{:", "");
    R = R.replace(":}", "");

    // spoiler düzenleme
    R = R.replace(/\n\r?/g, "<br>");
    R = R.replaceAll(
      "(spoiler!)",
      "<font color='red'>(spoiler!)</font>"
    );
    // içerik düzenleme bitti..

    return R
  },

  convertToTextFromHtml(text) {
    var R = text

    R = R.replaceAll("<br>", '\n');
    R = R.replaceAll(
      "<font color='red'>(spoiler!)</font>",
      "(spoiler!)"
    );

    var result = this.getFromBetween.get(R, "(Bkz:", "</a>)");
    result.forEach((element) => {

      if (element.includes('Article')) {
        var subelement = element.split(">#");
        R = R.replace(element, "{:" + subelement[1] + ':}')
      }
      else if (element.includes('Headline')) {
        var subelementhead = element.split("'>");
        var subelementhead2 = subelementhead[0].split("/");
        var subelementhead3 = subelementhead2[6].split("' ");
        R = R.replace(element, "{;" + subelementhead[1] + "|" + subelementhead2[5] + "|" + subelementhead3[0] + ';}')
      }
    });

    var resulturl = this.getFromBetween.get(R, "<a href='", "</a>");
    resulturl.forEach((element) => {
      var subelementurl = element.split("'")
      R = R.replace(element, "[:" + subelementurl[0] + "|" + this.getFromBetween.get(subelementurl[7], ">", "&") + ':]')
    });

    R = R.replaceAll("(Bkz:", "")
    R = R.replaceAll("</a>)", "")
    
    R = R.replaceAll("</a>", "")
    R = R.replaceAll("<a href='", "")



    return R
  }


}