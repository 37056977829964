import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'G-EXCPE836TM'
})

// import VueGtag from 'vue-gtag'

// Vue.use(VueGtag, {
//   config: { id: 'G-EXCPE836TM' } 
// })

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
