<template>
  <div id="app">
    <v-app id="inspire">
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>YÖNETİCİ ANA SAYFASI</v-toolbar-title>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <v-row dense>
          <v-col lg="6" md="12" sm="12" xs="12" class="">
            <v-card color="#385F73" dark>
              <v-card-title class="headline">
                <v-avatar color="#385F73" dark>
                  <v-icon dark>mdi-shape</v-icon>
                </v-avatar>

                KATAGORİ YÖNETİMİ
              </v-card-title>

              <v-card-actions>
                <v-btn text @click="gocategory()">
                  KATAGORİ SAYFASINA GİT
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col lg="6" md="12" sm="12" xs="12" class="">
            <v-card color="#385F73" dark>
              <v-card-title class="headline">
                <v-avatar color="#385F73" dark>
                  <v-icon dark>mdi-view-headline</v-icon>
                </v-avatar>
                BAŞLIK YÖNETİMİ
              </v-card-title>

              <v-card-actions>
                <v-btn text @click="goheadline()"> BAŞLIK SAYFASINA GİT </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col lg="6" md="12" sm="12" xs="12" class="">
            <v-card color="#385F73" dark>
              <v-card-title class="headline"> 
                <v-avatar color="#385F73" dark>
                  <v-icon dark>mdi-comment-text</v-icon>
                </v-avatar>                
                YORUM YÖNETİMİ 
              </v-card-title>

              <v-card-actions>
                <v-btn text @click="goarticle()"> YORUM SAYFASINA GİT </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col lg="6" md="12" sm="12" xs="12" class="">
            <v-card color="#385F73" dark>
              <v-card-title class="headline"> 
                <v-avatar color="#385F73" dark>
                  <v-icon dark>mdi-account-voice</v-icon>
                </v-avatar>                 
                ŞİKAYET YÖNETİMİ </v-card-title>

              <v-card-actions>
                <v-btn text @click="gocomplaint()">
                  ŞİKAYET SAYFASINA GİT
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col lg="6" md="12" sm="12" xs="12" class="">
            <v-card color="#385F73" dark>
              <v-card-title class="headline"> 
                <v-avatar color="#385F73" dark>
                  <v-icon dark>mdi-account</v-icon>
                </v-avatar>                  
                KULLANICI YÖNETİMİ </v-card-title>

              <v-card-actions>
                <v-btn text @click="gouser()"> KULLANICI SAYFASINA GİT </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col lg="6" md="12" sm="12" xs="12" class="">
            <v-card color="#385F73" dark>
              <v-card-title class="headline"> 
                <v-avatar color="#385F73" dark>
                  <v-icon dark>mdi-numeric</v-icon>
                </v-avatar>                  
                İSTATİSTİK </v-card-title>

              <v-card-actions>
                <v-btn text @click="gostatistics()"> İSTATİSTİK SAYFASINA GİT </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>          
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import auth from "../../api/auth";
import router from "../../router";

export default {
  data: () => ({}),

  methods: {
    loadAdminPages,
    gocategory,
    goheadline,
    goarticle,
    gocomplaint,
    gouser,
    gostatistics,
  },
  mounted() {
    this.loadAdminPages();
  },
};

function loadAdminPages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function gocategory() {
  this.$router.push({ name: "admincategory" });
}

function goheadline() {
  this.$router.push({ name: "adminheadline" });
}

function goarticle() {
  this.$router.push({ name: "adminarticle" });
}

function gocomplaint() {
  this.$router.push({ name: "admincomplaint" });
}

function gouser() {
  this.$router.push({ name: "adminuser" });
}

function gostatistics() {
  this.$router.push({ name: "adminstatistics" });
}
</script>