<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card flat>
          <v-toolbar dense>
            <v-toolbar-title>BAŞLIK YÖNETİMİ</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="indigo"
              @click="goHome()"
              small
            >
              <v-icon>mdi-home-import-outline </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <v-card>
          <v-tabs v-model="tab" background-color="indigo" dark>
            <v-tab @click="switchList(0)"> Başlık Listesi </v-tab>
            <v-tab @click="switchList(1)">
              Yayından Kaldırılan Başlıklar
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table
                :headers="headlines"
                :items="headlinelist"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-btn color="warning" fab dark small @click="goFilter()">
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.headlineName`]="{ item }">
                  <router-link
                    style="text-decoration: none; color: inherit"
                    :to="{
                      name: 'headline',
                      params: { name: item.headlineUrl, id: item.headlineId },
                    }"
                    target="_blank"
                  >
                    <v-chip
                      class="ma-2"
                      color="blue-grey darken-4"
                      outlined
                      style="cursor: pointer"
                      small
                    >
                      {{ item.headlineShowName }}
                    </v-chip>
                  </router-link>
                </template>

                <template v-slot:[`item.categoryName`]="{ item }">
                  <v-chip class="ma-2" color="pink" outlined small>
                    {{ item.categoryName }}
                  </v-chip>
                </template>

                <template v-slot:[`item.userName`]="{ item }">
                  <router-link
                    style="text-decoration: none; color: inherit"
                    :to="{
                      name: 'user',
                      params: {
                        userUrl: item.userUrl,
                        userGui: item.userUUID,
                      },
                    }"
                    target="_blank"
                  >
                    <v-chip
                      class="ma-2 text-uppercase"
                      color="indigo"
                      outlined
                      style="cursor: pointer"
                      small
                    >
                      {{ item.userName }}
                    </v-chip>
                  </router-link>
                </template>

                <template v-slot:[`item.headlineDate`]="{ item }">
                  <v-chip class="ma-2" color="brown" outlined small>
                    {{ item.headlineDate }}
                  </v-chip>
                </template>

                <template v-slot:[`item.firstComment`]="{ item }">
                  <router-link
                    v-if="!item.firstArticleStatus"
                    style="text-decoration: none; color: inherit"
                    :to="{
                      name: 'article',
                      params: { articleid: item.firstArticleID },
                    }"
                    target="_blank"
                  >
                    <v-chip
                      v-model="item.firstArticleStatus"
                      color="success"
                      outlined
                      class="ma-2"
                      small
                      style="cursor: pointer"
                    >
                      <div>Yayında</div>
                    </v-chip>
                  </router-link>

                  <v-chip
                    v-if="item.firstArticleStatus"
                    v-model="item.firstArticleStatus"
                    color="error"
                    outlined
                    class="ma-2"
                    small
                  >
                    <div v-if="item.firstArticleStatus">Yayında Değil</div>
                  </v-chip>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-model="item.status"
                    :color="item.status ? 'success' : 'error'"
                    outlined
                    class="ma-2"
                    small
                  >
                    <div v-if="!item.status">Onaylanmamış</div>
                    <div v-if="item.status">Onaylandı</div>
                  </v-chip>
                </template>

                <template v-slot:[`item.commentCount`]="{ item }">
                  <v-chip>
                    {{ item.commentCount }}
                  </v-chip>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-icon color="indigo" class="mr-2" @click="goEdit(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    color="green"
                    class="mr-2"
                    @click="goConfirm(item.headlineId,item.headlineName,item.userId )"
                    :disabled="item.status ? true : false"
                  >
                    mdi-check-decagram
                  </v-icon>
                  <v-icon
                    color="red"
                    class="mr-2"
                    @click="goRemove(item.headlineId,item.headlineName,item.userId)"
                  >
                    mdi-close-octagon
                  </v-icon>
                  <v-icon color="brown" class="mr-2" @click="goTransfer(item)">
                    mdi-folder-swap
                  </v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="removeheadlines"
                :items="removeheadlinelist"
                :items-per-page="10"
                class="elevation-1"
              >

                <template v-slot:[`item.headlineName`]="{ item }">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-4"
                    outlined
                    small
                  >
                    {{ item.headlineShowName }}
                  </v-chip>
                </template>

                <template v-slot:[`item.categoryName`]="{ item }">
                  <v-chip class="ma-2" color="pink" outlined small>
                    {{ item.categoryName }}
                  </v-chip>
                </template>

                <template v-slot:[`item.userName`]="{ item }">
                  <router-link
                    style="text-decoration: none; color: inherit"
                    :to="{
                      name: 'user',
                      params: {
                        userUrl: item.userUrl,
                        userGui: item.userUUID,
                      },
                    }"
                    target="_blank"
                  >
                    <v-chip
                      class="ma-2 text-uppercase"
                      color="indigo"
                      outlined
                      style="cursor: pointer"
                      small
                    >
                      {{ item.userName }}
                    </v-chip>
                  </router-link>
                </template>

                <template v-slot:[`item.headlineDate`]="{ item }">
                  <v-chip class="ma-2" color="brown" outlined small>
                    {{ item.headlineDate }}
                  </v-chip>
                </template>

                <template v-slot:[`item.removeDate`]="{ item }">
                  <v-chip class="ma-2" color="red" outlined small>
                    {{ item.removeDate }}
                  </v-chip>
                </template>

                <template v-slot:[`item.removeReason`]="{ item }">
                  <v-chip class="ma-2" color="red" outlined small>
                    {{ item.removeReason }}
                  </v-chip>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    color="red"
                    class="mr-2"
                    @click="goRemoveCancel(item.headlineId,item.headlineName,item.userId)"
                  >
                    mdi-backspace
                  </v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>


      <v-dialog v-model="dialogConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>Bu başlığı onaylamak istediğinizden emin misiniz?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="confirmSave()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFilter" max-width="500px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text"
              >Filtre Seçimi</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Başlangıç Tarihi"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        İptal
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        Tamam
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="Bitiş Tarihi"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        İptal
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date2)"
                      >
                        Tamam
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-radio-group v-model="confirmGroup" mandatory>
                    <v-radio label="Onaylanmamışlar" value="0"></v-radio>
                    <v-radio label="Onaylanmışlar" value="1"></v-radio>
                    <v-radio label="Hepsi" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogFilter = false"
              >
                <v-icon left>mdi-home</v-icon>İPTAL
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="FilterSave()"
              >
                <v-icon left>mdi-content-save</v-icon>UYGULA
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum silme dialog box -->
      <v-dialog v-model="dialogRemove" width="500px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text"
              >BAŞLIĞI YAYINDAN KALDIR</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="removeReason"
                    outlined
                    name="removeReason"
                    label="Yayından Kaldırma Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogRemove = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="dialogRemoveConfirm = true"
              >
                <v-icon left>mdi-delete</v-icon>YAYINDAN KALDIR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogRemoveConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>
                Bu başlığı yayından kaldırmak istediğinizden emin misiniz?
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogRemoveConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="removeSave()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRemoveCancel" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>
                Bu başlığı tekrardan yayına almak istediğinizden emin misiniz?
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogRemoveCancel = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="removeCancelSave()"
                >Tamam</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Headline Transfer dialog box -->
      <v-dialog v-model="dialogTransfer" max-width="650px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text">BAŞLIĞI TAŞI</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip class="ma-2" color="red" outlined>
                    {{
                      "Taşınan Başlık : " + this.transferItem.headlineShowName
                    }}
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="headlineSearching"
                    :loading="isLoading"
                    :items="entries"
                    :search-input.sync="search"
                    hide-no-data
                    hide-details
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Aramak için yazınız.."
                    class="mx-4"
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action>
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              v-if="
                typeof this.headlineSearching !== 'undefined' &&
                this.headlineSearching !== ''
              "
            >
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip class="ma-2" color="red" outlined>
                    {{ "Başlık Bilgileri :" }}
                  </v-chip>
                </v-col>

                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-4"
                    outlined
                    small
                  >
                    {{ "Başlık Adı : " + this.headlineSearching.HeadlineName }}
                  </v-chip>
                </v-col>
                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-4"
                    outlined
                    small
                  >
                    {{ "Alt Başlık Adı : " + this.headlineSearching.SubTitle }}
                  </v-chip>
                </v-col>
                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-4"
                    outlined
                    small
                  >
                    {{ "Kategori : " + this.headlineSearching.CategoryName }}
                  </v-chip>
                </v-col>
                <v-col cols="6">
                  <router-link
                    style="text-decoration: none; color: inherit"
                    :to="{
                      name: 'headline',
                      params: {
                        name: this.headlineSearching.UrlText,
                        id: this.headlineSearching.HeadlinesID,
                      },
                    }"
                    target="_blank"
                  >
                    <v-btn class="ma-2" tile outlined color="indigo" small>
                      <v-icon left>mdi-home-import-outline </v-icon>BAŞLIK
                      SAYFASINA GİT
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogTransfer = false"
              >
                <v-icon left>mdi-home</v-icon>İPTAL
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="dialogTransferConfirm = true"
              >
                <v-icon left>mdi-content-save</v-icon>TAŞI
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Headline Transfer Confirm dialog box -->
      <v-dialog v-model="dialogTransferConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>
                {{ this.transferItem.headlineShowName }} başlığını
                {{ this.headlineSearching.ShowText }} başlığına taşımak
                istediğinizden emin misiniz?
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogTransferConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="TransferSave()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Headline Edit dialog box -->
      <v-dialog v-model="dialogEdit" width="600px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text"
              >BAŞLIK DÜZENLEME
            </v-card-title>

            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    id="category"
                    v-model="category"
                    name="category"
                    :items="catlist"
                    label="Kategori"
                    dense
                    item-text="name"
                    item-value="id"
                    color="blue-grey darken-2"
                    outlined
                  ></v-select>
                  <v-text-field
                    id="headline"
                    v-model="headline"
                    label="Başlık Adı"
                    name="headline"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    id="subheadline"
                    v-model="subheadline"
                    label="Alt Başlık Adı"
                    name="subheadline"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>

                  <v-text-field
                    v-if="category == 1 || category == 2"
                    id="imdblink"
                    v-model="imdblink"
                    label="IMDB Linki"
                    name="imdblink"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    v-if="category == 3"
                    id="goodreadslink"
                    v-model="goodreadslink"
                    label="Goodreads Linki"
                    name="goodreadslink"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    id="taglist"
                    v-model="taglist"
                    label="Etiketler"
                    name="taglist"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-textarea
                    v-model="updatereason"
                    outlined
                    name="updatereason"
                    label="Güncelleme nedenini giriniz..."
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogEdit = false"
              >
                <v-icon left>mdi-cancel</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="dialogEditConfirm = true"
              >
                <v-icon left>mdi-pencil</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Headline Edit Confirm dialog box -->
      <v-dialog v-model="dialogEditConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>
                Başlıkta yaptığınız tüm değişiklikleri kaydetmek istediğinizden
                emin misiniz?
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogEditConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="EditSave()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import auth from "../../api/auth";
import router from "../../router";

export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    tab: null,
    dialogConfirm: false,
    dialogFilter: false,
    dialogRemove: false,
    dialogRemoveConfirm: false,
    dialogRemoveCancel: false,
    dialogTransfer: false,
    dialogTransferConfirm: false,
    dialogEdit: false,
    dialogEditConfirm: false,
    confirmGroup: 0,
    headlines: [
      {
        text: "Başlık Adı",
        align: "start",
        sortable: false,
        value: "headlineName",
      },
      {
        text: "Katagori",
        align: "start",
        sortable: false,
        value: "categoryName",
      },
      {
        text: "Yazar Adı",
        align: "start",
        sortable: false,
        value: "userName",
      },
      {
        text: "Başlık Tarihi",
        value: "headlineDate",
        align: "start",
        sortable: false,
      },
      {
        text: "İlk Yorum",
        value: "firstComment",
        align: "start",
        sortable: false,
      },
      {
        text: "Durum",
        value: "status",
        align: "start",
        sortable: false,
      },
      {
        text: "Yorum Sayısı",
        value: "commentCount",
        align: "end",
        sortable: false,
      },
      { text: "ID", value: "headlineId", align: " d-none", sortable: false },
      { text: "İşlemler", value: "action", sortable: false },

      // { text: "Aktif/Pasif", value: "active", sortable: false },
      // { text: "ID", value: "id", align: " d-none", sortable: false },
      // { text: "İşlemler", value: "action", sortable: false },
    ],
    headlinelist: [],

    removeheadlines: [
      {
        text: "Başlık Adı",
        align: "start",
        sortable: false,
        value: "headlineName",
      },
      {
        text: "Katagori",
        align: "start",
        sortable: false,
        value: "categoryName",
      },
      {
        text: "Yazar Adı",
        align: "start",
        sortable: false,
        value: "userName",
      },
      {
        text: "Başlık Tarihi",
        value: "headlineDate",
        align: "start",
        sortable: false,
      },
      {
        text: "Kaldırılma Tarihi",
        value: "removeDate",
        align: "start",
        sortable: false,
      },
      {
        text: "Kaldırılma Nedeni",
        value: "removeReason",
        align: "start",
        sortable: false,
      },
      { text: "İşlemler", value: "action", sortable: false },
    ],
    removeheadlinelist: [],

    loginSuccess: false,
    activeuserid: 0,
    accountname: "",
    headlineName: "",
    headlineShowName: "",
    userName: "",
    headlineDate: null,
    headlineId: 0,
    headlineUrl: "",
    userUrl: "",
    userUUID: null,
    categoryName: "",
    categoryIcon: "",
    firstArticleStatus: false,
    firstArticleID: 0,
    status: false,
    commentCount: 0,

    itemHeadlineID: 0,
    itemHeadlineName:"",
    itemHeadlineUserID: 0,

    multiLine: null,
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    removeReason: "",
    headlineType: 0,

    entries: [],
    isLoading: false,
    headlineSearching: "",
    search: null,

    transferItem: "",
    editItem: "",

    category: 0,
    catlist: [],
    headline: "",
    subheadline: "",
    taglist: "",
    imdblink: "",
    goodreadslink: "",
    taglisttxt: "",
    updatereason: "",
  }),

  computed: {},

  watch: {
    search(val) {
      if (val != null) {
        if (val.length < 3) return;

        // Items have already been requested
        if (this.isLoading) return;

        this.isLoading = true;

        this.entries = [];
        var params = {
          SearchKey: val,
          ActiveUserID: this.accountid,
          SearchType: 1,
        };
        this.$http
          .post(auth.API_URL + "api/Search/MainSearch", params, {
            headers: {
              Authorization: "Bearer " + auth.getAuthHeader(),
            },
          })
          .then(
            (response) => {
              this.entries = response.body;
              this.isLoading = false;
            },
            (response) => {
              if (response.status === 0) {
                this.text1 = "Service Unavailable";
                this.snackbar = true;
              } else {
                this.text1 = "Service Unavailable";
                this.snackbar = true;
              }
            }
          );
      }
    },
  },

  created() {},

  methods: {
    goHome,
    goFilter,
    FilterSave,
    loadHeadlineList,
    goConfirm,
    confirmSave,
    goRemove,
    removeSave,
    switchList,
    goRemoveCancel,
    removeCancelSave,
    goTransfer,
    TransferSave,
    goEdit,
    categoryList,
    EditSave,
  },

  mounted() {
    this.loadHeadlineList();
    this.categoryList();
  },
};

function goHome() {
  this.$router.push({ name: "adminmain" });
}

function loadHeadlineList() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }

          this.headlinelist = [];
          this.removeheadlinelist = [];
          var params = {
            ConfirmStatus: this.confirmGroup,
            ListType: this.headlineType,
            FirstDate: this.date,
            LastDate: this.date2,
          };

          this.progress = false;
          this.$http
            .post(auth.API_URL + "api/HeadlineArticle/HeadlineList", params, {
              headers: {
                Authorization: "Bearer " + auth.getAuthHeader(),
              },
            })
            .then(
              (response) => {
                this.progress = false;
                this.list = response.body;

                this.list.forEach((element) => {
                  var elementItem = {
                    headlineName: element.HeadlineName,
                    headlineShowName: element.HeadlineShowName,
                    userId: element.UserID,
                    userName: element.UserName,
                    headlineDate: element.HeadlineDateTxt,
                    headlineId: element.HeadlinesID,
                    headlineUrl: element.UrlText,
                    userUrl: element.UserUrlText,
                    userUUID: element.UserUUID,
                    categoryName: element.CategoryName,
                    categoryIcon: element.CategoryIcon,
                    firstArticleStatus: element.FirstArticleStatus,
                    firstArticleID: element.FirstArticleID,
                    status: element.Confirmed,
                    commentCount: element.ArticleCount,
                    removeDate: element.DeletedDateTxt,
                    removeReason: element.DeleteReason,
                    subTitle: element.SubTitle,
                    categoryid: element.CategoryID,
                    imdbLink: element.ImdbLink,
                    goodreadsLink: element.GoodreadsLink,
                    tagList: element.TagList,
                    updateReason: element.UpdateReason,
                  };
                  if (this.headlineType == 0) {
                    this.headlinelist.push(elementItem);
                  }
                  if (this.headlineType == 1) {
                    this.removeheadlinelist.push(elementItem);
                  }
                });
              },
              (response) => {
                if (response.status === 0) {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                } else {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                }
              }
            );
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function categoryList() {
  this.catlist = [];
  var params = {
    PassiveShow: true,
    HeadlineDontShow: true,
  };

  this.$http
    .post(auth.API_URL + "api/CategoryAD/CategoryListAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.list = response.body;

        this.list.forEach((element) => {
          var elementItem = {
            id: element.CategoryID,
            name: element.CategoryName,
            icon: element.CategoryIcon,
            importance: element.CategoryImportance,
            active: element.Active,
          };

          this.catlist.push(elementItem);
        });
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goConfirm(headlineid, headlineName, headlineUserId) {
  this.dialogConfirm = true;
  this.itemHeadlineID = headlineid;
  this.itemHeadlineName = headlineName;
  this.itemHeadlineUserID = headlineUserId;
}

function confirmSave() {
  var params = {
    UserID: this.activeuserid,
    HeadlinesID: this.itemHeadlineID,
    HeadlineName: this.itemHeadlineName,
    HeadlineUserID: this.itemHeadlineUserID,
  };

  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineConfirmSave", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;

          this.dialogConfirm = false;
          this.itemHeadlineID = false;
          this.loadHeadlineList();
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
          this.dialogConfirm = false;
          this.itemHeadlineID = false;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goFilter() {
  this.dialogFilter = true;
}

function FilterSave() {
  this.dialogFilter = false;
  this.loadHeadlineList();
}

function goRemove(headlineid,headlineName,headlineUserID) {
  this.dialogRemove = true;
  this.itemHeadlineID = headlineid;
  this.itemHeadlineName = headlineName;
  this.itemHeadlineUserID = headlineUserID;
}

function removeSave() {
  if (this.removeReason.trim() === "") {
    this.errorMsg = "Bir yayından kaldırılma nedeni girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      UserID: this.activeuserid,
      HeadlinesID: this.itemHeadlineID,
      Reason: this.removeReason,
      HeadlineName: this.itemHeadlineName,
      HeedlineUserID: this.itemHeadlineUserID,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/HeadlineRemove", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogRemoveConfirm = false;
            this.dialogRemove = false;
            this.removeReason = "";
            this.loadHeadlineList();
          } else {
            this.dialogRemoveConfirm = false;
            this.dialogRemove = false;
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function switchList(type) {
  this.headlineType = type;
  this.loadHeadlineList();
}

function goRemoveCancel(headlineid,headlineName,headlineUserID) {
  this.dialogRemoveCancel = true;
  this.itemHeadlineID = headlineid;
  this.itemHeadlineName = headlineName;
  this.itemHeadlineUserID = headlineUserID;
}

function removeCancelSave() {
  var params = {
    UserID: this.activeuserid,
    HeadlinesID: this.itemHeadlineID,
    Cancel: true,
    HeadlineName: this.itemHeadlineName,
    HeedlineUserID: this.itemHeadlineUserID,    
  };

  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineRemove", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.dialogRemoveCancel = false;
          this.loadHeadlineList();
        } else {
          this.dialogRemoveCancel = false;
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goTransfer(item) {
  this.dialogTransfer = true;
  this.transferItem = item;
}

function TransferSave() {
  var params = {
    UserID: this.activeuserid,
    TransferredHeadlineID: this.transferItem.headlineId,
    HeadlinesID: this.headlineSearching.HeadlinesID,
    Cancel: true,
  };

  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineTransferSave", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.dialogTransfer = false;
          this.dialogTransferConfirm = false;
          this.loadHeadlineList();
        } else {
          this.dialogTransfer = false;
          this.dialogTransferConfirm = false;
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goEdit(item) {
  this.dialogEdit = true;

  this.editItem = item;

  this.category = item.categoryid;
  this.headline = item.headlineName;
  this.subheadline = item.subTitle;
  this.imdblink = item.imdbLink;
  this.goodreadslink = item.goodreadsLink;
  this.taglist = item.tagList;
  this.updatereason = item.updateReason;

  this.taglisttxt = "";
  if (this.taglist.trim() !== "") {
    var result = this.taglist.split("#");
    var ecount = 0;
    result.forEach((element) => {
      if (element.trim() !== "") {
        if (ecount == 0) {
          this.taglisttxt = element.trim();
        } else {
          this.taglisttxt = this.taglisttxt + "," + element.trim();
        }
        ecount = ecount + 1;
      }
    });
  }

  this.taglist = this.taglisttxt;
}

function EditSave() {
  this.taglisttxt = "";
  if (this.headline.trim() === "") {
    this.errorMsg = "Bir başlık girmeniz gerekmektedir.";
    this.errorAlert = true;
    return;
  }

  if (this.category === 0) {
    this.errorMsg = "Bir kategori girmeniz gerekmektedir.";
    this.errorAlert = true;
    return;
  }

  if (this.taglist.trim() !== "") {
    var ecount = 0;
    var result = this.taglist.split(",");
    result.forEach((element) => {
      if (element.trim() !== "") {
        this.taglisttxt = this.taglisttxt + "#" + element.trim() + " ";
        ecount = ecount + 1;
      }
    });
    if (ecount > 5) {
      this.errorMsg = "En fazla 5 etiket girebilirsiniz.";
      this.errorAlert = true;
      return;
    }
  }

  var params = {
    UserID: this.activeuserid,
    HeadlineID: this.editItem.headlineId,
    HeadlineName: this.headline,
    CategoryID: this.category,
    SubTitle: this.subheadline,
    IMDBLink: this.imdblink,
    GoodreadsLink: this.goodreadslink,
    TagList: this.taglisttxt,
    UpdateReason: this.updatereason,
  };

  this.$http
    .post(auth.API_URL + "api/HeadlineArticle/HeadlineUpdate", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.dialogEditConfirm = false;
          this.dialogEdit = false;
          this.loadHeadlineList();
        } else {
          this.dialogEditConfirm = false;
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}
</script>