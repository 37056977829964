<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
          >{{ successMsg }}</v-snackbar
        >
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
          >{{ errorMsg }}</v-snackbar
        >
      </div>
      <v-container class="text-uppercase">
        <v-card flat>
          <v-toolbar dense>
            <v-toolbar-title>{{ this.AccountName }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-chip
              v-if="
                fallowshow &&
                loginSuccess &&
                blockorder == 0 &&
                blockactiveorder == 0
              "
              class="ma-2 hidden-sm-and-down"
              color="green"
              outlined
              pill
              small
              @click="messageClick"
            >
              MESAJ GÖNDER
              <v-icon right>mdi-email-send</v-icon>
            </v-chip>

            <v-chip
              v-if="
                falloworder == 0 &&
                blockorder == 0 &&
                fallowshow &&
                loginSuccess
              "
              class="ma-2 hidden-sm-and-down"
              color="indigo"
              outlined
              pill
              small
              @click="fallowclick"
            >
              TAKİP ET
              <v-icon right>mdi-account</v-icon>
            </v-chip>

            <v-chip
              v-if="
                falloworder == 1 &&
                blockorder == 0 &&
                fallowshow &&
                loginSuccess
              "
              class="ma-2 hidden-sm-and-down"
              color="indigo"
              outlined
              pill
              small
              @click="dialogFallowed = true"
            >
              <v-icon center>mdi-account-check</v-icon>
            </v-chip>
            <!-- <v-icon color="indigo" >mdi-account-remove</v-icon> -->

            <v-chip
              v-if="
                falloworder == 0 &&
                blockorder == 0 &&
                fallowshow &&
                loginSuccess
              "
              class="ma-2 hidden-sm-and-down"
              color="red"
              outlined
              pill
              small
              @click="dialogBanned = true"
            >
              <v-icon center>mdi-account-cancel</v-icon>
            </v-chip>

            <v-chip
              v-if="fallowshow && loginSuccess && blockorder == 1"
              class="ma-2 hidden-sm-and-down"
              color="red"
              outlined
              pill
              small
              @click="dialogBanned = true"
            >
              ENGELİ KALDIR
              <v-icon right>mdi-sort-variant-lock-open</v-icon>
            </v-chip>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container v-if="fallowshow" class="text-uppercase hidden-lg-and-up">
        <v-card flat>
          <v-toolbar dense>
            <v-chip
              v-if="
                fallowshow &&
                loginSuccess &&
                blockorder == 0 &&
                blockactiveorder == 0
              "
              class="ma-2"
              color="green"
              outlined
              pill
              small
              @click="messageClick"
            >
              <v-icon right>mdi-email-send</v-icon>
            </v-chip>

            <v-chip
              v-if="
                falloworder == 0 &&
                blockorder == 0 &&
                fallowshow &&
                loginSuccess
              "
              class="ma-2"
              color="indigo"
              outlined
              pill
              small
              @click="fallowclick"
            >
              TAKİP ET
              <v-icon right>mdi-account</v-icon>
            </v-chip>

            <v-chip
              v-if="
                falloworder == 1 &&
                blockorder == 0 &&
                fallowshow &&
                loginSuccess
              "
              class="ma-2"
              color="indigo"
              outlined
              pill
              small
              @click="dialogFallowed = true"
            >
              <v-icon center>mdi-account-check</v-icon>
            </v-chip>
            <!-- <v-icon color="indigo" >mdi-account-remove</v-icon> -->

            <v-chip
              v-if="
                falloworder == 0 &&
                blockorder == 0 &&
                fallowshow &&
                loginSuccess
              "
              class="ma-2"
              color="red"
              outlined
              pill
              small
              @click="dialogBanned = true"
            >
              <v-icon center>mdi-account-cancel</v-icon>
            </v-chip>

            <v-chip
              v-if="fallowshow && loginSuccess && blockorder == 1"
              class="ma-2"
              color="red"
              outlined
              pill
              small
              @click="dialogBanned = true"
            >
              ENGELİ KALDIR
              <v-icon right>mdi-sort-variant-lock-open</v-icon>
            </v-chip>
          </v-toolbar>
        </v-card>
      </v-container>

      <!-- Toolbar -->
      <v-container>
        <v-card class="mx-auto" color="white" dark max-width="1800">
          <v-chip-group mandatory>
            <v-chip
              class="ma-2"
              :color="order === 6 ? 'grey darken-4' : 'grey lighten-1'"
              @click="orderclick(6)"
              small
            >
              <v-icon left small>mdi-information-outline</v-icon>HAKKINDA
            </v-chip>
            <v-chip
              class="ma-2"
              :color="order === 0 ? 'grey darken-4' : 'grey lighten-1'"
              @click="orderclick(0)"
              small
            >
              <v-icon left small>mdi-comment-text</v-icon>YORUMLARI
            </v-chip>
            <v-chip
              class="ma-2"
              :color="order === 1 ? 'grey darken-4' : 'grey lighten-1'"
              @click="orderclick(1)"
              small
            >
              <v-icon left small>mdi-thumbs-up-down</v-icon>ETKİLEŞİMLERİ
            </v-chip>
            <v-chip
              class="ma-2"
              :color="order === 4 ? 'grey darken-4' : 'grey lighten-1'"
              @click="orderclick(4)"
              small
            >
              <v-icon left small>mdi-reorder-horizontal</v-icon>BAŞLIKLARI
            </v-chip>
            <v-chip
              class="ma-2"
              :color="order === 5 ? 'grey darken-4' : 'grey lighten-1'"
              @click="orderclick(5)"
              small
            >
              <v-icon left small>mdi-account-box-multiple</v-icon>TAKİP /
              TAKİPÇİ
            </v-chip>
          </v-chip-group>
        </v-card>
      </v-container>

      <!-- En beğenilen yazısı blogu -->
      <div v-if="order == 6">

        <v-container>
          <v-chip-group mandatory>
            <v-chip
              outlined
              small
              color="blue-grey lighten-1"
            >
             <v-icon left small>mdi-reorder-horizontal</v-icon> {{ headlineCount }} BAŞLIK
            </v-chip>
            <v-chip
              outlined
              small
              color="blue-grey lighten-1"
            >
             <v-icon left small>mdi-comment-text</v-icon> {{ allarticleCount }} YORUM
            </v-chip>     
            <v-chip
              outlined
              small
              color="blue-grey lighten-1"
            >
             <v-icon left small>mdi-account-arrow-right</v-icon>{{ fallowedCount }} TAKİP
            </v-chip>            
            <v-chip
              outlined
              small
              color="blue-grey lighten-1"
            >
             <v-icon left small>mdi-account-arrow-left</v-icon>{{fallowerCount }} TAKİPÇİ
            </v-chip>                          
          </v-chip-group>
        </v-container>
        <v-container v-for="item in favarticle" v-bind:key="item.index">
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              class="mx-auto"
              color="white"
              dark
              max-width="1800"
            >
              <v-card-text class="text--primary"> </v-card-text>

              <v-card-text class="text--primary">
                <v-chip class="ma-2" color="red" small>
                  <v-icon left small>mdi-heart</v-icon>En Beğenilen Yazısı
                </v-chip>
              </v-card-text>

              <v-card-text class="text--primary">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'headline',
                    params: { name: item.HeadlineUrl, id: item.HeadlineID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                    style="cursor: pointer"
                  >
                    <v-icon left>mdi-view-headline</v-icon>
                    {{ item.HeadlineName }}
                  </v-chip>
                </router-link>
              </v-card-text>

              <v-card-text class="text--primary" v-html="item.ArticleText">
                <h4>{{ item.ArticleText }}</h4>
              </v-card-text>

              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'user',
                    params: {
                      userUrl: item.UserUrl,
                      userGui: item.UserUUID,
                    },
                  }"
                >
                  <v-chip
                    class="ma-2 text-uppercase"
                    color="indigo"
                    text-color="white"
                    small
                    style="cursor: pointer"
                  >
                    <v-avatar left>
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ item.UserName }}
                  </v-chip>
                </router-link>
              </v-col>
              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticlesID, 0, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-heart</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.Favorite }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticlesID, 1, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.ArticleLike }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticlesID, 2, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.ArticleDisLike }}</span>
                </v-tooltip>

                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'article',
                    params: { articleid: item.ArticlesID },
                  }"
                >
                  <v-chip class="ma-2" color="indigo" outlined pill x-small>
                    {{ item.ArticleDateText }}
                  </v-chip>
                </router-link>
              </v-col>

              <v-speed-dial top right absolute direction="left">
                <template #activator>
                  <v-btn icon x-small :class="'pink--text'">
                    <v-icon>mdi-alert-decagram</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  icon
                  x-small
                  @click="
                    complaintclick(item.UserID, item.ArticlesID, item.UserName)
                  "
                  class="purple--text"
                >
                  <v-icon>mdi-account-voice</v-icon>
                </v-btn>

                <v-btn
                  v-clipboard="clipboardTxt + item.ArticlesID"
                  class="black--text"
                  icon
                  x-small
                >
                  <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
                </v-btn>

                <!-- <v-btn class="blue--text" icon x-small>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>

                <v-btn class="indigo--text" icon x-small>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn> -->

                <v-btn
                  class="green--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articleditshow(item.ArticleText, item.ArticlesID)"
                >
                  <v-icon>mdi-tooltip-edit</v-icon>
                </v-btn>

                <v-btn
                  class="red--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articledeleteshow(item.ArticlesID)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-card>
          </v-hover>
        </v-container>
      </div>

      <!-- Tüm yorumları sayfaları blogu -->
      <v-container v-if="order == 0">
        <v-card class="mx-auto" color="white" dark max-width="1800">
          <v-row no-gutters>
            <v-col lg="6" md="6" sm="6" xs="6">
              <v-chip class="ma-2" dark small>
                <v-icon left small>mdi-comment-text</v-icon>
                TÜM YORUMLARI ({{ this.allarticleCount }})
              </v-chip>
            </v-col>
            <v-col lg="6" md="6" sm="6" xs="6" align="end">
              <v-chip
                class="ma-2 hidden-sm-and-down"
                color="red"
                outlined
                @click="ordersubclick(ordersub + 1)"
                small
              >
                <v-icon v-if="ordersub == 0" left small
                  >mdi-chevron-double-down</v-icon
                >
                <v-icon v-if="ordersub == 1" left small
                  >mdi-chevron-double-up</v-icon
                >
                <v-icon v-if="ordersub == 2" left small
                  >mdi-heart-multiple</v-icon
                >
                {{ ordersubtxt }}
              </v-chip>

              <v-chip
                class="ma-2 hidden-lg-and-up"
                color="red"
                outlined
                @click="ordersubclick(ordersub + 1)"
                small
              >
                <v-icon v-if="ordersub == 0" small
                  >mdi-chevron-double-down</v-icon
                >
                <v-icon v-if="ordersub == 1" small
                  >mdi-chevron-double-up</v-icon
                >
                <v-icon v-if="ordersub == 2" small>mdi-heart-multiple</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <div v-if="order == 0">
        <v-container v-for="item in allarticleList" v-bind:key="item.index">
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              class="mx-auto"
              color="white"
              dark
              max-width="1800"
            >
              <v-card-text class="text--primary"> </v-card-text>

              <v-card-text class="text--primary">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'headline',
                    params: { name: item.HeadlineUrl, id: item.HeadlineID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                    style="cursor: pointer"
                  >
                    <v-icon left>mdi-view-headline</v-icon>
                    {{ item.HeadlineName }}
                  </v-chip>
                </router-link>
              </v-card-text>

              <v-card-text class="text--primary" v-html="item.ArticleText">
                <h4>{{ item.ArticleText }}</h4>
              </v-card-text>

              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'user',
                    params: {
                      userUrl: item.UserUrl,
                      userGui: item.UserUUID,
                    },
                  }"
                >
                  <v-chip
                    class="ma-2 text-uppercase"
                    color="indigo"
                    text-color="white"
                    small
                    style="cursor: pointer"
                  >
                    <v-avatar left>
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ item.UserName }}
                  </v-chip>
                </router-link>
              </v-col>
              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 0, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-heart</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.FavCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 1, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.LikeCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 2, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.DisLikeCount }}</span>
                </v-tooltip>

                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'article',
                    params: { articleid: item.ArticleID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="indigo"
                    outlined
                    pill
                    x-small
                    style="cursor: pointer"
                  >
                    {{ item.ArticleDate }}
                  </v-chip>
                </router-link>
              </v-col>

              <v-speed-dial top right absolute direction="left">
                <template #activator>
                  <v-btn icon x-small :class="'pink--text'">
                    <v-icon>mdi-alert-decagram</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  icon
                  x-small
                  @click="
                    complaintclick(item.UserID, item.ArticleID, item.UserName)
                  "
                  class="purple--text"
                >
                  <v-icon>mdi-account-voice</v-icon>
                </v-btn>

                <v-btn
                  v-clipboard="clipboardTxt + item.ArticleID"
                  class="black--text"
                  icon
                  x-small
                >
                  <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
                </v-btn>

                <!-- <v-btn class="blue--text" icon x-small>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>

                <v-btn class="indigo--text" icon x-small>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn> -->

                <v-btn
                  class="green--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articleditshow(item.ArticleText, item.ArticleID)"
                >
                  <v-icon>mdi-tooltip-edit</v-icon>
                </v-btn>

                <v-btn
                  class="red--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articledeleteshow(item.ArticleID)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-card>
          </v-hover>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenallarticle > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pageallarticle"
              :length="pagelenallarticle"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <!-- Tüm etkileşimler blogu -->
      <v-container v-if="order == 1">
        <v-card class="mx-auto" color="white" dark max-width="1800">
          <v-row no-gutters>
            <v-col lg="6" md="6" sm="6" xs="6">
              <v-chip-group mandatory>
                <v-chip
                  class="ma-2"
                  :color="
                    orderinteraction === 0 ? 'grey darken-4' : 'grey lighten-1'
                  "
                  @click="orderinteractionclick(0)"
                  small
                >
                  <v-icon left small>mdi-heart</v-icon>
                  FAVORİLERİ ({{ this.favarticleCount }})
                </v-chip>
                <v-chip
                  class="ma-2"
                  :color="
                    orderinteraction === 1 ? 'grey darken-4' : 'grey lighten-1'
                  "
                  @click="orderinteractionclick(1)"
                  small
                >
                  <v-icon left small>mdi-thumb-up</v-icon>
                  BEĞENDİKLERİ ({{ this.likearticleCount }})
                </v-chip>
                <v-chip
                  class="ma-2"
                  :color="
                    orderinteraction === 2 ? 'grey darken-4' : 'grey lighten-1'
                  "
                  @click="orderinteractionclick(2)"
                  small
                >
                  <v-icon left small>mdi-thumb-down</v-icon>
                  BEĞENMEDİKLERİ ({{ this.dislikearticleCount }})
                </v-chip>
                <v-chip
                  class="ma-2 hidden-lg-and-up"
                  color="red"
                  outlined
                  @click="ordersubclick(ordersub + 1)"
                  small
                >
                  <v-icon v-if="ordersub == 0" small
                    >mdi-chevron-double-down</v-icon
                  >
                  <v-icon v-if="ordersub == 1" small
                    >mdi-chevron-double-up</v-icon
                  >
                  <v-icon v-if="ordersub == 2" small>mdi-heart-multiple</v-icon>
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col lg="6" md="6" sm="6" xs="6" align="end">
              <v-chip
                class="ma-2 hidden-sm-and-down"
                color="red"
                outlined
                @click="ordersubclick(ordersub + 1)"
                small
              >
                <v-icon v-if="ordersub == 0" left small
                  >mdi-chevron-double-down</v-icon
                >
                <v-icon v-if="ordersub == 1" left small
                  >mdi-chevron-double-up</v-icon
                >
                <v-icon v-if="ordersub == 2" left small
                  >mdi-heart-multiple</v-icon
                >
                {{ ordersubtxt }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <!-- Tüm favorileri sayfaları blogu -->
      <div v-if="order == 1 && orderinteraction == 0">
        <v-container v-for="item in favarticleList" v-bind:key="item.index">
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              class="mx-auto"
              color="white"
              dark
              max-width="1800"
            >
              <v-card-text class="text--primary"> </v-card-text>
              <v-card-text class="text--primary">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'headline',
                    params: { name: item.HeadlineUrl, id: item.HeadlineID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                    style="cursor: pointer"
                  >
                    <v-icon left>mdi-view-headline</v-icon>
                    {{ item.HeadlineName }}
                  </v-chip>
                </router-link>
              </v-card-text>

              <v-card-text class="text--primary" v-html="item.ArticleText">
                <h4>{{ item.ArticleText }}</h4>
              </v-card-text>

              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'user',
                    params: {
                      userUrl: item.UserUrl,
                      userGui: item.UserUUID,
                    },
                  }"
                >
                  <v-chip
                    class="ma-2 text-uppercase"
                    color="indigo"
                    text-color="white"
                    small
                    style="cursor: pointer"
                  >
                    <v-avatar left>
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ item.UserName }}
                  </v-chip>
                </router-link>
              </v-col>
              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 0, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-heart</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.FavCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 1, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.LikeCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 2, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.DisLikeCount }}</span>
                </v-tooltip>

                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'article',
                    params: { articleid: item.ArticleID },
                  }"
                >
                  <v-chip class="ma-2" color="indigo" outlined pill x-small>
                    {{ item.ArticleDate }}
                  </v-chip>
                </router-link>
              </v-col>

              <v-speed-dial top right absolute direction="left">
                <template #activator>
                  <v-btn icon x-small :class="'pink--text'">
                    <v-icon>mdi-alert-decagram</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  icon
                  x-small
                  @click="
                    complaintclick(item.UserID, item.ArticleID, item.UserName)
                  "
                  class="purple--text"
                >
                  <v-icon>mdi-account-voice</v-icon>
                </v-btn>

                <v-btn
                  v-clipboard="clipboardTxt + item.ArticleID"
                  class="black--text"
                  icon
                  x-small
                >
                  <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
                </v-btn>

                <!-- <v-btn class="blue--text" icon x-small>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>

                <v-btn class="indigo--text" icon x-small>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn> -->

                <v-btn
                  class="green--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articleditshow(item.ArticleText, item.ArticleID)"
                >
                  <v-icon>mdi-tooltip-edit</v-icon>
                </v-btn>

                <v-btn
                  class="red--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articledeleteshow(item.ArticleID)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-card>
          </v-hover>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenfavarticle > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pagefavarticle"
              :length="pagelenfavarticle"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <!-- Tüm beğenilenler sayfaları blogu -->
      <div v-if="order == 1 && orderinteraction == 1">
        <v-container v-for="item in likearticleList" v-bind:key="item.index">
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              class="mx-auto"
              color="white"
              dark
              max-width="1800"
            >
              <v-card-text class="text--primary"> </v-card-text>

              <v-card-text class="text--primary">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'headline',
                    params: { name: item.HeadlineUrl, id: item.HeadlineID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                    style="cursor: pointer"
                  >
                    <v-icon left>mdi-view-headline</v-icon>
                    {{ item.HeadlineName }}
                  </v-chip>
                </router-link>
              </v-card-text>

              <v-card-text class="text--primary" v-html="item.ArticleText">
                <h4>{{ item.ArticleText }}</h4>
              </v-card-text>

              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'user',
                    params: {
                      userUrl: item.UserUrl,
                      userGui: item.UserUUID,
                    },
                  }"
                >
                  <v-chip
                    class="ma-2 text-uppercase"
                    color="indigo"
                    text-color="white"
                    small
                    style="cursor: pointer"
                  >
                    <v-avatar left>
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ item.UserName }}
                  </v-chip>
                </router-link>
              </v-col>
              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 0, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-heart</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.FavCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 1, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.LikeCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 2, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.DisLikeCount }}</span>
                </v-tooltip>

                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'article',
                    params: { articleid: item.ArticleID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="indigo"
                    outlined
                    pill
                    x-small
                    style="cursor: pointer"
                  >
                    {{ item.ArticleDate }}
                  </v-chip>
                </router-link>
              </v-col>

              <v-speed-dial top right absolute direction="left">
                <template #activator>
                  <v-btn icon x-small :class="'pink--text'">
                    <v-icon>mdi-alert-decagram</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  icon
                  x-small
                  @click="
                    complaintclick(item.UserID, item.ArticleID, item.UserName)
                  "
                  class="purple--text"
                >
                  <v-icon>mdi-account-voice</v-icon>
                </v-btn>

                <v-btn
                  v-clipboard="clipboardTxt + item.ArticleID"
                  class="black--text"
                  icon
                  x-small
                >
                  <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
                </v-btn>

                <!-- <v-btn class="blue--text" icon x-small>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>

                <v-btn class="indigo--text" icon x-small>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn> -->

                <v-btn
                  class="green--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articleditshow(item.ArticleText, item.ArticleID)"
                >
                  <v-icon>mdi-tooltip-edit</v-icon>
                </v-btn>

                <v-btn
                  class="red--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articledeleteshow(item.ArticleID)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-card>
          </v-hover>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenlikearticle > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pagelikearticle"
              :length="pagelenlikearticle"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <!-- Tüm beğenilmeyen sayfaları blogu -->
      <div v-if="order == 1 && orderinteraction == 2">
        <v-container v-for="item in dislikearticleList" v-bind:key="item.index">
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              class="mx-auto"
              color="white"
              dark
              max-width="1800"
            >
              <v-card-text class="text--primary"> </v-card-text>

              <v-card-text class="text--primary">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'headline',
                    params: { name: item.HeadlineUrl, id: item.HeadlineID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                    style="cursor: pointer"
                  >
                    <v-icon left>mdi-view-headline</v-icon>
                    {{ item.HeadlineName }}
                  </v-chip>
                </router-link>
              </v-card-text>

              <v-card-text class="text--primary" v-html="item.ArticleText">
                <h4>{{ item.ArticleText }}</h4>
              </v-card-text>

              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'user',
                    params: {
                      userUrl: item.UserUrl,
                      userGui: item.UserUUID,
                    },
                  }"
                >
                  <v-chip
                    class="ma-2 text-uppercase"
                    color="indigo"
                    text-color="white"
                    small
                    style="cursor: pointer"
                  >
                    <v-avatar left>
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ item.UserName }}
                  </v-chip>
                </router-link>
              </v-col>
              <v-col lg="12" md="12" sm="12" xs="12" align="end">
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleFav ? 'red--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 0, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-heart</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.FavCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleUp ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 1, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.LikeCount }}</span>
                </v-tooltip>
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="item.ArticleDown ? 'pink--text' : 'grey--text'"
                      icon
                      x-small
                      @click="favupdownclick(item.ArticleID, 2, item.UserName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.DisLikeCount }}</span>
                </v-tooltip>

                <router-link
                  style="text-decoration: none; color: inherit"
                  :to="{
                    name: 'article',
                    params: { articleid: item.ArticleID },
                  }"
                >
                  <v-chip
                    class="ma-2"
                    color="indigo"
                    outlined
                    pill
                    x-small
                    style="cursor: pointer"
                  >
                    {{ item.ArticleDate }}
                  </v-chip>
                </router-link>
              </v-col>

              <v-speed-dial top right absolute direction="left">
                <template #activator>
                  <v-btn icon x-small :class="'pink--text'">
                    <v-icon>mdi-alert-decagram</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  icon
                  x-small
                  @click="
                    complaintclick(item.UserID, item.ArticleID, item.UserName)
                  "
                  class="purple--text"
                >
                  <v-icon>mdi-account-voice</v-icon>
                </v-btn>

                <v-btn
                  v-clipboard="clipboardTxt + item.ArticleID"
                  class="black--text"
                  icon
                  x-small
                >
                  <v-icon @click="linkInfo()">mdi-link-variant-plus</v-icon>
                </v-btn>

                <!-- <v-btn class="blue--text" icon x-small>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>

                <v-btn class="indigo--text" icon x-small>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn> -->

                <v-btn
                  class="green--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articleditshow(item.ArticleText, item.ArticleID)"
                >
                  <v-icon>mdi-tooltip-edit</v-icon>
                </v-btn>

                <v-btn
                  class="red--text"
                  icon
                  x-small
                  v-if="item.EditOk"
                  @click="articledeleteshow(item.ArticleID)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-card>
          </v-hover>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelendislikearticle > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pagedislikearticle"
              :length="pagelendislikearticle"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <!-- Tüm başlıklar blogu -->
      <v-container v-if="order == 4">
        <v-card class="mx-auto" color="white" dark max-width="1800">
          <v-row no-gutters>
            <v-col lg="12" md="12" sm="12" xs="12">
              <v-chip class="ma-2" dark small>
                <v-icon left small>mdi-reorder-horizontal</v-icon>
                TÜM BAŞLIKLARI ({{ this.headlineCount }})
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <div v-if="order == 4">
        <v-container>
          <v-card class="mx-auto" max-width="1800" tile>
            <v-list shaped>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in headlineList"
                  :key="i"
                  :to="item.UrlText"
                >
                  <v-row dense>
                    <v-col cols="9">
                      <v-list-item-content>
                        <h4>
                          <v-list-item-title>
                            <v-icon small color="blue">{{
                              item.HeadlineIcon
                            }}</v-icon>
                            {{ item.HeadlineName }}</v-list-item-title
                          >
                        </h4>
                      </v-list-item-content>
                    </v-col>
                    <v-col cols="3" align="end">
                      <v-list-item-content>
                        <h5>({{ item.HeadlineCount }})</h5>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenheadline > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pageheadline"
              :length="pagelenheadline"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <!-- Tüm takip ettiklerim blogu -->
      <v-container v-if="order == 5">
        <v-card class="mx-auto" color="white" dark max-width="1800">
          <v-row no-gutters>
            <v-col lg="12" md="12" sm="12" xs="12">
              <v-chip-group mandatory>
                <v-chip
                  class="ma-2"
                  :color="
                    orderfallowing === 0 ? 'grey darken-4' : 'grey lighten-1'
                  "
                  @click="orderfallowinglick(0)"
                  small
                >
                  <v-icon left small>mdi-account-arrow-right</v-icon>
                  TAKİP ({{ this.fallowedCount }})
                </v-chip>
                <v-chip
                  class="ma-2"
                  :color="
                    orderfallowing === 1 ? 'grey darken-4' : 'grey lighten-1'
                  "
                  @click="orderfallowinglick(1)"
                  small
                >
                  <v-icon left small>mdi-account-arrow-left</v-icon>
                  TAKİPÇİ ({{ this.fallowerCount }})
                </v-chip>
                <v-chip
                  v-if="!fallowshow"
                  class="ma-2"
                  :color="
                    orderfallowing === 2 ? 'grey darken-4' : 'grey lighten-1'
                  "
                  @click="orderfallowinglick(2)"
                  small
                >
                  <v-icon left small>mdi-account-cancel</v-icon>
                  ENGELLENENLER ({{ this.blockedCount }})
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <div v-if="order == 5 && orderfallowing == 0">
        <v-container>
          <v-card class="mx-auto text-uppercase" max-width="1800" tile>
            <v-list shaped>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in fallowedList"
                  :key="i"
                  :to="item.FallowedUrlText"
                >
                  <v-list-item-content>
                    <h4>
                      <v-list-item-title>
                        <v-icon small color="indigo">mdi-account</v-icon>
                        {{ item.FallowedName }}</v-list-item-title
                      >
                    </h4>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenfallowed > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pagefallowed"
              :length="pagelenfallowed"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <div v-if="order == 5 && orderfallowing == 1">
        <v-container>
          <v-card class="mx-auto text-uppercase" max-width="1800" tile>
            <v-list shaped>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in fallowerList"
                  :key="i"
                  :to="item.FallowedUrlText"
                >
                  <v-list-item-content>
                    <h4>
                      <v-list-item-title>
                        <v-icon small color="indigo">mdi-account</v-icon>
                        {{ item.FallowedName }}</v-list-item-title
                      >
                    </h4>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenfallower > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pagefallower"
              :length="pagelenfallower"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <div v-if="order == 5 && orderfallowing == 2">
        <v-container>
          <v-card class="mx-auto text-uppercase" max-width="1800" tile>
            <v-list shaped>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in blockedList"
                  :key="i"
                  :to="item.BlockedUrlText"
                >
                  <v-list-item-content>
                    <h4>
                      <v-list-item-title>
                        <v-icon small color="indigo">mdi-account</v-icon>
                        {{ item.BlockedName }}</v-list-item-title
                      >
                    </h4>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-container>

        <v-container>
          <div class="text-center">
            <v-pagination
              v-if="pagelenblocked > 1"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              color="indigo"
              v-model="pageblocked"
              :length="pagelenblocked"
              @input="loadUserPage"
            ></v-pagination>
          </div>
        </v-container>
      </div>

      <!-- Yorum edit dialog box -->
      <v-dialog v-model="dialogEdit" width="600px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text"
              >YORUM DÜZENLE</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (linkshow = true),
                        (bknzshow = false),
                        (bknzheadline = ''),
                        (bknzid = '')
                    "
                  >
                    <v-icon left> mdi-link-variant </v-icon>
                    Link
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="
                      (bknzshow = true),
                        (linkshow = false),
                        (linkurl = ''),
                        (linkname = '')
                    "
                  >
                    <v-icon left> mdi-comment-search </v-icon>
                    Bknz.
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="blue-grey darken-2"
                    small
                    outlined
                    @click="addSpoilerTags()"
                  >
                    <v-icon left> mdi-alert-rhombus </v-icon>
                    Spoiler!
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="linkshow">
                  <v-text-field
                    id="linkurl"
                    v-model="linkurl"
                    label="Url Adresi"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    id="linkname"
                    v-model="linkname"
                    label="Url Tanım"
                    name="linkurl"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                  ></v-text-field>

                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="linkclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="linkadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12" v-if="bknzshow">
                  <v-autocomplete
                    v-model="bknzheadline"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="searchMain"
                    hide-no-data
                    hide-selected
                    item-text="ShowText"
                    item-value="HeadlinesID"
                    placeholder="Başlık aramak için yazınız.."
                    return-object
                    outlined
                    dense
                    label="Başlık"
                    color="blue-grey darken-2"
                  >
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                        small
                      >
                        <span v-text="item.ShowText"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-title
                        v-text="item.ShowText"
                      ></v-list-item-title>
                      <v-list-item-action v-if="item.SearchType == 0">
                        <v-icon color="indigo">{{ item.CategoryIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-action v-if="item.SearchType == 1">
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    id="bknzid"
                    v-model="bknzid"
                    label="Yorum ID"
                    name="bknzid"
                    color="blue-grey darken-2"
                    outlined
                    dense
                    autocomplete="off"
                    @input="bknzheadline = ''"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                  <v-chip
                    class="ma-2"
                    color="red darken-4"
                    small
                    outlined
                    @click="bknzclose()"
                  >
                    <v-icon left> mdi-close-circle </v-icon>
                    İptal
                  </v-chip>

                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    small
                    outlined
                    @click="bknzadd()"
                  >
                    <v-icon left> mdi-plus-circle </v-icon>
                    Ekle
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="articleEditItem"
                    outlined
                    name="articleEditItem"
                    label="Yorumunuz"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogEdit = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleEdit()"
              >
                <v-icon left>mdi-pencil</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Yorum silme dialog box -->
      <v-dialog v-model="dialogDelete" width="400px">
        <v-form ref="test">
          <v-card>
            <v-card-title class="indigo white--text">YORUM SİLME</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="deleteReason"
                    outlined
                    name="deleteReason"
                    label="Silme Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="dialogDelete = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="articleDelete()"
              >
                <v-icon left>mdi-delete</v-icon>SİL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!--şikayet dialog box -->
      <v-dialog v-model="diaologCompliant" width="400px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text">ŞİKAYET ET</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    id="compliantTitle"
                    v-model="compliantTitle"
                    label="Şikeyet Başlık"
                    name="compliantTitle"
                    color="blue-grey darken-2"
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                    :rules="textRules"
                  ></v-textarea>
                  <v-textarea
                    v-model="compliantReason"
                    outlined
                    name="compliantReason"
                    label="Şikayet Nedeni"
                    auto-grow
                    color="blue-grey darken-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="diaologCompliant = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="compliantSave()"
              >
                <v-icon left>mdi-account-voice</v-icon>ŞİKAYET ET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!--message dialog box -->
      <v-dialog v-model="diaologMessage" width="400px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text">MESAJ GÖNDER</v-card-title>
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-textarea
                    color="indigo"
                    auto-grow
                    class="mx-2"
                    label="Bir mesaj yazın"
                    rows="1"
                    prepend-icon="mdi-message-plus"
                    v-model="textmessage"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="diaologMessage = false"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="indigo"
                @click="messageSave()"
              >
                <v-icon left>mdi-send</v-icon>MESAJ GÖNDER
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogFallowed" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>Bu yazarı takibi bırakmayı istediğinizden emin misiniz?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogFallowed = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="fallowclick()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogBanned" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3 v-if="!blockorder">
                Bu yazarı engellemek istediğinizden emin misiniz?
              </h3>
              <h3 v-if="blockorder">
                Bu yazarın engelini kaldırmak istediğinizden emin misiniz?
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogBanned = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="blockclick()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import auth from "../api/auth";
import Vue from "vue";
import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

export default {
  name: "App",
  watch: {
    "$route.params.userGui": function () {
      if (typeof this.$route.params.userGui !== "undefined") {
        (this.pageallarticle = 1),
          (this.pagelenallarticle = 0),
          (this.pagefavarticle = 1),
          (this.pagelenfavarticle = 0),
          (this.pagelikearticle = 1),
          (this.pagelenlikearticle = 0),
          (this.pagedislikearticle = 1),
          (this.pagelendislikearticle = 0),
          (this.pageheadline = 1),
          (this.pagelenheadline = 0),
          (this.pagefallowed = 1),
          (this.pagelenfallowed = 0),
          (this.pagefallower = 1),
          (this.pagelenfallower = 0),
          (this.pageblocked = 1),
          (this.pagelenblocked = 0),
          (this.order = 6),
          (this.orderinteraction = 0),
          this.loadUserPage();
      }
    },
    searchMain(val) {
      this.bknzid = "";

      if (val == null) return;
      if (val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.entries = [];
      var params = {
        SearchKey: val,
        ActiveUserID: this.accountid,
        SearchType: 1,
      };
      this.$http
        .post(auth.API_URL + "api/Search/MainSearch", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.entries = response.body;
            this.isLoading = false;
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    },
  },

  data: () => ({
    favarticle: [],
    allarticleList: [],
    favarticleList: [],
    likearticleList: [],
    dislikearticleList: [],
    userpageList: [],
    headlineList: [],
    fallowedList: [],
    fallowerList: [],
    blockedList: [],
    AccountName: "",
    AccountUserID: 0,
    loginSuccess: false,
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    order: 6,
    ordersub: 0,
    allarticleCount: 0,
    favarticleCount: 0,
    likearticleCount: 0,
    dislikearticleCount: 0,
    headlineCount: 0,
    fallowedCount: 0,
    fallowerCount: 0,
    blockedCount: 0,
    pageallarticle: 1,
    pagelenallarticle: 0,
    pagefavarticle: 1,
    pagelenfavarticle: 0,
    pagelikearticle: 1,
    pagelenlikearticle: 0,
    pagedislikearticle: 1,
    pagelendislikearticle: 0,
    pageheadline: 1,
    pagelenheadline: 0,
    pagefallowed: 1,
    pagelenfallowed: 0,
    pagefallower: 1,
    pagelenfallower: 0,
    pageblocked: 1,
    pagelenblocked: 0,
    falloworder: false,
    fallowshow: false,
    pageNo: 1,
    customToolbar: [["link"], [{ list: "ordered" }, { list: "bullet" }]],
    dialogDelete: false,
    deleteReason: "",
    dialogEdit: false,
    articleEditItem: "",
    editArticleID: 0,
    compuserid: 0,
    comparticleid: 0,
    diaologCompliant: false,
    textRules: [(v) => !!v || "Girilmesi zorunlu alan."],
    multiLine: null,
    compliantTitle: null,
    compliantReason: null,
    diaologMessage: false,
    textmessage: "",

    linkurl: "",
    linkname: "",
    linkshow: false,
    bknzshow: false,
    bknzid: "",

    entries: [],
    isLoading: false,
    bknzheadline: [],
    searchMain: null,

    dialogFallowed: false,
    orderinteraction: 0,
    orderfallowing: 0,

    dialogBanned: false,

    blockorder: false,
    blockactiveorder: false,

    ordersubtxt: "İLK YORUM BAŞTA",
    clipboardTxt: auth.URLROOT + "Article/",
  }),
  methods: {
    loadUserPage,
    favupdownclick,
    orderclick,
    ordersubclick,
    orderinteractionclick,
    orderfallowinglick,
    fallowclick,
    blockclick,
    articleditshow,
    articledeleteshow,
    articleEdit,
    articleDelete,
    complaintclick,
    compliantSave,
    messageClick,
    messageSave,

    cancelArticle,
    addSpoilerTags,
    linkclose,
    linkadd,
    bknzclose,
    bknzadd,
    linkInfo,
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    if (localStorage.userSessionID) {
      var sparam = {
        SessionID: localStorage.userSessionID,
      };

      this.$http
        .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            this.loginSuccess = true;
            this.accountname = response.body.Title;
            this.activeuserid = response.body.UserID;

            this.loadUserPage();
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    }
  },
};

function loadUserPage() {
  this.fallowshow = false;
  // if (localStorage.userInfo) {
  //   this.loginSuccess = true;
  //   this.accountname = JSON.parse(localStorage.userInfo).username;
  //   this.activeuserid = JSON.parse(localStorage.userInfo).userid;
  // }

  if (this.order === 0) {
    this.pageNo = this.pageallarticle;
  }
  if (this.order === 1) {
    if (this.orderinteraction === 0) {
      this.pageNo = this.pagefavarticle;
    }
    if (this.orderinteraction === 1) {
      this.pageNo = this.pagelikearticle;
    }
    if (this.orderinteraction === 2) {
      this.pageNo = this.pagedislikearticle;
    }
  }
  // if (this.order === 2) {
  //   this.pageNo = this.pagelikearticle;
  // }
  // if (this.order === 3) {
  //   this.pageNo = this.pagedislikearticle;
  // }
  if (this.order === 4) {
    this.pageNo = this.pageheadline;
  }
  if (this.order === 5) {
    this.pageNo = this.pagefallowed;
  }

  var params = {
    UUID: this.$route.params.userGui,
    ActiveUserID: this.activeuserid,
    ActiveOrder: this.order,
    ActiveOrderInteraction: this.orderinteraction,
    OrderBy: this.ordersub,
    PageNumber: this.pageNo,
    DataCount: 10,
  };

  this.$http
    .post(auth.API_URL + "api/UserAD/UserPageLoad", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        this.AccountName = response.body.UserInfo.Title;
        this.AccountUserID = response.body.UserInfo.UserID;
        this.allarticleCount = response.body.AllArticleTotalCount;
        this.favarticleCount = response.body.AllFavoriteArticleTotalCount;
        this.likearticleCount = response.body.AllLikeArticleTotalCount;
        this.dislikearticleCount = response.body.AllDisLikeArticleTotalCount;
        this.headlineCount = response.body.AllHeadlineTotalCount;
        this.fallowedCount = response.body.AllFallowedCount;
        this.fallowerCount = response.body.AllFallowerCount;
        this.blockedCount = response.body.AllBlockedCount;

        if (this.accountname != this.AccountName) {
          this.fallowshow = true;
        }

        if (response.body.ActiveUserFallowedBy) {
          this.falloworder = true;
        } else {
          this.falloworder = false;
        }

        if (response.body.ActiveUserBlockedBy) {
          this.blockorder = true;
        } else {
          this.blockorder = false;
        }

        if (response.body.BlockedActiveUser) {
          this.blockactiveorder = true;
        } else {
          this.blockactiveorder = false;
        }

        this.favarticle = [];
        if (response.body.FavoriteArticle != null)
        {
        (response.body.FavoriteArticle.EditOk =
          response.body.FavoriteArticle.UserID === this.activeuserid
            ? true
            : false),
          this.favarticle.push(response.body.FavoriteArticle);
        }


        this.allarticleList = [];
        this.entryList = response.body.AllArticles;

        var icount = 0;
        this.entryList.forEach((element) => {
          var allarticleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            HeadlineName: element.HeadlineName,
            HeadlineUrl: element.HeadlineUrl,
            HeadlineID: element.HeadlineID,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
          };
          if (icount === 0) {
            this.pagelenallarticle = element.TotalPage;
          }
          this.allarticleList.push(allarticleItem);
          icount = icount + 1;
        });

        this.favarticleList = [];
        this.entryList = response.body.AllFavoriteArticles;

        icount = 0;
        this.entryList.forEach((element) => {
          var favarticleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            HeadlineName: element.HeadlineName,
            HeadlineUrl: element.HeadlineUrl,
            HeadlineID: element.HeadlineID,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
          };
          if (icount === 0) {
            this.pagelenfavarticle = element.TotalPage;
          }
          this.favarticleList.push(favarticleItem);
          icount = icount + 1;
        });

        this.likearticleList = [];
        this.entryList = response.body.AllLikeArticles;

        icount = 0;
        this.entryList.forEach((element) => {
          var likearticleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            HeadlineName: element.HeadlineName,
            HeadlineUrl: element.HeadlineUrl,
            HeadlineID: element.HeadlineID,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
          };
          if (icount === 0) {
            this.pagelenlikearticle = element.TotalPage;
          }
          this.likearticleList.push(likearticleItem);
          icount = icount + 1;
        });

        this.dislikearticleList = [];
        this.entryList = response.body.AllDisLikeArticles;

        icount = 0;
        this.entryList.forEach((element) => {
          var dislikearticleItem = {
            ArticleID: element.ArticlesID,
            ArticleText: element.ArticleText,
            ArticleDate: element.ArticleDateText,
            UserID: element.UserID,
            UserName: element.UserName,
            UserUUID: element.UserUUID,
            UserUrl: element.UserUrl,
            ArticleFav: element.ActionInfo.Favorite,
            ArticleUp: element.ActionInfo.ArticleLike,
            ArticleDown: element.ActionInfo.ArticleDisLike,
            HeadlineName: element.HeadlineName,
            HeadlineUrl: element.HeadlineUrl,
            HeadlineID: element.HeadlineID,
            FavCount: element.Favorite,
            LikeCount: element.ArticleLike,
            DisLikeCount: element.ArticleDisLike,
            EditOk: element.UserID === this.activeuserid ? true : false,
          };
          if (icount === 0) {
            this.pagelendislikearticle = element.TotalPage;
          }
          this.dislikearticleList.push(dislikearticleItem);
          icount = icount + 1;
        });

        this.headlineList = [];
        this.entryList = response.body.AllHeadlines;

        icount = 0;
        this.entryList.forEach((element) => {
          var headlineItem = {
            HeadlineIcon: element.CategoryIcon,
            HeadlineCount: element.Count,
            HeadlineName:
              element.SubTitle === ""
                ? element.HeadlineName
                : element.HeadlineName + " (" + element.SubTitle + ")",
            UrlText: {
              name: "headline",
              params: { name: element.UrlText, id: element.HeadlineID },
            },
          };
          if (icount === 0) {
            this.pagelenheadline = element.TotalPage;
          }
          this.headlineList.push(headlineItem);
          icount = icount + 1;
        });

        this.fallowedList = [];
        this.entryList = response.body.AllFallowed;

        icount = 0;
        this.entryList.forEach((element) => {
          var fallowedItem = {
            FallowedName: element.UserName,
            FallowedUrlText: {
              name: "user",
              params: { userUrl: element.UrlText, userGui: element.UUID },
            },
          };
          if (icount === 0) {
            this.pagelenfallowed = element.TotalPage;
          }
          this.fallowedList.push(fallowedItem);
          icount = icount + 1;
        });

        this.fallowerList = [];
        this.entryList = response.body.AllFallower;

        icount = 0;
        this.entryList.forEach((element) => {
          var fallowerItem = {
            FallowedName: element.UserName,
            FallowedUrlText: {
              name: "user",
              params: { userUrl: element.UrlText, userGui: element.UUID },
            },
          };
          if (icount === 0) {
            this.pagelenfallower = element.TotalPage;
          }
          this.fallowerList.push(fallowerItem);
          icount = icount + 1;
        });

        this.blockedList = [];
        this.entryList = response.body.AllBlocked;

        icount = 0;
        this.entryList.forEach((element) => {
          var blockedItem = {
            BlockedName: element.UserName,
            BlockedUrlText: {
              name: "user",
              params: { userUrl: element.UrlText, userGui: element.UUID },
            },
          };
          if (icount === 0) {
            this.pagelenblocked = element.TotalPage;
          }
          this.blockedList.push(blockedItem);
          icount = icount + 1;
        });
        this.$vuetify.goTo(0);
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function favupdownclick(value, typeInput, articleUserID) {
  if (this.loginSuccess) {
    var params = {
      Type: typeInput,
      UserID: this.activeuserid,
      ArticleID: value,
    };

    if (articleUserID != this.accountname) {
      this.$http
        .post(auth.API_URL + "api/HeadlineArticle/UserActionSave", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = response.body.ResponseMessage;
              this.successAlert = true;
              // this.dialog = false
              // this.article = ''
              this.loadUserPage();
            } else {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;
            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    } else {
      this.errorMsg =
        "Favori, beğeni ve ya beğenmeme işlemlerini kendi yorumlarınızda yapamazsınız.";
      this.errorAlert = true;
    }
  } else {
    this.errorMsg =
      "Favori, beğeni ve ya beğenmeme işlemlerini yapmak için oturum açmalısınız.";
    this.errorAlert = true;
  }
}

function orderclick(value) {
  this.order = value;
  this.loadUserPage();
}

function orderinteractionclick(value) {
  this.orderinteraction = value;
  this.loadUserPage();
}

function orderfallowinglick(value) {
  this.orderfallowing = value;
  this.loadUserPage();
}

function ordersubclick(value) {
  if (value == 3) {
    value = 0;
  }
  this.ordersub = value;
  if (value == 0) {
    this.ordersubtxt = "İLK YORUM BAŞTA";
  }
  if (value == 1) {
    this.ordersubtxt = "SON YORUM BAŞTA";
  }
  if (value == 2) {
    this.ordersubtxt = "EN BEĞENİLEN BAŞTA";
  }
  this.loadUserPage();
}

function fallowclick() {
  if (!this.falloworder) {
    this.falloworder = true;
  } else {
    this.falloworder = false;
  }

  var params = {
    ActiveUserID: this.activeuserid,
    UserID: this.AccountUserID,
    FallowedBy: this.falloworder,
  };

  this.$http
    .post(auth.API_URL + "api/UserAD/UserFallowed", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          if (this.falloworder) {
            this.successMsg =
              this.AccountName.toUpperCase() +
              " kullanıcısını takip etmeye başladınız.";
            this.AccountName + " kullanıcısını takip etmeye başladınız.";
          } else {
            this.successMsg =
              this.AccountName.toUpperCase() +
              " kullanıcısını takibi bıraktınız.";
            this.AccountName + " kullanıcısını takibi bıraktınız.";
          }

          this.successAlert = true;
          this.mailSuccess = true;
          this.dialogFallowed = false;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function blockclick() {
  if (!this.blockorder) {
    this.blockorder = true;
  } else {
    this.blockorder = false;
  }

  var params = {
    ActiveUserID: this.activeuserid,
    UserID: this.AccountUserID,
    BlockedBy: this.blockorder,
  };

  this.$http
    .post(auth.API_URL + "api/UserAD/UserBlocked", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          if (this.blockorder) {
            this.successMsg =
              this.AccountName.toUpperCase() + " kullanıcısını engellediniz.";
            this.AccountName + " kullanıcısının engeleni kaldırdınız.";
          } else {
            this.successMsg =
              this.AccountName.toUpperCase() +
              " kullanıcısının engeleni kaldırdınız.";
            this.AccountName + " kullanıcısının engeleni kaldırdınız.";
          }

          this.successAlert = true;
          this.mailSuccess = true;
          this.dialogBanned = false;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function articleditshow(text, id) {
  this.dialogEdit = true;
  this.articleEditItem = auth.convertToTextFromHtml(text);
  this.editArticleID = id;
}

function articledeleteshow(id) {
  this.dialogDelete = true;
  this.editArticleID = id;
}

function articleEdit() {
  var articletxt = auth.convertToHtmlFromText(this.articleEditItem);

  if (this.articleEditItem === "") {
    this.errorMsg = "Bir yorum girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      ArticleText: articletxt,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleEdit", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogEdit = false;
            this.articleEditItem = "";
            this.loadUserPage();
            this.cancelArticle();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function articleDelete() {
  if (this.deleteReason === "") {
    this.errorMsg = "Bir silme nedeni girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ArticlesID: this.editArticleID,
      DeleteType: 1,
      DeleteReason: this.deleteReason,
    };

    this.$http
      .post(auth.API_URL + "api/HeadlineArticle/ArticleDelete", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
            this.dialogDelete = false;
            this.deleteReason = "";
            this.loadUserPage();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function complaintclick(userid, articleid, articleUserID) {
  if (this.loginSuccess) {
    if (articleUserID != this.accountname) {
      // şikayet diaolog açılacak
      this.diaologCompliant = true;
      this.compuserid = userid;
      this.comparticleid = articleid;
    } else {
      this.errorMsg = "Kendi yorumlarınızı şikayet edemezsiniz.";
      this.errorAlert = true;
    }
  } else {
    this.errorMsg = "Bir yorumu şikayet edebilmeniz için oturum açmalısınız. ";
    this.errorAlert = true;
  }
}

function compliantSave() {
  if (this.$refs.testc.validate()) {
    if (this.compliantReason === "") {
      this.errorMsg = "Bir şikayet nedeni girmeniz gerekmektedir.";
      this.errorAlert = true;
    } else {
      var compliantReasonHtml = auth.convertToHtmlFromText(
        this.compliantReason
      );
      var params = {
        CompliantType: 0,
        UserID: this.compuserid,
        ArticleID: this.comparticleid,
        ComplainingID: this.activeuserid,
        ComplaintTitle: this.compliantTitle,
        ComplaintText: compliantReasonHtml,
        ComplaintRead: false,
      };

      this.$http
        .post(auth.API_URL + "api/HeadlineArticle/CompliantSave", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = response.body.ResponseMessage;
              this.successAlert = true;
              this.diaologCompliant = false;
              this.compliantTitle = "";
              this.compliantReason = "";
            } else {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;
            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    }
  }
}

function messageClick() {
  this.diaologMessage = true;
}

function messageSave() {
  if (this.textmessage === "") {
    this.errorMsg = "Bir mesaj girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      ConversationSaved: true,
      SendUserID: this.activeuserid,
      ReceivedID: this.AccountUserID,
      MessageText: this.textmessage,
    };

    this.$http
      .post(auth.API_URL + "api/Conversation/MessageSave", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.textmessage = "";
            this.diaologMessage = false;
            this.successMsg = response.body.ResponseMessage;
            this.successAlert = true;
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}

function addSpoilerTags() {
  this.articleEditItem =
    this.articleEditItem == ""
      ? this.articleEditItem + "(spoiler!)" + "\n\n" + "(spoiler!)"
      : this.articleEditItem + "\n\n" + "(spoiler!)" + "\n\n" + "(spoiler!)";
}

function linkclose() {
  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function linkadd() {
  if (this.linkurl == "") {
    this.errorMsg = "Link eklemek için bir url adresi girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  this.articleEditItem =
    this.articleEditItem + "[:" + this.linkurl + "|" + this.linkname + ":]";

  this.linkshow = false;
  this.linkurl = "";
  this.linkname = "";
}

function bknzclose() {
  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function bknzadd() {
  if (this.bknzid == "" && this.bknzheadline == "") {
    this.errorMsg = "Bknz eklemek için bir başlık ya da yorum id girmelisiniz.";
    this.errorAlert = true;
    return;
  }

  if (this.bknzid != "") {
    this.articleEditItem = this.articleEditItem + "{:" + this.bknzid + ":}";
  }

  if (this.bknzheadline != "") {
    this.articleEditItem =
      this.articleEditItem +
      "{;" +
      this.bknzheadline.HeadlineName +
      "|" +
      this.bknzheadline.UrlText +
      "|" +
      this.bknzheadline.HeadlinesID +
      ";}";
  }

  this.bknzshow = false;
  this.bknzid = "";
  this.bknzheadline = "";
}

function cancelArticle() {
  this.articleEditItem = "";
  this.dialog = false;
  this.linkshow = false;
  this.bknzshow = false;
  this.bknzheadline = "";
}

function linkInfo() {
  this.successMsg = "Yorumun linki kopyalandı.";
  this.successAlert = true;
}
</script>
