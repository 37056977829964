<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>      
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>KULLANICI YÖNETİMİ</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="blue-grey darken-4"
              @click="goHome()"
              small
            >
              <v-icon>mdi-home-import-outline </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container class="text-uppercase">
        <v-card flat>
            <v-chip class="ma-2" color="blue-grey darken-4" outlined small>
              Toplam Kullanıcı Sayısı : {{ this.totalUserCount }}
            </v-chip>
            <v-chip class="ma-2" color="blue-grey darken-4" outlined small>
              Günlük Kullanıcı Sayısı : {{ this.todayUserCount }}
            </v-chip>
            <v-chip class="ma-2" color="blue-grey darken-4" outlined small>
              Haftalık Kullanıcı Sayısı : {{ this.weekUserCount }}
            </v-chip>   
            <v-chip class="ma-2" color="blue-grey darken-4" outlined small>
              Aylık Kullanıcı Sayısı : {{ this.monthUserCount }}
            </v-chip>                                   
        </v-card>
      </v-container>

      <v-container>
        <v-data-table
          :headers="users"
          :items="userList"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>KULLANICI LİSTESİ</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>

          <template v-slot:[`item.userName`]="{ item }">
            <v-chip class="ma-2" color="indigo" outlined small>
              {{ item.userName }}
            </v-chip>
          </template>          

          <template v-slot:[`item.userEmail`]="{ item }">
            <v-chip class="ma-2" color="" outlined small>
              {{ item.userEmail }}
            </v-chip>
          </template> 

          <template v-slot:[`item.userType`]="{ item }">
            <v-chip class="ma-2" color="" outlined small>
              <div v-if="item.userType == 1">ADMIN</div>
              <div v-if="item.userType == 0">USER</div>
            </v-chip>
          </template>    

          <template v-slot:[`item.registerDate`]="{ item }">
            <v-chip class="ma-2" color="" outlined small>
              {{ item.registerDate }}
            </v-chip>
          </template>                 

 <!-- 
          <template v-slot:[`item.complaintType`]="{ item }">
            <v-chip class="ma-2" color="pink" outlined small>
              <div v-if="item.complaintType == 0">Yorum</div>
              <div v-if="item.complaintType == 1">Kullanıcı</div>
            </v-chip>
          </template>

          <template v-slot:[`item.complainingName`]="{ item }">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'user',
                params: {
                  userUrl: item.complainingUrlTxt,
                  userGui: item.complainingUUID,
                },
              }"
              target="_blank"
            >
              <v-chip
                class="ma-2 text-uppercase"
                color="indigo"
                outlined
                style="cursor: pointer"
                small
              >
                {{ item.complainingName }}
              </v-chip>
            </router-link>
          </template>

          <template v-slot:[`item.userName`]="{ item }">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'user',
                params: {
                  userUrl: item.userUrlTxt,
                  userGui: item.userUUID,
                },
              }"
              target="_blank"
            >
              <v-chip
                class="ma-2 text-uppercase"
                color="indigo"
                outlined
                style="cursor: pointer"
                small
              >
                {{ item.userName }}
              </v-chip>
            </router-link>
          </template>

          <template v-slot:[`item.articleID`]="{ item }">
            <v-chip class="ma-2" color="green" outlined small>
              {{ item.articleID }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintDate`]="{ item }">
            <v-chip class="ma-2" color="brown" outlined small>
              {{ item.complaintDate }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintTitle`]="{ item }">
            <v-chip class="ma-2" color="brown" outlined small>
              {{ item.complaintTitle }}
            </v-chip>
          </template>

          <template v-slot:[`item.complaintReason`]="{ item }">
            <v-btn
              color="indigo"
              fab
              dark
              x-small
              @click="goReason(item.complaintReason)"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </v-container>

      <!--şikayet dialog box -->
      <!-- <v-dialog v-model="diaologCompliant" width="400px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text"
              >ŞİKAYET ET
              <v-spacer></v-spacer>
              <v-avatar color="indigo darken-4" dark>
                <v-icon dark @click="diaologCompliant = false"
                  >mdi-close-octagon</v-icon
                >
              </v-avatar>
            </v-card-title>

            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-card class="mx-auto" color="white" dark max-width="400">
                    <v-card-text
                      class="text--primary"
                      v-html="compliantReason"
                    >
                      <h4>{{ compliantReason }}</h4>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog> -->
    </v-app>
  </div>
</template>

<script>
import auth from "../../api/auth";
import router from "../../router";

export default {
  data: () => ({
    users: [
      {
        text: "Kullanıcı ID",
        align: " d-none",
        sortable: false,
        value: "userID",
      },
      {
        text: "Kullancı Adı",
        align: "start",
        sortable: false,
        value: "userName",
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "userEmail",
      },
      {
        text: "Tipi",
        align: "start",
        sortable: false,
        value: "userType",
      },
      {
        text: "Kayıt Tarihi",
        align: "start",
        sortable: false,
        value: "registerDate",
      },

    ],
    userList: [],

    multiLine: null,
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,
    totalUserCount: 0,
    todayUserCount: 0,
    weekUserCount: 0,
    monthUserCount: 0,

  }),

  computed: {},

  created() {},

  methods: {
    goHome,
    loadAdminPages,
  },

  mounted() {
    this.loadAdminPages();
  },
};

function loadAdminPages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }

          this.userList = [];
          var params = {
            UserID: 0,
          };

          this.progress = false;
          this.$http
            .post(auth.API_URL + "api/UserAD/UserList", params, {
              headers: {
                Authorization: "Bearer " + auth.getAuthHeader(),
              },
            })
            .then(
              (response) => {
                this.progress = false;
                console.log(response.body)
                this.list = response.body.UserInfo;
                this.totalUserCount = response.body.TotalUser;
                this.todayUserCount = response.body.TodayUser;
                this.weekUserCount = response.body.WeekUser;
                this.monthUserCount = response.body.MonthUser;

                this.list.forEach((element) => {
                  var elementItem = {
                    userID: element.UserID,
                    userName: element.Title,
                    userEmail: element.Email,
                    userType: element.UserType,
                    registerDate : element.RegisterDateTxt

                  };
                  this.userList.push(elementItem);
                });
              },
              (response) => {
                if (response.status === 0) {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                } else {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                }
              }
            );
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function goHome() {
  this.$router.push({ name: "adminmain" });
}

</script>