<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>{{ username }} SOHBETİ</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class="ma-2 hidden-sm-and-down"
              tile
              outlined
              color="amber darken-3"
              @click="gomessagemain"
            >
              <v-icon left>mdi-email-multiple</v-icon>TÜM SOHBETLER
            </v-btn>

            <v-btn
              class="ma-2 hidden-lg-and-up"
              tile
              outlined
              color="amber darken-3"
              @click="gomessagemain"
              fab
              x-small
            >
              <v-icon>mdi-email-multiple</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>
      <v-container>
        <v-card color="white" class="mx-auto" dense>
          <v-row>
            <v-col cols="9" lg="11" sm="10" md="6">
              <v-textarea
                color="indigo"
                auto-grow
                class="mx-2"
                label="Bir mesaj yazın"
                rows="1"
                prepend-icon="mdi-message-plus"
                v-model="textmessage"
              ></v-textarea>
            </v-col>
            <v-col cols="3" lg="1" sm="2" md="6">
              <v-btn
                class="ma-2"
                outlined
                fab
                color="indigo"
                @click="writemessage()"
                small
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <v-container>
        <v-row v-for="item in items" v-bind:key="item.index">
          <v-col cols="6">
            <v-card
              v-if="item.MeOpponent"
              class="rounded-xl"
              color="amber lighten-5"
              dense
            >
              <v-card-text>
                {{ item.MessageText }}
              </v-card-text>
            </v-card>
            <v-chip
              v-if="item.MeOpponent"
              class="ma-2"
              color="grey"
              outlined
              pill
              x-small
            >
              {{ item.MessageDateTxt }}
            </v-chip>
          </v-col>
          <v-col cols="6">
            <v-card
              v-if="!item.MeOpponent"
              class="rounded-xl"
              color="purple lighten-5"
              dense
            >
              <v-card-text> {{ item.MessageText }} </v-card-text>
            </v-card>
            <v-chip
              v-if="!item.MeOpponent"
              class="ma-2"
              color="grey"
              outlined
              pill
              x-small
              align="end"
            >
              {{ item.MessageDateTxt }}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>


<script>
import auth from "../api/auth";
import router from "../router";

export default {
  name: "App",
  watch: {
    "$route.params.conversationid": function () {
      if (typeof this.$route.params.conversationid !== "undefined") {
        this.count = 0;
        this.pollData();
      }
    },
    $route: function () {
      if (this.$route.name !== "conversation") {
        clearInterval(this.polling);
      }
    },
  },

  data() {
    return {
      textmessage: "",
      items: [],
      messageList: [],
      count: 0,
      username: "",
      userid: "",
      multiLine: null,
      successAlert: false,
      successMsg: "",
      errorAlert: false,
      errorMsg: "",

    };
  },
  methods: {
    gomessagemain,
    writemessage,
    loadconversationroom,
    pollData() {
      if (this.count == 0) {
        this.loadconversationroom();
      }
      this.polling = setInterval(() => {
        this.loadconversationroom();
        this.count++;
      }, 30000);
    },
  },
  mounted() {
    this.pollData();
  },
};

function loadconversationroom() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.ID == 0) {
            router.push({
              path: "/",
            });
            window.location.reload();
          } else {
            if (response.body.UserID != this.$route.params.userid) {
              router.push({
                path: "/",
              });
              window.location.reload();
            } else {
              (this.username = this.$route.params.chattedname),
                (this.userid = this.$route.params.chattedid),
                (this.items = []);

              var params = {
                UUID: this.$route.params.conversationid,
                ActiveUserID: this.$route.params.userid,
              };
              this.$http
                .post(
                  auth.API_URL + "api/Conversation/ConversationRoomLoad",
                  params,
                  {
                    headers: {
                      Authorization: "Bearer " + auth.getAuthHeader(),
                    },
                  }
                )
                .then(
                  (response) => {
                    this.messageList = response.body;

                    this.messageList.forEach((element) => {
                      var messageItem = {
                        MessageID: element.MessageID,
                        ConversationsUUID: element.ConversationsUUID,
                        SendUserID: element.SendUserID,
                        ReceivedID: element.ReceivedID,
                        MessageDate: element.MessageDate,
                        MessageDateTxt: element.MessageDateTxt,
                        MessageText: element.MessageText,
                        MessageRead: element.MessageRead,
                        MeOpponent: element.MeOpponent,
                      };
                      this.items.push(messageItem);
                    });
                  },
                  (response) => {
                    if (response.status === 0) {
                      this.text1 = "Service Unavailable";
                      this.snackbar = true;
                    } else {
                      this.text1 = "Service Unavailable";
                      this.snackbar = true;
                    }
                  }
                );
            }
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }

  // if (localStorage.userSessionID) {

  //   (this.username = this.$route.params.chattedname),
  //     (this.userid = this.$route.params.chattedid),
  //     (this.items = []);

  //   var params = {
  //     UUID: this.$route.params.conversationid,
  //     ActiveUserID: this.$route.params.userid,
  //   };
  //   this.$http
  //     .post(auth.API_URL + "api/Conversation/ConversationRoomLoad", params, {
  //       headers: {
  //         Authorization: "Bearer " + auth.getAuthHeader(),
  //       },
  //     })
  //     .then(
  //       (response) => {
  //         this.messageList = response.body;

  //         this.messageList.forEach((element) => {
  //           var messageItem = {
  //             MessageID: element.MessageID,
  //             ConversationsUUID: element.ConversationsUUID,
  //             SendUserID: element.SendUserID,
  //             ReceivedID: element.ReceivedID,
  //             MessageDate: element.MessageDate,
  //             MessageDateTxt: element.MessageDateTxt,
  //             MessageText: element.MessageText,
  //             MessageRead: element.MessageRead,
  //             MeOpponent: element.MeOpponent,
  //           };
  //           this.items.push(messageItem);
  //         });
  //         console.log(this.items);
  //       },
  //       (response) => {
  //         if (response.status === 0) {
  //           this.text1 = "Service Unavailable";
  //           this.snackbar = true;
  //         } else {
  //           this.text1 = "Service Unavailable";
  //           this.snackbar = true;
  //         }
  //       }
  //     );
  // } else {
  //   router.push({
  //     path: "/",
  //   });
  //   window.location.reload();
  // }
}

function gomessagemain() {
  this.$router.push({ name: "messagemain" });
}

function writemessage() {
  if (this.textmessage === "") {
    this.errorMsg = "Bir mesaj girmeniz gerekmektedir.";
    this.errorAlert = true;
  } else {
    var params = {
      UUID: this.$route.params.conversationid,
      SendUserID: this.$route.params.userid,
      ReceivedID: this.$route.params.chattedid,
      MessageText: this.textmessage,
    };

    this.$http
      .post(auth.API_URL + "api/Conversation/MessageSave", params, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          if (response.body.Status) {
            this.textmessage = "";
            this.loadconversationroom();
          } else {
            this.errorMsg = response.body.ResponseMessage;
            this.errorAlert = true;
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  }
}
</script>