<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>İSTATİSTİK</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="indigo"
              @click="goHome()"
              small
            >
              <v-icon>mdi-home-import-outline </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

    </v-app>
  </div>
</template>

<script>
 import auth from "../../api/auth";
 import router from "../../router";

export default {
  data: () => ({
  }),

  computed: {},

  created() {},

  methods: {
    goHome,
    loadAdminPages,
  },

  mounted() {
      this.loadAdminPages()
  },
};


function loadAdminPages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
        //   this.loginSuccess = true;
        //   this.accountname = response.body.Title;
        //   this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function goHome() {
  this.$router.push({ name: "adminmain" });
}

</script>