import Vue from 'vue'
import Router from 'vue-router'
// import VueMeta from 'vue-meta'


// import Deneme from '../src/views/Deneme.vue'
import About from '../src/views/About.vue'
import Login from '../src/views/Login.vue'
import Homepage from '../src/views/Homepage.vue'
import Headline from '../src/views/Headline.vue'
import HeadlineAll from '../src/views/HeadlineAll.vue'
import User from '../src/views/User.vue'
import MailConfirm from '../src/views/MailConfirm.vue'
import MailReset from '../src/views/MailReset.vue'
import Article from '../src/views/Article.vue'
import MessageMain from '../src/views/MessageMain.vue'
import Conversation from '../src/views/Conversation.vue'
import Notification from '../src/views/Notification.vue'

import AdminMain from '../src/views/Admin/AdminMain.vue'
import AdminCategory from '../src/views/Admin/AdminCategory.vue'
import AdminHeadline from '../src/views/Admin/AdminHeadline.vue'
import AdminArticle from '../src/views/Admin/AdminArticle.vue'
import AdminComplaint from '../src/views/Admin/AdminComplaint.vue'
import AdminUser from '../src/views/Admin/AdminUser.vue'
import AdminStatistics from '../src/views/Admin/AdminStatistics.vue'

Vue.use(Router)
// Vue.use(VueMeta)

const router = new Router({
  routes: [
    { path: '/about', name: 'about', component: About, meta: { requiresAuth: true } },
    { path: '/login', name: 'login', component: Login, meta: { title: 'Giriş',requiresAuth: true } },
    { path: '/Homepage', name: 'homepage', component: Homepage, meta: {  title: 'Ana Sayfa', requiresAuth: true } },
    { path: '/Headline/:name/:id', name: 'headline', component: Headline, meta: { requiresAuth: true } },
    { path: '/HeadlineAll/:categoryid/:categoryname', name: 'headlineAll', component: HeadlineAll, meta: { requiresAuth: true } },
    { path: '/User/:userUrl/:userGui', name: 'user', component: User, meta: { requiresAuth: true } },
    { path: '/MailConfirm/:userName/:userUUID', name: 'mailconfirm', component: MailConfirm, meta: { requiresAuth: true } },
    { path: '/MailReset/:userName/:userUUID', name: 'mailreset', component: MailReset, meta: { requiresAuth: true } },
    { path: '/Article/:articleid', name: 'article', component: Article, meta: { requiresAuth: true } },
    { path: '/MessageMain', name: 'messagemain', component: MessageMain, meta: { title: 'Sohbetler', requiresAuth: true } },
    { path: '/Conversation/:conversationid/:userid/:chattedid/:chattedname', name: 'conversation', component: Conversation, meta: { title: 'Sohbet', requiresAuth: true } },  
    { path: '/Notification/:userid', name: 'notification', component: Notification, meta: { title: 'Bildirimler', requiresAuth: true } },      

    { path: '/AdminMain', name: 'adminmain', component: AdminMain,  meta: { title: 'Yönetici Ekranı', requiresAuth: true } },
    { path: '/AdminCategory', name: 'admincategory', component: AdminCategory,  meta: { title: 'Kategori Yönetici Ekranı', requiresAuth: true } },    
    { path: '/AdminHeadline', name: 'adminheadline', component: AdminHeadline,  meta: { title: 'Başlık Yönetici Ekranı', requiresAuth: true } },          
    { path: '/AdminArticle', name: 'adminarticle', component: AdminArticle,  meta: { title: 'Yorum Yönetici Ekranı', requiresAuth: true } },    
    { path: '/AdminComplaint', name: 'admincomplaint', component: AdminComplaint,  meta: { title: 'Şikayet Yönetici Ekranı', requiresAuth: true } },           
    { path: '/AdminUser', name: 'adminuser', component: AdminUser,  meta: { title: 'Kullanıcı Yönetici Ekranı', requiresAuth: true } },   
    { path: '/AdminStatistics', name: 'adminstatistics', component: AdminStatistics,  meta: { title: 'İstatistik Ekranı', requiresAuth: true } },      

  ],
  scrollBehavior() {
    window.scrollTo(0, 0);
  }

})


router.beforeEach((to, from, next) => {   

  var titlestr = to.meta.title

  if (to.path == "/")
  {    
    titlestr = 'Ana Sayfa'
  }  

  if (to.name == "headline")
  {    
    titlestr = to.params.name.replaceAll('-',' ')
  }

  if (to.name == "article")
  {    
    titlestr = "#" + to.params.articleid
  }  

  if (to.name == "user")
  {    
    titlestr = to.params.userUrl.replaceAll('-',' ')
  }  

  if (to.name == "headlineAll")
  {    
    titlestr = to.params.categoryname.replaceAll('-',' ')
  }    

  document.title = titlestr  + " - Sanat Sözlük"
  next()
})

export default router

