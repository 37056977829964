<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="4000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="4000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-container class="text-uppercase">
        <v-card color="red" flat>
          <v-toolbar dense>
            <v-toolbar-title>KATEGORİ YÖNETİMİ</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              v-if="loginSuccess"
              class="ma-2"
              tile
              outlined
              color="indigo"
              @click="goHome()"
            >
              <v-icon>mdi-home-import-outline </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-container>

      <v-container>
        <v-data-table
          :headers="category"
          :items="catlist"
          :items-per-page="100"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>KATEGORİ LİSTESİ</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                color="indigo"
                dark
                class="hidden-sm-and-down mb-2"
                v-bind="attrs"
                v-on="on"
                @click="categoryEditShow()"
              >
                <v-icon left> mdi-content-save </v-icon>
                YENİ KATEGORİ EKLE
              </v-btn>
              <v-btn
                color="indigo"
                dark
                class="hidden-lg-and-up mb-2"
                v-bind="attrs"
                v-on="on"
                @click="categoryEditShow()"
                small
              >
                <v-icon small> mdi-content-save </v-icon>
              </v-btn>              
            </v-toolbar>
          </template>
          <template v-slot:[`item.importance`]="{ item }">
            <v-chip>
              {{ item.importance }}
            </v-chip>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-chip
              v-model="item.active"
              :color="item.active ? 'success' : 'error'"
              outlined
              class="ma-2"
              small
              @click="goConfirm(item.id, item.active)"
              style="cursor: pointer"
            >
              <div v-if="item.active">Aktif</div>
              <div v-if="!item.active">Pasif</div>
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon color="indigo" small class="mr-2" @click="goEdit(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              color="brown darken-1"
              small
              class="mr-2"
              @click="goDelete(item.id)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              color="green"
              small
              class="mr-2"
              @click="goUpDown(item, false)"
            >
              mdi-transfer-up
            </v-icon>
            <v-icon
              color="red"
              small
              class="mr-2"
              @click="goUpDown(item, true)"
            >
              mdi-transfer-down
            </v-icon>
          </template>
        </v-data-table>
      </v-container>

      <!-- dialog edit box -->
      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-form ref="testc">
          <v-card>
            <v-card-title class="indigo white--text"
              >YENİ KATEGORİ</v-card-title
            >
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-text-field
                    id="categoryTitle"
                    v-model="categoryTitle"
                    label="Kategori Adı"
                    name="categoryTitle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    id="categoryIcon"
                    v-model="categoryIcon"
                    label="Kategori İkon Adı"
                    name="categoryIcon"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="activeselect"
                    :label="activeselect == false ? 'Pasif' : 'Aktif'"
                    color="indigo"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="error"
                @click="EditCancel()"
              >
                <v-icon left>mdi-home</v-icon>VAZGEÇ
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="categorySave()"
              >
                <v-icon left>mdi-content-save</v-icon>KAYDET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogPAConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>
                Bu kategoriyi {{ this.itemactive }} yapmak istediğinizden emin
                misiniz?
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogPAConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="goPassiveActive()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>Bu kategoriyi silmek istediğinizden emin misiniz?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogDeleteConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="categoryDelete()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogUpDownConfirm" max-width="500px">
        <v-card>
          <v-container>
            <v-card-text class="text--primary" align="center">
              <h3>Bu kategoriyi {{ this.upDownTxt }} emin misiniz?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialogUpDownConfirm = false"
                >Vazgeç</v-btn
              >
              <v-btn color="green" text @click="categoryUpDown()">Tamam</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import auth from "../../api/auth";
import router from "../../router";

export default {
  data: () => ({
    dialogEdit: false,
    dialogPAConfirm: false,
    dialogDeleteConfirm: false,
    dialogUpDownConfirm: false,
    category: [
      {
        text: "Kategori Adı",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Kategori Icon", value: "icon", sortable: false },
      {
        text: "Kategori Sirası",
        value: "importance",
        align: "end",
        sortable: false,
      },
      { text: "Aktif/Pasif", value: "active", sortable: false },
      { text: "ID", value: "id", align: " d-none", sortable: false },
      { text: "İşlemler", value: "action", sortable: false },
    ],
    catlist: [],
    categoryTitle: "",
    categoryIcon: "",
    on: null,
    attrs: null,
    activeselect: false,

    multiLine: null,
    successMsg: "",
    successAlert: false,
    errorMsg: "",
    errorAlert: false,

    itemid: 0,
    itemactive: "",
    itemtitle: "",
    itemicon: "",
    itemsaveupdate: false,
    itemimportance: 0,
    itemupdown: false,

    upDownTxt: "",
  
  }),

  computed: {},

  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  created() {},

  methods: {
    loadCategoryPages,
    categoryEditShow,
    categorySave,
    categoryDelete,
    categoryUpDown,
    goPassiveActive,
    goConfirm,
    goEdit,
    EditCancel,
    goDelete,
    goUpDown,
    goHome,
  },

  mounted() {
    this.loadCategoryPages();
  },
};

function loadCategoryPages() {
  if (localStorage.userSessionID) {
    var sparam = {
      SessionID: localStorage.userSessionID,
    };
    this.$http
      .post(auth.API_URL + "api/UserAD/UserSessionsLoad", sparam, {
        headers: {
          Authorization: "Bearer " + auth.getAuthHeader(),
        },
      })
      .then(
        (response) => {
          this.loginSuccess = true;
          this.accountname = response.body.Title;
          this.activeuserid = response.body.UserID;

          if (response.body.UserType != 1) {
            router.push({
              path: "/",
            });
            window.location.reload();
          }                 

          this.catlist = [];
          var params = {
            PassiveShow: true,
            HeadlineDontShow: true,
          };

          this.progress = false;
          this.$http
            .post(auth.API_URL + "api/CategoryAD/CategoryListAD", params, {
              headers: {
                Authorization: "Bearer " + auth.getAuthHeader(),
              },
            })
            .then(
              (response) => {
                this.progress = false;
                this.list = response.body;

                this.list.forEach((element) => {
                  var elementItem = {
                    id: element.CategoryID,
                    name: element.CategoryName,
                    icon: element.CategoryIcon,
                    importance: element.CategoryImportance,
                    active: element.Active,
                  };

                  this.catlist.push(elementItem);
                });
              },
              (response) => {
                if (response.status === 0) {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                } else {
                  this.text1 = "Service Unavailable";
                  this.snackbar = true;
                }
              }
            );
        },
        (response) => {
          if (response.status === 0) {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          } else {
            this.text1 = "Service Unavailable";
            this.snackbar = true;
          }
        }
      );
  } else {
    router.push({
      path: "/",
    });
    window.location.reload();
  }
}

function categoryEditShow() {
  this.dialogEdit = true;
  this.itemsaveupdate = false;
}

function categorySave() {
  var params = {
    CategoryID: this.itemid,
    CategoryName: this.categoryTitle,
    CategoryIcon: this.categoryIcon,
    Active: this.activeselect,
    SaveUpdate: this.itemsaveupdate,
  };

  this.$http
    .post(auth.API_URL + "api/CategoryAD/CategorySaveAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.categoryTitle = "";
          this.categoryIcon = "";
          this.activeselect = false;
          this.dialogEdit = false;
          this.itemsaveupdate = false;
          this.loadCategoryPages();
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goPassiveActive() {
  var params = {
    CategoryID: this.itemid,
  };

  this.$http
    .post(auth.API_URL + "api/CategoryAD/CategoryPassiveActiveAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.loadCategoryPages();
          (this.itemid = 0),
            (this.itemvalue = ""),
            (this.dialogPAConfirm = false);
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
          (this.itemid = 0),
            (this.itemvalue = ""),
            (this.dialogPAConfirm = false);
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goConfirm(id, value) {
  this.dialogPAConfirm = true;
  this.itemid = id;
  this.itemactive = value == true ? "pasif" : "aktif";
}

function goDelete(id) {
  this.dialogDeleteConfirm = true;
  this.itemid = id;
}

function goEdit(item) {
  this.dialogEdit = true;

  this.categoryTitle = item.name;
  this.categoryIcon = item.icon;
  this.activeselect = item.active;
  this.itemid = item.id;
  this.itemsaveupdate = true;
}

function goUpDown(item, value) {
  this.dialogUpDownConfirm = true;
  this.itemid = item.id;
  this.itemimportance = item.importance;
  this.itemupdown = value;
  this.upDownTxt = !value ? "yukarıya" : "aşağıya";
}

function EditCancel() {
  this.dialogEdit = false;

  this.categoryTitle = "";
  this.categoryIcon = "";
  this.activeselect = false;
  this.itemid = 0;
  this.itemsaveupdate = false;
}

function categoryDelete() {
  var params = {
    CategoryID: this.itemid,
  };

  this.$http
    .post(auth.API_URL + "api/CategoryAD/CategoryDeleteAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.loadCategoryPages();
          (this.itemid = 0), (this.dialogDeleteConfirm = false);
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
          (this.itemid = 0), (this.dialogDeleteConfirm = false);
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function categoryUpDown() {
  var params = {
    CategoryID: this.itemid,
    ImportanceNo: this.itemimportance,
    UpDown: this.itemupdown,
  };

  this.$http
    .post(auth.API_URL + "api/CategoryAD/CategoryUpDownAD", params, {
      headers: {
        Authorization: "Bearer " + auth.getAuthHeader(),
      },
    })
    .then(
      (response) => {
        if (response.body.Status) {
          this.successMsg = response.body.ResponseMessage;
          this.successAlert = true;
          this.loadCategoryPages();
          this.itemid = 0;
          this.dialogUpDownConfirm = false;
          this.itemimportance = 0;
          this.UpDown = false;
          this.upDownTxt = "";
        } else {
          this.errorMsg = response.body.ResponseMessage;
          this.errorAlert = true;
          this.itemid = 0;
          this.dialogUpDownConfirm = false;
          this.itemimportance = 0;
          this.UpDown = false;
          this.upDownTxt = "";
        }
      },
      (response) => {
        if (response.status === 0) {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        } else {
          this.text1 = "Service Unavailable";
          this.snackbar = true;
        }
      }
    );
}

function goHome()
{
  this.$router.push({ name: "adminmain" });
}

</script>