<template>
  <div id="app">
    <v-app id="inspire">
      <div class="text-center">
        <v-snackbar
          :timeout="5000"
          v-model="successAlert"
          :multi-line="multiLine"
          color="green"
          shaped
          top
        >
          {{ successMsg }}
        </v-snackbar>
        <v-snackbar
          :timeout="5000"
          v-model="errorAlert"
          :multi-line="multiLine"
          color="red"
          shaped
          top
        >
          {{ errorMsg }}
        </v-snackbar>
      </div>
      <v-form ref="reset" v-if="!mailSuccess">
        <v-card class="mx-auto" max-width="500">
          <v-toolbar color="indigo" dark>
            <v-toolbar-title>ŞİFRE SIFIRLAMA</v-toolbar-title>
          </v-toolbar>

          <v-container fluid>
            <v-text-field
              id="password"
              v-model="password"
              label="Şifre"
              name="Password"
              append-icon="showPassword ? mdi-eye : mdi-eye-off"
              :type="showPassword ? 'text' : 'password'"
              :color="bgColor"
              :rules="passwordRules"
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              id="repassword"
              v-model="repassword"
              label="Şifre Tekrarı"
              name="RePassword"
              append-icon="showPassword ? mdi-eye : mdi-eye-off"
              :type="showRePassword ? 'text' : 'password'"
              :color="bgColor"
              :rules="passwordRules"
              @click:append="showRePassword = !showRePassword"
            />

            <div class="text-center mt-6">
              <v-btn @click="resetMail" large :color="bgColor" dark
                >ŞİFREMİ DEĞİŞTİR</v-btn
              >
            </div>
          </v-container>
        </v-card>
      </v-form>

      <v-container v-if="mailSuccess">
        <div class="text-center">
          <v-card>
            <v-card-text class="headline font-weight-bold text-uppercase">
              MERHABA {{ this.userName }}
            </v-card-text>

            <v-btn class="ma-2" outlined large fab color="green">
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-card-text class="headline font-weight-bold">
              ŞİFREN DEĞİŞİTİRİLDİ.
            </v-card-text>

            <v-btn
              class="ma-2"
              tile
              outlined
              color="indigo"
              @click="loginEntry"
            >
              <v-icon left>mdi-account</v-icon>GİRİŞ YAP
            </v-btn>
          </v-card>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<script>
 import auth from "../api/auth";

export default {
  name: "App",

  data: () => ({
    userName: "",
    mailSuccess: false,
    password: "",
    repassword: "",
    showPassword: false,
    showRePassword: false,
    passwordRules: [(v) => !!v || "Lütfen şifrenizi giriniz."],
    errorMsg: "",
    errorAlert: false,
    successMsg: "",
    successAlert: false,
  }),
  methods: {
    resetMail,
    loginEntry,
  },

  mounted() {
    this.userName = this.$route.params.userName;
  },
};

function resetMail() {
  if (this.$refs.reset.validate()) {
    if (this.password != this.repassword) {
      this.errorMsg = "Şifre ve Şifre Tekrarı eşit olmalıdır.";
      this.errorAlert = true;
    } else {
      var params = {
        userUUID: this.$route.params.userUUID,
        Password: this.password,
      };

      this.$http
        .post(auth.API_URL + "api/UserAD/UserPassReset", params, {
          headers: {
            Authorization: "Bearer " + auth.getAuthHeader(),
          },
        })
        .then(
          (response) => {
            if (response.body.Status) {
              this.successMsg = "Şifreniz başarıyla değiştirilmiştir.";
              this.successAlert = true;
              this.mailSuccess = true;
            }
            else 
            {
              this.errorMsg = response.body.ResponseMessage;
              this.errorAlert = true;

            }
          },
          (response) => {
            if (response.status === 0) {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            } else {
              this.text1 = "Service Unavailable";
              this.snackbar = true;
            }
          }
        );
    }
  }
}

function loginEntry() {
  this.$router.push({ name: "login" });
}
</script>
