<template>
  <div class="about">
          <v-btn class="ma-2" tile outlined color="error" @click="sendmail">
           <v-icon left>mdi-mail</v-icon>MAİL GÖNDER
          </v-btn>     

          <rich-text-editor @update="onUpdate"/>           
  </div>
</template>


<script>
import auth from '../api/auth'
import axios from 'axios'

import Vue from 'vue'
import RichTextEditor from 'rich-text-editor-vuetify'
 
Vue.use(RichTextEditor)

export default {
  name: 'App',

  data: () => ({
    kime: 'mustafa.sahintas@gmail.com',
    text: ''
  }),
  methods: {
    sendmail,
    onUpdate (text) {
      this.text = text
    }    
  },

};


function sendmail()
{
      var okMail = auth.URL_MAIL +'MailConfirm/'+ 'tokendeneme12345' +
        ' url adresinden şifrenizi yenilebilirsiniz!'


    let formData = new FormData();

    formData.append('Konu', 'E-Posta Onay Maili')
    formData.append('to', this.kime)
    formData.append('formattedMessage', okMail)
    formData.append('mailType', '0')
    axios.defaults.baseURL = auth.API_URL;
    // axios.responseType = 'arraybuffer';
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios.post(auth.API_URL + 'api/HelpersAD/SendEmail',
        formData, {
          headers: {

          }
        }
      ).then((response) => {


        console.log(response)
        // if (response.data == true) {
        //   this.text1 =
        //     "Sistemde kayıtlı mail adresinize onay maili gönderilmiştir. Mailinizde bulunan link ile işleminize devam edebilirsiniz."
        //   this.snackbar = true
        // } else {
        //   this.text1 = "Kullanıcı kayıtınız oluşturuldu, onay maili adresinize gönderilemedi."
        //   this.snackbar = true
        // }
      })
      .catch(function () {});

}



</script>
